import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/mainpage.css';
import Timeline from '../../assets/images/timeline-logo.png';
import TimelineJes from '../../assets/images/timeline-jes.png';
import JesManufacture from '../../assets/images/logo1.png';

const responsive = {
    pc: {
        breakpoint: { max: 1200, min: 768 },
        items: 1
      },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

function HistoryCarousel() {
    return (
        <Carousel responsive={responsive} arrows={true}>
            <li>
                <div>
                    <time>1976</time> 
                    <p>Manufacturing Operations was established in 1976 in a 2500 sq.mt facility in the Industrial Area of the State of Qatar for manufacture of Air Outlets under License from Anemostat, USA</p>
                    <img src={Timeline} />
                </div>
            </li>
            <li>
                <div>
                    <time>2000</time> 
                    <p>The manufacturing business of KMC is acquired by the Jersey Group</p>
                    <img src={TimelineJes} />
                    <img src={Timeline} />
                </div>
            </li>
            <li>
                <div>
                    <time>2001-2009</time> 
                    <p>By the mid 2000 the business had grown to such a size that new manufacturing 
                        facilities were required.</p>
                        <img src={Timeline} />
                </div>
                </li>
            <li>
                <div>
                    <time>2010</time> 
                    <p>In 2010 the business moved to a new upgraded 5,000 sq.mt. facility to the 
                        New Industrial Area in the State of Qatar, where extensive investment in 
                        product development, people, IT and capital equipment took place, all of
                        which underpinned the company’s growth and subsequent rapid expansion.
                    </p>
                    <img src={Timeline} />
                </div>
            </li>
            <li>
                <div>
                    <time>2011</time> 
                    <p>We enter into an agreement with Mestek, USA for the manufacture of UL 
                        classified Life Safety dampers. KMC enters into an agreement to 
                        manufacture HVAC noise control products with Aerosonics, USA in 
                        Qatar and the region.
                    </p>
                    <img src={Timeline} />
                </div>
            </li>
            <li>
                <div>
                    <time>2012</time> 
                    <p>All air outlets and Air terminal products manufactured by us are now under
                        the KMC brand KMC adds another manufacturing facility of 5,500 sq.mt for
                        the manufacture of UL classified dampers with state of the art CNC machines.
                    </p>
                    <img src={Timeline} />
                </div>
            </li>
            <li>
                <div>
                    <time>2014-2016</time> 
                    <p>Export revenue to the GCC region increase due to addition of the entire range of Air
                        control and Distribution products.
                    </p>
                    <img src={Timeline} />
                </div>
            </li>
            <li>
                <div>
                    <time>2017-2020</time> 
                    <p>Business expands further along with our customer base and investment in broadening product range To
                        provide extensive integrated solutions continued.
                        Air Terminals(Pressure Independent VAVs) manufactured by KMC certified by AHRI
                    </p>
                    <img src={Timeline} />
                </div>
            </li>
            <li>
                <div>
                    <time>2021</time> 
                    <p>The Board decides to re-brand KMC as "Jersey Manufacturing" to align wth the Jersey Group
                        and the planned expansion to other markets.
                    </p>
                    <img src={JesManufacture} />
                </div>
            </li>
            <li>
                <div>
                    <time>2022</time> 
                    <p>In line with the future vision, Jersey manufacturing, division of the Jersey Group and Air Balance
                        , USA have entered into an agreement for the manufacture and sales of UL classified Life Safety Dampers and other products
                        in the MENA region, India and the Indian sub-continent.
                        Red More...
                    </p>
                    <img src={JesManufacture} />
                </div>
            </li>
        </Carousel>
    );
}

export default HistoryCarousel


