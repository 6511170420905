import React from "react";
import {Container , Row, Col} from 'react-bootstrap';  
import Certificate1 from '../../assets/images/certificate1.png';
import Certificate2 from '../../assets/images/certificate2.png';
import Certificate3 from '../../assets/images/certification3.png';

const Certification = () =>{
    return(
        <div className="Certification">
            <div className="our_products-sec certify">
                <div className="container">
                    <div className="sec-bottom">
                        <h2 className="sec-title">Certifications</h2>
                    </div>
                </div>
            </div>
            <div className="our_products-sec certification">
                <Row>  
                    <Col sm={12} md={12}>
                    {/* <p class="sec-top-title">Certification</p> */}
                        <div className="sec-bottom">
                            <p className="sec-bottom-title">Jersey manufactures an extensive range of Life Safety Dampers, Air Terminals, Noise Control Products, Diffusers, Grilles, Registers and Architectural louvers. Our products have met the quality and operational expectations in all applications and also under critical operating conditions. 
                            Our products are tested and certified by some of the globally recognized laboratories.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Fire Dampers</h5>
                                <img src={Certificate1} />
                                <p>Tested and Classified by Underwriters Laboratories Inc. (UL), USA, to UL555 Standard.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Combination Fire-Smoke Dampers</h5>
                                <img src={Certificate1} />
                                <p>Tested and Classified by Underwriters Laboratories Inc. (UL), USA, to UL555 & UL555s standards.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Smoke Dampers</h5>
                                <img src={Certificate1} />
                                <p>Tested and Classified by Underwriters Laboratories Inc. (UL), USA, to UL555s standards.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Ceiling Radiation Dampers</h5>
                                <img src={Certificate1} />
                                <p>Tested and Classified by Underwriters Laboratories Inc. (UL), USA, to UL555c standards.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Variable Air Volume Air Terminals (VAVs)</h5>
                                <img src={Certificate2} />
                                <p>Tested and certified by AHRI, USA, to AHRI Standard 880 for Air Terminals.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Air Outlets</h5>
                                <img src={Certificate3} />
                                <p>Tested by Intertek Laboratories, USA to ASHRAE 70 Standard “Method of Testing for Rating the Performance of Air Outlets and Inlets”.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Air Control Dampers</h5>
                                <img src={Certificate3} />
                                <p>Tested by Intertek Laboratories, USA to AMCA Standard 500-D, “Laboratory Methods of Testing Dampers for Rating”.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Louvers</h5>
                                <img src={Certificate3} />
                                <p>Tested by Intertek Laboratories, USA to AMCA 500-L Standards “Laboratory Methods of Testing Louvers for Rating”.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Sound Attenuators</h5>
                                <img src={Certificate3} />
                                <p>Tested by Intertek Laboratories, USA to ASTM-E477 Standards “Standard Method of Testing Duct Liner Materials and Prefabricated Silencers for Acoustical and Airflow Performance”.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={4}>
                        <div className="certificates">
                            <div className="certificate-box">
                                <h5>Acoustic Louvers</h5>
                                <img src={Certificate3} />
                                <p>Tested by Intertek Laboratories, USA to ASTM E90-09, “Standard Test Method for Laboratory Measurement of Airborne Sound Transmission Loss of Building Partitions” and classified in accordance with the <a>Readmore</a></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
};
export default Certification