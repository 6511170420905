import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import '../../assets/css/mainpage.css';
import Banner1 from '../../assets/images/Jersy-Banner-Option-1.jpg';
import Banner2 from '../../assets/images/Jersy-Banner-Option-2.jpg';
import Banner3 from '../../assets/images/Jersy-Banner-Option-3.jpg';
import Banner4 from '../../assets/images/Ahri-News2.png';
import Banner5 from '../../assets/images/Jersy-Banner-Option-5.jpg';
import Banner6 from '../../assets/images/Jersy-Banner-Option-6.jpg';
import Banner7 from '../../assets/images/Jersy-Banner-Option-7.png';
import MobileBanner1 from '../../assets/images/Jersy-Banner-Mobile-1.jpg';
import MobileBanner2 from '../../assets/images/Jersy-Banner-Mobile-2.jpg';
import MobileBanner3 from '../../assets/images/Jersy-Banner-Mobile-3.jpg';
import MobileBanner4 from '../../assets/images/Jersy-Banner-Mobile-4.jpg';
import MobileBanner5 from '../../assets/images/Jersy-Banner-Mobile-5.jpg';
import MobileBanner6 from '../../assets/images/Jersy-Banner-Mobile-6.jpg';
import MobileBanner7 from '../../assets/images/Jersy-Banner-Mobile-7.jpg';
import Ahri from '../../assets/images/AHRI.png';
import Next from '../../assets/images/next-button.svg';

const ImageList = {
    "images": [
        {
            "url" : Banner6,
            "caption" : "Life Safety Dampers",
            "description" : "UL Classified Life Safety Dampers",
            "mobileUrl" : MobileBanner5
        },
        {
            "url" : Banner3,
            "caption" : "Air Control Dampers",
            "description" : "Superior Air Control with High Perfomance",
            "mobileUrl" : MobileBanner3
        },
        {
            "url" : Banner4,
            "caption" : "Air Terminal Units",
            "description" : "Smart and Convenient Solutions",
            "mobileUrl" : MobileBanner4
        },
        {
            "url" : Banner2,
            "caption" : "Air Distribution Products",
            "description": "For High Performance Buildings",
            "mobileUrl" : MobileBanner2
        },
        {
            "url" : Banner1,
            "caption" : "Acoustic Louvers",
            "description" : "Efficient & Attractive solutions for optimum noice reduction for air Intake & Exhaust applications",
            "mobileUrl" : MobileBanner1
        },
        {
            "url" : Banner5,
            "caption" : "Architectural Performance Louvers",
            "description" : "Meets the most stringent requirements for Performance and aesthetic appeal",
            "mobileUrl" : MobileBanner7
        },
        {
            "url" : Banner7,
            "caption" : "Noise Control",
            "description" : "Commercial HVAC Duct Silencers",
            "mobileUrl" : MobileBanner6
        },
        
    ]
}

function BannerCarousel() {
    return (
        <>
            {
               
                ImageList.images ?
                    <Carousel slide={false} >
                        
                        {
                             
                            ImageList.images.map((img, key) => {
                                return (

                                    <Carousel.Item interval={3000} key={key}>
                                        <img
                                            className="w-100"
                                            src={img.url}
                                            alt={`slide ${key}`}
                                        />
                                        <img
                                            className="w-100 mobile-banner"
                                            src={img.mobileUrl}
                                            alt="First slide"
                                        />
                                        <Carousel.Caption>
                                            
                                                <h2>{img.caption}</h2>
                                                
                                            
                                            <p>{img.description}</p>
                                            <div className="cap-title">
                                            <Link to="/products">
                                                <button className='caption-btn'>Explore product <img src={Next} /></button>
                                            </Link>
                                            
                                            </div>
                                        </Carousel.Caption>
                                    </Carousel.Item>

                                );
                            })
                        }
                    </Carousel> : null
            }
        </>
    );
}

export default BannerCarousel