import {
    isRequired,
    isValidEmail,
    isTextOnly,
    isNumberOnly,
    capitalizeFirstLetter
  } from '../../utils/Validate';
  import { isValidPhoneNumber } from 'react-phone-number-input';
  
  export const validation = (values) => {
    const errors = {};
  
    if (!isRequired(values.wsFirstName)) {
      errors.wsFirstName = 'First Name is required';
    } else if (!isTextOnly(values.wsFirstName)) {
      errors.wsFirstName = 'First Name must only contain alphabets';
    }else {
      values.wsFirstName = capitalizeFirstLetter(values.wsFirstName);
    }

    if (!isRequired(values.wsLastName)) {
        errors.wsLastName = 'Last Name is required';
      } else if (!isTextOnly(values.wsLastName)) {
        errors.wsLastName = 'Last Name must only contain alphabets';
      }else {
        values.wsLastName = capitalizeFirstLetter(values.wsLastName);
      }

    if (isRequired(values.wsPhone)) {
      
      if ( values?.wsPhone?.length > 4  && !isValidPhoneNumber(values.wsPhone))
      {

        errors.wsPhone = 'Phone number is not valid';
      }
    }

      if (!isRequired(values.wsEmail)) {
        errors.wsEmail = 'Email is required';
      } else if (!isValidEmail(values.wsEmail)) {
        errors.wsEmail = 'Email is not valid';
      }
    
    if (!isRequired(values.wsProject)) {
      errors.wsProject = 'Project name is required';
    }else {
      values.wsProject = capitalizeFirstLetter(values.wsProject);
    }

     
    if (!isRequired(values.wsCustomer)) {
        errors.wsCustomer = 'Company name is required';
      }else {
        values.wsCustomer = capitalizeFirstLetter(values.wsCustomer);
      }

      if (!isRequired(values.wsAddress1)) {
        errors.wsAddress1 = 'Address name is required';
      }

      if (!isRequired(values.wsCountry)) {
        errors.country = 'Country name is required';
      }
      if (!isRequired(values.wsCity)) {
        errors.wsCity = 'City name is required';
      }
      if (!isRequired(values.wsState)) {
        errors.wsState = 'State name is required';
      }

      
      if (!isRequired(values.wsZipCode)) {
        errors.wsZipCode = 'ZipCode is required';
      }else if(!isNumberOnly(values.wsZipCode))
      {
        errors.wsZipCode = 'Only Number is allowed for ZipCode';
      }

    return errors;
  };
  
  