import React from "react";

const PrivacyPloicy = () =>{
    return (
        <>
        <div className="policy-sec">
            <div className="our_products-sec policy">
                <div className="sec-bottom">
                    <h2 className="sec-title">Privacy Policy</h2>
                </div>
                <p>By using this Site, you consent to our Privacy Policy set out below. All terms not defined in this document have the meanings ascribed to them in the Terms of Use Agreement between you and jerseygroup.com, which by use of this Site you agree to accept.</p>
            
                <div className="policy-sub">
                    <h4 className="policy-title">When do we collect information?</h4>
                    <h5 className="sub-title">Registration Information:</h5>
                    <p>At the time of registration, you will be asked to complete and submit a form which requires you to provide information such as your name, address, phone/fax number, email address and other personal information as well as information about your business.</p>
                    <h5 className="sub-title">Publishing Information:</h5>
                    <p>If you submit any information or details to jerseygroup.com to be published on the Site, including but not limited to individual profile and any discussion forum, then you are deemed to have given consent to the publication of such information.</p>
                    <br/>
                    <h5 className="sub-title">Third Party Services:</h5>
                    <p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions. For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</p>
                    <h5 className="sub-title">Statistical Information:</h5>
                    <p>In addition, we gather aggregate statistical information about our Site and Users, such as IP addresses, browser software, operating system, pages viewed, number of sessions, unique visitors etc.</p>
                    <br />
                    <h5 className="sub-title">Collected Information:</h5>
                    <p>Publishing Information, Payment Information, Statistical Information and any information we may collect from you through the use of cookies, or any other means, shall collectively be referred to as “Collected Information”.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">How do we use your information?</h4>
                    <h5 className="sub-title">General:</h5>
                    <p>We use your Collected Information to customize our Site’s content, layout and service for you and improve our marketing and promotional efforts, to analyze our site usage, to improve the overall usage. We may use your Collected Information to service investigate problems, resolve disputes regarding orders/sales or published information. We do not sell, rent, trade or exchange any personally identifying information of our Users. We may share certain aggregate information based on analysis of Collected Information with our partners, customers and advertisers. We may use your Collected Information for marketing campaigns and promotions on behalf of third parties; however, your Collected Information is not disclosed unless you respond to the marketing or promotion exercise.</p>
                    <h5 className="sub-title">Registration Information:</h5>
                    <p>We may use your Registration Information to provide services that you request or to contact you regarding additional services from jerseygroup.com. Specifically, we may use your email address, mailing address, phone number or fax number to contact you regarding notices, surveys, product alerts, new service or product offerings and communications relevant to your use of our Site. We may generate reports based on the Registration Information for internal analysis, monitoring and marketing decisions.</p>
                    <h5 className="sub-title">Publishing Information:</h5>
                    <p>All of your Publishing Information will be publicly available on the Site and therefore accessible by any Internet user. Any Publishing Information that you disclose to jerseygroup.com becomes public information and you relinquish any proprietary rights (including but not limited to the rights of confidentiality and copyrights) in such information. You should exercise caution when deciding to include personal or proprietary information in the Publishing Information that you submit to us.</p>
                    <h5 className="sub-title">Statistical Information:</h5>
                    <p>We use Statistical Information to help diagnose problems with and maintain our computer servers, to manage our Site, and to enhance our Site and services based on the usage pattern data we receive. We may generate reports and analysis based on the Statistical Information for internal analysis, monitoring and marketing decisions. We may provide Statistical Information to third parties, but when we do so, we do not provide personally identifying information without your permission.</p>
                    <h5 className="sub-title">Deleted Customer Account Information:</h5>
                    <p>In the event of a registered user choosing to delete their account, then all the previous order history existing for that user will be saved in our database and the respective account will be automatically deactivated.If the user does not have any previous order history, then his/her data will be deleted permanently.</p>
                    <h5 className="sub-title">Disclosure of Information:</h5>
                    <p>We reserve the right to disclose your Collected Information to relevant authorities where we have reason to believe that such disclosure is necessary to identify, contact or bring legal action against someone who may be infringing or threatening to infringe, or who may otherwise be causing injury to or interference with, the title, rights, interests or property of jerseygroup.com, our Users, customers, partners, other web site users or anyone else who could be harmed by such activities. We also reserve the right to disclose Collected Information in response to a subpoena or other judicial order or when we reasonably believe that law, regulation or administrative order of any court, governmental or regulatory authority, requires such disclosure. If we have reason to believe that a User is in breach of the Terms of User Agreement or any other agreement with us, then we reserve the right to make public or otherwise disclose such User’s Collected Information in order to pursue our claim or prevent further injury to jerseygroup.com or others.</p>
                    <h5 className="sub-title">Cookies:</h5>
                    <p>We use “cookies’’ to store specific information about you and track your visits to our Site. A “cookie” is a small amount of data that is sent to your browser and stored on your computer’s hard drive. A cookie can be sent to your computer’s hard drive only if you access our Site using the computer. If you do not deactivate or erase the cookie, each time you use the same computer to access our Site, our web servers will be notified of your visit to our Site and in turn we may have knowledge of your visit and the pattern of your usage. Generally, we use cookies to identify you and enable us to access your Registration Information, Publishing Information or Payment Information so you do not have to re-enter it; gather statistical information about usage by Users; research visiting patterns and help target advertisements based on User interests; assist our partners to track User visits to the Site and process orders; and track progress and participation in promotions.</p>
                    <h5 className="sub-title">Third Party Links:</h5>
                    <p>When you click on any third party links on our web page, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>
                    <h5 className="sub-title">Age of Consent:</h5>
                    <p>The Site and its contents are not intended to target minors under applicable law and we do not intend to sell any of our products or services to minors. However, we have no way of distinguishing the age of individuals who access our Site and so we carry out the same Privacy Policy for individuals of all ages. If a minor has provided us with personal information without parental or guardian consent, the parent or guardian should contact us to remove the information.</p>
                    <h5 className="sub-title">Security Measures:</h5>
                    <p>We employ commercially reasonable security methods to prevent unauthorized access, maintain data accuracy and ensure correct use of information. As a Registered User, your Registration Information, Publishing Information and Payment Information (if any) can be viewed and edited through your Account, which is protected by Password. We recommend that you do not divulge your Password to anyone. Our personnel will never ask you for your Password in an unsolicited phone call or in an unsolicited e-mail. If you share a computer with others, you should not choose to save your login information (e.g., User ID and Password) on the computer. Remember to sign out of your Account and close your browser window when you have finished your session. No data transmission over the Internet or any wireless network can be guaranteed to be perfectly secured. As a result, while we try to protect your information, no web site or company, including ourselves, can absolutely ensure or guarantee the security of any information you transmit to us and you do so at your own risk.</p>
                    <h5 className="sub-title">Data Storage Policy</h5>
                    <p>All member’s information in terms of placed orders, refunds, returns, contact details are securely stored under jerseygroup.com servers that are placed under high protection.</p>
                    <h5 className="sub-title">Amendment to Privacy Policy</h5>
                    <p>Any changes to this Privacy Policy will be communicated through our posting an amended and restated Privacy Policy on our Site. Our posting the amended and restated Privacy Policy will make such new Privacy Policy immediately effective. You do agree that all Collected Information, (whether or not collected prior to or after the new policy became effective) will be governed by the latest Privacy Policy in effect. If you do not agree to the new changes in our Privacy Policy, you should contact jerseygroup.com in writing and specifically request that jerseygroup.com return and/or destroy all copies of all or part of your Collected Information in jerseygroup.com possession.</p>
                    <h5 className="sub-title">Editing Your Information</h5>
                    <p>You can access; view and edit your Registration Information, Publishing Information and Payment Information (if any) through your Account with jerseygroup.com.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Your feedback & Complaints</h4>
                    <p>We welcomes your continuous input regarding our Privacy Policy or our services provided to you. You may send us your comments and responses to us <a href="">support@jerseygroup.com</a></p>
                </div>
                
            </div>
        </div>
        </>
    )
};

export default PrivacyPloicy