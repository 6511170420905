import React from "react";
import {Container , Row, Col} from 'react-bootstrap';
import logo from '../../assets/images/logo-icon.png';
import Next from '../../assets/images/seemore-white.svg';

const NewRegister = () =>{
    return(
        <div className="login-page new-register">
            <div className="login-sec">
                <Row>
                    <Col md={6}>
                        <div className="left-side">
                            <img src={logo} />
                            <h1>Create New Account</h1>
                            <p>Register Now to your account to add product to RFQ list</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form">
                            <form>
                                <Row>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Full Name</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Company Name</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <select class="did-floating-input">
                                                <option>Select</option>
                                                <option>Option2</option>
                                            </select>
                                            <label class="did-floating-label">Company Type</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Job Title</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <select class="did-floating-input">
                                                <option>Select</option>
                                                <option>Option2</option>
                                            </select>
                                            <label class="did-floating-label">Country</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <select class="did-floating-input">
                                                <option>Select</option>
                                                <option>Option2</option>
                                            </select>
                                            <label class="did-floating-label">State / Province</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">City</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Zip / Postal Code</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Email Address</label>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Contact Number</label>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="signedin">
                                            <div class="form-check">
                                                <div className='agree-check'>
                                                    <input type="checkbox" class="form-check-input" id="check2" name="option2" value="something" />
                                                    <label class="form-check-label" for="check2">I Accept Terms And Conditions</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <button className="btn display-btn">Register <img src={Next} /></button>
                                    </Col>
                                    <Col md={12}>
                                        <p className="register-link">Already a Member? <a href="/login"> Sign In</a></p>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
};
export default NewRegister