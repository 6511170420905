import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom';
import MainPage from './components/Mainpage';
import Home from './components/Mainpage/Home';
import About from './components/About/Aboutus';
import Products from './components/Products/Products';
import ProductList from './components/Products/ProductList';
import ProductDetail from './components/Products/ProductDetail';
import RequestADemo from './components/Products/RequsetADemo';
import DownloadCenter from './components/Products/DownloadCenter';
import Reference from './components/Reference/Reference';
import ReferenceDetail from './components/Reference/ReferenceDetail';
import ContactUs from './components/Contact/Contact-us';
import Careers from './components/Career/Careers';
import Certification from './components/Certification/Certification';
import Login from './components/Login/Login';
import NewRegister from './components/Login/NewRegister';
import NewsEvents from './components/News&Events/NewsEvents';
import NewsDetails from './components/News&Events/NewsDetails';
import PrivacyPloicy from './components/Policies/PrivacyPolicy';
import TermsConditions from './components/Policies/TermsConditions';
import Disclaimer from './components/Policies/Disclaimer';
import Warranty from './components/Policies/Warranty';
import CareerDetail from './components/Career/CareerDetail';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'

import { TokenProvider } from './components/Context/TokenContext';


const queryClient = new QueryClient()

function App() {
  return (
    <TokenProvider>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route exact path='/' element={<MainPage />}>
                <Route index element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/products' element={<Products />} />
                <Route path='/product-list' element={<ProductList />} />
                <Route path='/product-detail' element={<ProductDetail />} />
                <Route path='/request-demo' element={<RequestADemo />} />
                <Route path='/download-center' element={<DownloadCenter />} />
                <Route path='/reference' element={<Reference />} />
                <Route path='/reference-detail' element={<ReferenceDetail />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/careers' element={<Careers />} />
                <Route path='/career-detail' element={<CareerDetail />} />
                <Route path='/certification' element={<Certification />} />
                <Route path='/login' element={<Login />} />
                <Route path='/new-register' element={<NewRegister />} />
                <Route path='/news-events' element={<NewsEvents />} />
                <Route path='/news-events/news' element={<NewsDetails />} />
                <Route path='/privacy-policy' element={<PrivacyPloicy />} />
                <Route path='/terms-conditions' element={<TermsConditions />} />
                <Route path='/disclaimer' element={<Disclaimer />} />
                <Route path='/warranty' element={<Warranty />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </div>
    </TokenProvider>
  );
}

export default App;
