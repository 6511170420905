function exists(obj) {
  return obj && obj !== undefined && obj !== null;
}

function isEmpty(obj) {
  return !exists(obj) || (obj instanceof Array ? obj.length === 0 : Object.keys(obj).length === 0);
}

const checknull = (value) => {
  if (value === null || typeof (value) === "undefined" || value === NaN || value === "")
    return ""
  else
    return value;
}

const isRequired = (value) => {
  if (typeof value === 'string') {
    return value.trim() !== '';
  }
  return !!value;
};  

const isRequiredNo = (value) => {
  if (value === null || typeof (value) === "undefined" || value === NaN || value === "" || value === 0)
    return false;
  else
    return true;
}


const isValidEmail = (value) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}

const isValidPhone = (value) => {
  const regex =/^[\d\s]{8,14}$/;
  return regex.test(value);
};

const isAlphaNumeric = (value) => {
  const regex = /^[A-Za-z0-9]*$/;
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}


const isTextOnly = (value) => {
  let regex = /^[a-zA-Z\s]+$/;
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}

const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const isValidPassword = (value) => {
  let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{10,20}$/;
  if (value != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}

// const isNumberOnly = (value) => {
//   let regex = /^[0-9]*\.*\-?[0-9]+$/;
//   if (value.trim() != "" && regex.test(value) === false) {
//     return false;
//   } else {
//     return true;
//   }
// }
const isNumberOnly = (value) => {
  let regex = /^[0-9]*\.*\-?[0-9]+$/;
  const result = value != '' && typeof value === 'string' ? value.trim() : value;
  if(result == '') return false
  else if (result != "" && regex.test(value) === false) {
    return false;
  } else {
    return true;
  }
}


const isValidUrl = (value) => {
  let regex = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
  return regex.test(value);
};

const isValidFileUploaded = (file) => {
  const validExtensions = ["png", "jpg"];
  const fileExtension = file?.type.split("/")[1];
  return validExtensions.includes(fileExtension);
};

// const isValidFileSize = (file) => {
//   const MAX_FILE_SIZE = 500000;
//   if (file.size > MAX_FILE_SIZE) {
//     return false;
//   } else {
//     return true;
//   }
// };

const isValidFileSize = (file) => {
  const MAX_FILE_SIZE = 500000;
  if (file > MAX_FILE_SIZE) {
    return false;
  } else {
    return true;
  }
};

function isValidFaxNumber(values) {
  const regex =/^\+?[0-9]{6,}$/ // only allowed 9 digits and more
  return regex.test(values);
}

function diffdays(date1, date2) 
 {
  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
}

const isValidDate = (value) => {
  if (value === null || typeof (value) === "undefined" || value === "")
    return false;
  else if(new Date(value) === "Invalid Date")
    return false;
  else    
    return true;
}

const dateCheck = (value) => {
  if (value === null || typeof (value) === "undefined" || value === "")
    return new Date();
  else if(new Date(value) === "Invalid Date")
    return new Date();
  else    
    return new Date(value);
}

function validateName(name) {
  const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/; // Regular expression to check if input contains only text
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression to check if input contains email format
  const urlRegex = /(?:https?:\/\/)?(?:www\.)?[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,}/; // Regular expression to check if input contains a URL

  if (name.match(emailRegex)) {
    return "Please enter a valid name and not an email format.";
  } else if (name.charAt(0).match(/\d/)) {
    return "Name should not start with a number.";
  } else if (name.match(urlRegex)) {
    return "Name should not contain a website URL.";
  } else if (!name.match(regex)) {
    return "Name should contain only text.";
  } else {
    return "";
  }
}


function convertLocalToUTCDate(date) {
  if (!date) return date  
  date = new Date(date)
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}


//exclude negative numbers and zero,allow upto 4 decimal places
function isValidConversionRate(values) {
  const reg = /^(?!0(\.0*)?$)\d+(\.?\d{0,4})?$/;
  return reg.test(values);
}



function getMonthShortName(monthNo) {
  const date = new Date();
  date.setMonth(monthNo);  
  return date.toLocaleString('en-US', { month: 'short' });
}


const padDate = (n) =>{
  return n<10 ? '0'+n : n;
}

function formatDate(date, params) {
  let dt = new Date(date);
    let day = dt.getDate();
    let month = dt.getMonth();
    let monthName = getMonthShortName(month)
    let year = dt.getFullYear();
    let dateObject;
    if(params == "view"){
      dateObject =  padDate(day) + "/" + padDate(month + 1) + "/"+ year;
    }
    else dateObject = day+'-'+monthName+'-'+year
    return dateObject
}

const handleSocialMediaClick = (socialMedia) => {
  const currentUrl = window.location.href;
  let shareUrl = '';

  switch (socialMedia) {
      case 'Facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
          break;
      case 'Twitter':
          shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`;
          break;
      case 'WhatsApp':
          shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(`Check out this link: ${currentUrl}`)}`;
          break;
      case 'LinkedIn':
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;
          break;
      case 'Instagram':
          shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(currentUrl)}`;
          break;
      default:
          break;
  }

  if (shareUrl) {
      window.open(shareUrl, '_blank');
  }
};



export { 
  exists, 
  isEmpty, 
  checknull, 
  isRequired, 
  isValidEmail,
  isValidPhone, 
  isTextOnly, 
  isValidPassword, 
  isNumberOnly,
  isValidUrl, 
  isRequiredNo,
  isValidFileUploaded,
  isValidFileSize,
  isValidFaxNumber,
  diffdays,
  isValidDate,
  dateCheck,
  validateName,
  isAlphaNumeric,
  convertLocalToUTCDate,
  isValidConversionRate,
  formatDate,
  handleSocialMediaClick,
  capitalizeFirstLetter
};