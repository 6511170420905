import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import More from '../../assets/images/seemore.svg';
import Events4 from '../../assets/images/events1.png';
import Twitter from '../../assets/images/twitter-black.png';
import Facebook from '../../assets/images/facebook-black.png';
import Instagram from '../../assets/images/instagram-black.png';
import Linkedin from '../../assets/images/linkedin-black.png';
import Newscertify from '../../assets/images/AHRI.png';
import { useState } from 'react';
import Events6 from '../../assets/images/news33.png';
import News1 from '../../assets/images/JMC - KSA.jpg';

import {
    handleSocialMediaClick
} from '../../utils/Validate';
import Events2 from '../../assets/images/news2.png';
import Events3 from '../../assets/images/news33.png';

const NewsDetails = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const newsFromQuery = searchParams.get('news');
    
    const [news, setNews] = useState(newsFromQuery);

    useEffect(() => {
        if (newsFromQuery) {
            setNews(newsFromQuery);
        }
    }, [newsFromQuery]);

    return (
        <div className='news-and-events'>
            <div className='details'>
                <div className="bread-crumbs">
                    <nav>
                        <Link to="/"
                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            Home
                        </Link>
                        <span className="breadcrumb-arrow">/</span>
                        <Link to="/news-events"
                            className={location.pathname.startsWith("/events") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            News & Events
                        </Link>
                        <span className="breadcrumb-arrow">/</span>
                        <Link to="/news-events/news"
                            className={location.pathname.startsWith("/news") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            News
                        </Link>
                    </nav>
                </div>
                <div className="our_products-sec latestNews">
                    {parseInt(news)  === 1 && <div className='news-one'>
                        <div className="sec-bottom">
                            <h2 className="sec-title">Air Balance, USA signs agreement with Jersey Manufacturing Company</h2>
                        </div>
                        <div className="latest-news-sec">
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="news-section">

                                        {/* <p className="news-time">NEWS • FEBRUARY 2023</p> */}
                                        <img src={Events2} />
                                        <p>Jersey Manufacturing Company, Qatar has signed up with Air Balance, USA as an exclusive Licensee to manufacture and distribute their complete range of UL classified Life Safety Dampers and a wide range of high-Performance Louvers in the MENA region, South Asia and the Indian Sub-Continent.
                                            <br />
                                            Air Balance, was founded in Philadelphia in 1958 to manufacture louvers and dampers, with special emphasis on fire dampers and is wholly owned subsidiary of Mestek Inc.
                                            <br />
                                            <span>In 1964, Air Balance revolutionized fire dampers by inventing and introducing the curtain blade fire damper, which is still the industry standard.</span>
                                            <br />
                                            As HVAC systems have become more sophisticated, Air Balance has continued its role as the industry leader in developing state of the art UL classified life safety products including fire, fire/smoke and smoke dampers for both static and dynamic applications. Air Balance product offerings include a wide variety of related products, including louvers, control, balancing and industrial dampers, gravity ventilators and penthouses.
                                            <br />
                                            Jersey Manufacturing will now be able to provide the same state of the art, quality products that Air Balance manufactures in the USA to our customers in the region.
                                        </p>


                                        <div className='social-icons'>
                                            <a onClick={() => handleSocialMediaClick('Twitter')}><img src={Twitter} alt="Twitter" /></a>
                                            <a onClick={() => handleSocialMediaClick('Facebook')} title="Facebook"><img src={Facebook} alt="Facebook" /></a>
                                            <a onClick={() => handleSocialMediaClick('Instagram')} title="Instagram"><img src={Instagram} alt="Instagram" /></a>
                                            <a onClick={() => handleSocialMediaClick('LinkedIn')} title="Linkedin"><img src={Linkedin} alt="Linkedin" /></a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>}
                    {parseInt(news) === 2 && <div className='news-two'>
                        <div className="sec-bottom">
                            <h2 className="sec-title">Pressure Independent VAVs manufactured by Jersey Manufacturing (formerly KMC)
                                are tested and certified by AHRI</h2>
                        </div>
                        <div className="latest-news-sec">
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="news-section">
                                        <img src={Events6} />
                                        {/* <a className='news-certify'><img src={Newscertify} /></a> */}
                                        <p>Jersey Manufacturing Company (formerly known as Khalid
                                            Manufacturing Co) is known for our leading, customer focused
                                            approach to providing innovative Commercial and Industrial
                                            HVAC products since 1976.

                                            In almost forty years we have created a product portfolio that
                                            competes worldwide on quality, price, and application.
                                            Our products have met the quality and operational expectations
                                            in all applications and also under critical operating conditions.
                                            Pressure Independent Air Terminal Units manufactured by Jersey (KMC) have been tested and
                                            certified by AHRI, USA to AHRI Standard 880 for Air Terminals.</p>


                                        <div className='social-icons'>
                                            <a onClick={() => handleSocialMediaClick('Twitter')}><img src={Twitter} alt="Twitter" /></a>
                                            <a onClick={() => handleSocialMediaClick('Facebook')} title="Facebook"><img src={Facebook} alt="Facebook" /></a>
                                            <a onClick={() => handleSocialMediaClick('Instagram')} title="Instagram"><img src={Instagram} alt="Instagram" /></a>
                                            <a onClick={() => handleSocialMediaClick('LinkedIn')} title="Linkedin"><img src={Linkedin} alt="Linkedin" /></a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>}

                    {parseInt(news)  === 3 && <div className='news-two'>
                        <div className="sec-bottom">
                            <h2 className="sec-title">Jersey Manufacturing Co (formerly known as KMC) is now having its presence across Saudi Arabia & other GCC countries</h2>
                        </div>
                        <div className="latest-news-sec">
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="news-section">
                                        <img src={News1} />
                                        <p>
                                        JMC is well known for its reliable products ,engineering expertise and our multi skilled team capable of offering innovative solutions to meet the challenging project requirements.
                                        <br/>
                                        Our commitment to quality coupled with our experience in manufacturing & supplying air distribution products in Qatar for over 4 decades has been well reciprocated be our esteemed
                                         customers by being with us on this journey together & extending our reach into other new markets in the MENA region as well
                                        </p>


                                        <div className='social-icons'>
                                            <a onClick={() => handleSocialMediaClick('Twitter')}><img src={Twitter} alt="Twitter" /></a>
                                            <a onClick={() => handleSocialMediaClick('Facebook')} title="Facebook"><img src={Facebook} alt="Facebook" /></a>
                                            <a onClick={() => handleSocialMediaClick('Instagram')} title="Instagram"><img src={Instagram} alt="Instagram" /></a>
                                            <a onClick={() => handleSocialMediaClick('LinkedIn')} title="Linkedin"><img src={Linkedin} alt="Linkedin" /></a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="our_products-sec latestNews relatedNews">
                <div className="sec-bottom">
                    <h2 className="sec-title">Related news & events </h2>
                    {/* <span><a href="">View more <img src={More} /></a></span> */}
                </div>

                <div className="latest-news-sec">
                    <Row>
                        {parseInt(news) === 2 && 
                        <>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=1",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                            <div className="news-section">
                                <img src={Events2} />
                                <h5>Air Balance, USA signs agreement with Jersey Manufacturing Company</h5>
                                <p>Jersey Manufacturing</p>
                            </div>
                            </Link>
                        </Col>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=3",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                        <div className="news-section">
                            <img src={News1} />
                            <h5>Jersey Manufacturing Co (formerly known as KMC) is now having its presence across Saudi Arabia & other GCC countries</h5>
                            <p>Jersey Manufacturing</p>
                        </div>
                        </Link>
                    </Col>
                    </>}
                        {parseInt(news) === 1 && 
                        <>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=2",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                            <div className="news-section">
                                <img src={Events3} />
                                <h5>Pressure Independent VAVs manufactured by Jersey Manufacturing (formerly KMC)
                                are tested and certified by AHRI</h5>
                                <p>Jersey Manufacturing</p>
                            </div>
                            </Link>
                        </Col>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=3",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                            <div className="news-section">
                                <img src={News1} />
                                <h5>Jersey Manufacturing Co (formerly known as KMC) is now having its presence across Saudi Arabia & other GCC countries</h5>
                                <p>Jersey Manufacturing</p>
                            </div>
                            </Link>
                        </Col>
                        </>
                        }
                        {parseInt(news) === 3 && 
                        <>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=1",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                            <div className="news-section">
                                <img src={Events2} />
                                <h5>Air Balance, USA signs agreement with Jersey Manufacturing Company</h5>
                                <p>Jersey Manufacturing</p>
                            </div>
                            </Link>
                        </Col>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=2",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                            <div className="news-section">
                                <img src={Events3} />
                                <h5>Pressure Independent VAVs manufactured by Jersey Manufacturing (formerly KMC)
                                are tested and certified by AHRI</h5>
                                <p>Jersey Manufacturing</p>
                            </div>
                            </Link>
                        </Col>
                        </>
                        }
                        {/* <Col sm={6} md={4}>
                                    <div className="news-section">
                                        <img src={Excellence3} />
                                        <h5>USA links agreement  Jersey Manufacturing</h5>
                                        <p>Jersey Manufacturing</p>
                                    </div>
                                </Col> */}
                    </Row>
                    {/* <div className='bottom-more'>
                                <div className='button-more'><a href="">View more <img src={Viewmore} /></a></div>
                            </div> */}
                </div>

            </div>
        </div>
    )
};
export default NewsDetails