import { useState, useEffect } from 'react';
import { validation } from './validation';
import { CreateEntry } from '../../providers/dataProvider';
import urls from "../../constants";
import AWS from 'aws-sdk';


AWS.config.update({
  accessKeyId: urls.AWS_ACCESS_KEY_ID,
  secretAccessKey: urls.AWS_ACCESS_SECRET_KEY,
  region: urls.AWS_REGION,
  signatureVersion: 'v4',
});


const useForm = () => {

  const [selectedCountry, setSelectedCountry] = useState('QA');
  const [showInternationalFlag, setShowInternationalFlag] = useState(true)
  const [successMsg, setSuccessMsg] = useState('');

  const [formValues, setFormValues] = useState({
    wsCustomer: '',
    wsProject: '',
    wsFirstName: '',
    wsLastName: '',
    wsPhone: '',
    wsEmail: '',
    wsAddress1: '',
    wsAddress2: '',
    wsCity: '',
    wsState: '',
    wsCountry: '',
    wsZipCode: '',
    wsMessage: '',
    wsCreatedOn: '2022-11-24T17:36:16.593',
    TblWsEnqAttachments: [],
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [createMsg, setCreateMsg] = useState(null);
  const [failureMsg, setFailureMsg] = useState(null);
  const [isSubmitAtt, setIsSubmitAtt] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);
  const [country, setCountry] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const ALLOWED_FILE_TYPES = ['application/msword', 
                              'application/vnd.ms-excel',
                              'application/pdf', 
                              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                            ];
  const [errorMessage, setErrorMessage] = useState('');
 
 
  const handleRemoveFile = (index) => {
    if (uploadedFiles.length > MAX_COUNT) {
      setErrorMessage(`You can only add a maximum of ${MAX_COUNT} files`);
      return;
    }
  
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.from(e.target.files);
    handleUploadFiles(chosenFiles);
  };

 
  const handleFormChange = (e) => {

    if (e.target) {
      const { name, value } = e.target;
    
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
      }));
  
      const errors = validation({
        ...formValues,
        [name]: value,
      });
  
      setFormErrors({
        ...formErrors,
        [name]: errors[name],
      });
    }
  };

 

  const handlePhoneChange = (value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      wsPhone: value,
    }));

    const errors = validation({
      ...formValues,
      wsPhone: value,
    });
  
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      wsPhone: errors.wsPhone,
    }));

  };


  const handleInputBlur = (e) => {
    if (e.target) {
      const { name, value } = e.target;
  
      const errors = validation({
        ...formValues,
        [name]: value,
      });
  
      setFormErrors({
        ...formErrors,
        [name]: errors[name],
      });
    }
  };

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption)
    setFormValues({
      ...formValues,
      wsCountry: selectedOption.label,
    });

    setFormErrors({
      ...formErrors,
      country: !selectedOption
    });

  };

  const handleCountry= (country) => {
    setSelectedCountry(country);
  };

  

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const errors = validation(formValues);
    setFormErrors(errors);
    setIsSubmit(true);
  };



  const MAX_COUNT = 1;

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
  
    files.forEach((file) => {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setErrorMessage('Only documents, PDFs, and Excel files are allowed.');
        return;
      } else {
        setErrorMessage('');
      }
  
      if (uploaded.findIndex((f) => f.file.name === file.name) === -1) {
        uploaded.push({
          WsAttachName: file.name,
          WsAttachUrl: URL.createObjectURL(file),
          file: file,
        });
  
        if (uploaded.length === MAX_COUNT) {
          setFileLimit(true);
        }
        if (uploaded.length > MAX_COUNT) {
          setErrorMessage(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return;
        }
      }
    });
  
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
    }
  };
  
  const uploadToS3 = async (files) => {
    if (!files || files.length === 0) {
      return Promise.resolve(); 
    }
  
    const s3 = new AWS.S3();
    const uploadPromises = files.map(async (file, index) => {
      const params = {
        Bucket: urls.AWS_BUCKET_NAME,
        Key: `${Date.now()}.${file.name}`,
        Body: file,
      };
  
      try {
        const result = await s3.upload(params).promise();
        const { Location } = result;
  
        if (!Location) {
          throw new Error(`File${index} not uploaded`);
        }
  
        return {
          WsAttachName: file.name,
          WsAttachUrl: Location,
        };
      } catch (error) {
        throw error;
      }
    });
  
    return Promise.all(uploadPromises);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const files = uploadedFiles.map((item) => item.file);
  
      uploadToS3(files)
        .then((fileAttachments) => {
          const updatedFormValues = {
            ...formValues,
            TblWsEnqAttachments: fileAttachments,
          };

          
      CreateEntry(
        'Enquiries',
        updatedFormValues,
        setCreateMsg,
        'Success! Your message has been sent.',
        'Failed to create Enquiries'
      );

      setFormValues({
        wsCustomer: '',
        wsProject: '',
        wsFirstName: '',
        wsLastName: '',
        wsPhone: '',
        wsEmail: '',
        wsAddress1: '',
        wsAddress2: '',
        wsCity: '',
        wsState: '',
        wsCountry: '',
        wsZipCode: '',
        wsMessage: '',
        TblWsEnqAttachments: [],
      });
      setFormErrors({});
      setUploadedFiles([]);
      setCountry('');
      setSelectedCountry('QA');
        })
        .catch((error) => {
          throw error;
        });
    }
    setIsSubmit(false);
  };

  useEffect(()=>{
    setTimeout(() => {
      setErrorMessage('');
    }, 4000);
  },[errorMessage])
  

  useEffect(() => {
    if (createMsg?.message === 'Failure') {
      setFailureMsg(createMsg?.message);
      setTimeout(() => {
        setFailureMsg('');
        setIsSubmitAtt(true);
      }, 4000);
    } else {
      setSuccessMsg(createMsg?.message);
      setTimeout(() => {
        setSuccessMsg('');
        setIsSubmitAtt(true);
      }, 4000);
    }
  }, [createMsg]);
  
  return {
    handleFormChange,
    handleSubmit,
    formValues,
    formErrors,
    createMsg,
    successMsg,
    isSubmit,
    setIsSubmit,
    setUploadedFiles,
    handleSubmitForm,
    handleFileEvent,
    uploadedFiles,
    fileLimit,
    MAX_COUNT,
    isSubmitAtt,
    setIsSubmitAtt,
    handleCountryChange,
    handleRemoveFile,
    handleInputBlur,
    handlePhoneChange,
    country,
    setCountry,
    errorMessage,
    failureMsg,
    setFormValues,
    setFormErrors,
    handleCountry,
    showInternationalFlag,
    selectedCountry
  };
};

export default useForm;
