import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import Next from '../../assets/images/seemore-white.svg';
import Success from '../../assets/images/success.svg';

const SuccessModal = ({successpopup, successModalClose, successMsg}) => {
    return (
        <Modal centered show={successpopup} onHide={successModalClose} className="new-demo-modal success-modal">               
            <Modal.Body className="show-grid">
                <div className="body-box">
                    <img src={Success} />
                    <h1>Success!</h1>
                    <p>{successMsg}</p>
                    <div className="submit-sec">
                        <button type="submit" className="btn submit-btn" onClick={successModalClose}>Done <img src={Next} /></button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModal