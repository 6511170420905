import React, { useMemo, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Modal, Popover } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProductDetailCarousel from "./ProductDetailCarousel";
import SeeMore from '../../assets/images/seemore-white.svg';
import Share from '../../assets/images/share.png';
import { Accordion } from 'react-bootstrap';
import DownloadIcon from '../../assets/images/download.png';
import DownloadFile from '../../assets/images/pdf.png';

import ShareIcon from '../../assets/images/share-icon.png';
import Next from '../../assets/images/seemore-white.svg';
import Back from '../../assets/images/seeMore-black.svg';
import Attach from '../../assets/images/attach-file.png';
import RelatedProduct from "./RelatedProduct";
import { saveAs } from 'file-saver';
import Facbook from '../../assets/images/fb.png';
import Twitter from '../../assets/images/twit.png';
import whatsapp from '../../assets/images/whatsapp-black.png';
import Instagram from '../../assets/images/instagram-bl.png';
import useForm from "./useForm";
import Select from 'react-select';
import {
    handleSocialMediaClick
} from '../../utils/Validate';

import FetchData, { GetOne, httpHeaders } from '../../providers/dataProvider';
import urls from '../../constants';
import { useQuery } from 'react-query';
import FailModal from '../Popups/FailModal';
import SuccessModal from "../Popups/SuccessModal";
import DownloadDocument from './DownloadDocument';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

const ProductDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const requestOptions = httpHeaders();
    const myParam = new URLSearchParams(location.search);

    const [attachment, setAttachment] = useState(null);
    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);
    const [attachshow, setAttachShow] = useState(false);
    const attachmodalClose = () => setAttachShow(false);
    const attachmodalShow = () => setAttachShow(true);
    const [productDetail, setProductDetail] = useState([]);
    const [documentshow, setDocumentShow] = useState(false);
    const documentmodalClose = () => setDocumentShow(false);
    const documentmodalShow = (attachment) => {
        if ((document.cookie).indexOf("emailverify") < 0) {
            setAttachment(attachment)
            setDocumentShow(true);
        }
        else {
            handleDownload(attachment.wspPatUrl, attachment.wspPatName)
        }
    }


    const [productdt, setProductdt] = useState({
        ProdId: "",
        GroupId: "",
        ProdName: "",
        SubprodName: ""
    });

    const [successpopup, setSuccessShow] = useState(false);
    const successModalClose = () => setSuccessShow(false);
    const successModalShow = () => setSuccessShow(true);
    const [failpopup, setFailShow] = useState(false);
    const failModalClose = () => setFailShow(false);
    const failModalShow = () => setFailShow(true);



    useEffect(() => {
        // const { ProdId, GroupId, ProdName, SubprodName } = location.state;
        // setProductdt({
        //     ProdId: ProdId,
        //     GroupId: GroupId,
        //     ProdName: ProdName,
        //     SubprodName: SubprodName
        // })

        setProductdt({
            ProdId: myParam.get('ProdId'),
            GroupId: myParam.get('GroupId'),
            ProdName: myParam.get('ProdName'),
            SubprodName: myParam.get('SubprodName')
        })
    }, [myParam.get('ProdId')])

    const maxCharacterLength = 80;

    const fetchData = useMemo(() => {
        return () => {
            GetOne('prodDetails', { id: productdt?.ProdId }, (response) => {
                const data = response.data;
                if (data && data.length > 0) {
                    setProductDetail(data[0]);
                }
            });
        };
    }, [productdt]);

    useEffect(() => {
        if (productdt?.ProdId) {
            fetchData();
        }
    }, [productdt]);


    const { isLoading, error, data: countries } = useQuery(
        {
            queryKey: ['Countries'],
            queryFn: () =>
                fetch(urls.COUNTRY_LIST_URL, requestOptions).then((res) => res.json()),
            enabled: true,
        }
    );


    const [countryData, setCountryData] = useState([]);
    const [countrydrp, setCountrydrp] = useState([]);

    //country

    useEffect(() => {
        if (countries) setCountryData(countries)
    }, [countries])


    useEffect(() => {
        const getCountryData = () => {
            if (countryData) {
                const list = formatCountrydrp(countryData);
                setCountrydrp(list);
            }
        };
        getCountryData();
    }, [countryData]);


    const formatCountrydrp = (list) => {
        var te = list?.map((data, index) => ({
            label: data?.wsCountry,
            value: data?.wsCntId
        }));
        return te;
    };


    const setRequestDemo = () => {
        if (window.innerWidth <= 800) {
            navigate('/request-demo', {
                state: {
                    ProdId: productdt?.ProdId,
                    GroupId: productdt?.GroupId,
                    ProdName: productdt?.ProdName,
                    SubprodName: productdt?.SubprodName,
                },
            });
        }

        else {
            modalShow();
        }
    };

    const handleDownload = (url, name) => {
        const fileUrl = url;
        saveAs(fileUrl, `${name}.pdf`);
    };



    const {
        handleFormChange,
        handleSubmit,
        formValues,
        formErrors,
        createMsg,
        successMsg,
        isSubmit,
        handleSubmitForm,
        uploadedFiles,
        handleFileEvent,
        fileLimit,
        MAX_COUNT,
        isSubmitAtt,
        setIsSubmitAtt,
        handleCountryChange,
        handlePhoneChange,
        country,
        handleRemoveFile,
        handleInputBlur,
        errorMessage,
        failureMsg,
        setFormValues,
        setFormErrors,
        setIsSubmit,
        setCountry,
        handleCountry,
        showInternationalFlag,
        selectedCountry
    } = useForm();

    const handleCloseClick = () => {
        setFormValues({});
        setShow(false);
        setAttachShow(false);
        setIsSubmit(false);
        setFormErrors({});
        setCountry('');
    }


    const nextModal = () => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            modalClose(false);
            attachmodalShow(true);
        }
    };

    useEffect(() => {
        nextModal();
    }, [formErrors, isSubmit]);

    const backModal = () => {
        setShow(true);
        setAttachShow(false);
    };

    useEffect(() => {
        if (isSubmitAtt) {
            modalClose();
            attachmodalClose();
            setIsSubmitAtt(false);
        }
    }, [isSubmitAtt]);

    useEffect(() => {
        if (successMsg) {
            modalClose();
            attachmodalClose();
            successModalShow();
        } else {
            successModalClose();
        }
    }, [successMsg]);

    useEffect(() => {
        if (failureMsg) {
            modalClose();
            attachmodalClose();
            failModalShow();
        } else {
            failModalClose();
        }
    }, [failureMsg]);




    //Share the current location 

    const popoverRight = (
        <Popover id="popover-positioned-right" className="right">
            <div className="arrow"></div>
            <div className="social-share">
                <a onClick={() => handleSocialMediaClick('Facebook')} title="Facebook">
                    <img src={Facbook} alt="Facebook" />
                </a>
                <a onClick={() => handleSocialMediaClick('Twitter')} title="Twitter">
                    <img src={Twitter} alt="Twitter" />
                </a>
                <a onClick={() => handleSocialMediaClick('WhatsApp')} title="WhatsApp">
                    <img src={whatsapp} alt="WhatsApp" />
                </a>
                <a onClick={() => handleSocialMediaClick('Instagram')} title="Instagram">
                    <img src={Instagram} alt="Instagram" />
                </a>
            </div>
        </Popover>
    );

    const popoverBottom = (
        <Popover id="popover-positioned-bottom" className="">
            <div className="arrow"></div>
            <div className="social-share">
                <a onClick={() => handleSocialMediaClick('Facebook')} title="Facebook">
                    <img src={Facbook} alt="Facebook" />
                </a>
                <a onClick={() => handleSocialMediaClick('Twitter')} title="Twitter">
                    <img src={Twitter} alt="Twitter" />
                </a>
                <a onClick={() => handleSocialMediaClick('WhatsApp')} title="WhatsApp">
                    <img src={whatsapp} alt="WhatsApp" />
                </a>
                <a onClick={() => handleSocialMediaClick('Instagram')} title="Instagram">
                    <img src={Instagram} alt="Instagram" />
                </a>
            </div>
        </Popover>
    );

    return (
        <>
            <div className="product-detail-sec">

                <div className="bread-crumbs">

                    <nav>
                        <Link to="/"
                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            Home
                        </Link>
                        <span className="breadcrumb-arrow">/</span>
                        <Link to={{ pathname: `/product-list`, search: `?Id=${productdt?.GroupId}&Name=${productdt?.ProdName}` }} onClick={() => { window.scroll(0, 0); }}
                            className={location.pathname.startsWith("/products") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            {productdt?.ProdName}
                        </Link>

                        <span className="breadcrumb-arrow">/</span>
                        <a className="breadcrumb-active">
                            {productdt?.SubprodName}
                        </a>

                    </nav>
                </div>
                <div className="our_products-sec product-detail">
                    <Row>
                        <Col md={5}>
                            <div className="product-detail-left">
                                <OverlayTrigger placement="bottom" overlay={popoverBottom}
                                    delay={{ show: 250, hide: 2000 }}
                                    trigger={["hover", "hover"]}>
                                    <a className="mobile-share-icon"><img src={ShareIcon} /></a>
                                </OverlayTrigger>

                                <ProductDetailCarousel productDetail={productDetail} />
                            </div>

                            <button className="btn display-btn" onClick={() => { window.scroll(0, 0); setRequestDemo() }}>Request for quote <img src={SeeMore} /></button>
                        </Col>
                        <Col md={7}>
                            <div className="product-detail-right">
                                <div className="pdt-detail-title">
                                    <h2>{productDetail?.wsmMdlName}</h2>
                                    <div className="right-sec">
                                        {productDetail?.tblWspMdlCertifications?.map((imgUrl) => (
                                            <img className="client-logo" src={imgUrl?.wsmCetUrl} />

                                        ))}

                                        <OverlayTrigger placement="right" overlay={popoverRight}
                                            delay={{ show: 250, hide: 2000 }}
                                            trigger={["hover", "hover"]}>
                                            <p className="share-loc"><img src={Share} alt="Share" /> Share</p>
                                        </OverlayTrigger>

                                    </div>
                                </div>
                                <p className="pdt-detail-subtitle">{productDetail?.wsmMdlSrtDesc}</p>
                                <p className="gray-text">Overview</p>
                                <p className="pdt-description">
                                    <div dangerouslySetInnerHTML={{ __html: productDetail?.wsmMdlDetails }} />
                                </p>
                                <div className="pdt-features">
                                    <Accordion>
                                        {Object.values(
                                            (productDetail?.tblWsProdAttachments || []).reduce((groups, item) => {
                                                const { wspAttId } = item;
                                                if (!groups[wspAttId]) {
                                                    groups[wspAttId] = [];
                                                }
                                                groups[wspAttId].push(item);
                                                return groups;
                                            }, {})
                                        ).map((group, index) => (
                                            <Accordion.Item key={index} eventKey={index}>

                                                <Accordion.Header>{group[0]?.wspAttName}</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul className="file-ul">
                                                        {group.map((attachment) => (
                                                            <li key={attachment.wspPatId}>
                                                                <div className="download-sec">

                                                                    {attachment.wspPatName.length > maxCharacterLength ? (
                                                                        <a onClick={() => { documentmodalShow(attachment); }} title={attachment.wspPatName}>
                                                                            <img src={DownloadFile} />
                                                                            <p>{attachment.wspPatName.slice(0, maxCharacterLength - 3) + "..."}</p>
                                                                            <span>
                                                                                <img src={DownloadIcon} />
                                                                            </span>
                                                                        </a>
                                                                    ) : (
                                                                        <a onClick={() => { documentmodalShow(attachment); }} title={attachment.wspPatName}>
                                                                            <img src={DownloadFile} />
                                                                            <p>{attachment.wspPatName}</p>
                                                                            <span>
                                                                                <img src={DownloadIcon} />
                                                                            </span>
                                                                        </a>

                                                                    )}


                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>


                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Related Product */}
                <RelatedProduct GroupId={productdt?.GroupId} />

            </div>



            {/* Request demo modal */}

            <Modal centered show={show} onHide={modalClose} className="new-demo-modal">
                <Modal.Header>
                    <Modal.Title>Request a Quote</Modal.Title>
                    <button type="button" class="btn-close" aria-label="Close" onClick={handleCloseClick}>X</button>
                </Modal.Header>


                <Modal.Body className="show-grid">
                    <p>Enter your information below</p>
                    <div className="contact-form">
                        <form onSubmit={handleSubmitForm}>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsFirstName" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsFirstName} />
                                        <label class="did-floating-label">First Name</label>
                                    </div>
                                    {formErrors.wsFirstName && <p className="form-error">{formErrors.wsFirstName}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsLastName" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsLastName} />
                                        <label class="did-floating-label">Last Name</label>
                                    </div>
                                    {formErrors.wsLastName && <p className="form-error">{formErrors.wsLastName}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        {formValues.wsPhone && <label class="did-floating-label" style={{ top: "-15px", color: "#898989" }}>Phone Number(Optional)</label>}

                                        <PhoneInput
                                            international={showInternationalFlag}
                                            defaultCountry={selectedCountry}
                                            id='MobileNo'
                                            name='wsPhone'
                                            value={formValues?.wsPhone}
                                            onChange={handlePhoneChange}
                                            onBlur={handleInputBlur}
                                            placeholder="Mobile Number"
                                            onCountryChange={handleCountry}
                                            countryCallingCodeEditable={false}
                                        />

                                        {/* <input class="did-floating-input" type="text" placeholder=" " name="wsPhone" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsPhone} /> */}

                                    </div>
                                    {formErrors.wsPhone && <p className="form-error" style={{ marginLeft: "38px" }}>{formErrors.wsPhone}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsEmail" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsEmail} />
                                        <label class="did-floating-label">Email</label>
                                    </div>
                                    {formErrors.wsEmail && <p className="form-error">{formErrors.wsEmail}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsProject" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsProject} />
                                        <label class="did-floating-label">Project</label>
                                    </div>
                                    {formErrors.wsProject && <p className="form-error">{formErrors.wsProject}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsCustomer" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsCustomer} />
                                        <label class="did-floating-label">Company</label>
                                    </div>
                                    {formErrors.wsCustomer && <p className="form-error">{formErrors.wsCustomer}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsAddress1" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsAddress1} />
                                        <label class="did-floating-label">Address 1</label>
                                    </div>
                                    {formErrors.wsAddress1 && <p className="form-error">{formErrors.wsAddress1}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsAddress2" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsAddress2} />
                                        <label class="did-floating-label">Address 2</label>
                                    </div>
                                </Col>

                                <Col sm={12} md={6}>
                                    <div className="did-floating-label-content">
                                        {country && <label class="did-floating-label" style={{ top: "-18px" }}>Country</label>}
                                        <Select
                                            className="did-floating-input"
                                            name="wsCountry"
                                            value={country}
                                            options={countrydrp}
                                            onChange={handleCountryChange}
                                            onBlur={handleInputBlur}
                                            placeholder="Select Country"
                                            isSearchable
                                        />
                                    </div>
                                    {formErrors.country && <p className="form-error">{formErrors.country}</p>}
                                </Col>

                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsState" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsState} />
                                        <label class="did-floating-label">State/Providence</label>
                                    </div>
                                    {formErrors.wsState && <p className="form-error">{formErrors.wsState}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsCity" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsCity} />
                                        <label class="did-floating-label">City</label>
                                    </div>
                                    {formErrors.wsCity && <p className="form-error">{formErrors.wsCity}</p>}
                                </Col>
                                <Col sm={12} md={6}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsZipCode" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsZipCode} />
                                        <label class="did-floating-label">Zip/Postal Code</label>
                                    </div>
                                    {formErrors.wsZipCode && <p className="form-error">{formErrors.wsZipCode}</p>}
                                </Col>
                                <Col sm={12} md={12}>
                                    <div class="did-floating-label-content">
                                        <input class="did-floating-input" type="text" placeholder=" " name="wsMessage" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsMessage} />
                                        <label class="did-floating-label">Message</label>
                                    </div>
                                </Col>
                                <Col sm={12} md={7} className="submit-sec">
                                    {Object.keys(formErrors).length === 0 ? (
                                        <button type="submit" className="btn submit-btn" onClick={nextModal}>Next <img src={Next} /></button>
                                    ) : (
                                        <button type="submit" className="btn submit-btn" onClick={handleSubmitForm} >Next <img src={Next} /></button>
                                    )}
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Attach file modal */}
            <Modal centered show={attachshow} onHide={attachmodalClose} className="new-demo-modal">
                <Modal.Header>
                    <Modal.Title>Attach file</Modal.Title>
                    <button type="button" class="btn-close" aria-label="Close" onClick={attachmodalClose}>X</button>
                </Modal.Header>


                <Modal.Body className="show-grid">
                    <p>To help expedite the quote process, include attachments about your product or project
                        request like a photo equipment schedule or building schematic.</p>

                    <form class="form-container" onSubmit={handleSubmit}>
                        <div class="upload-files-container">
                            <div class="drag-file-area">
                                {uploadedFiles.length > 0 ? <>
                                    {uploadedFiles.map((file, index) => (
                                        <div key={index} className="uploaded-box">
                                            <a href={file.WsAttachUrl} target="_blank" rel="noopener noreferrer">
                                                <img src={DownloadFile} alt="Download" />
                                                <span>
                                                    {file.WsAttachName}
                                                </span>
                                            </a>

                                            <button onClick={() => handleRemoveFile(index)} className="close-img">
                                                X
                                            </button>
                                        </div>
                                    ))}

                                </> : <>
                                    <img src={Attach} onChange={handleFileEvent} />
                                    <h3 class="dynamic-message" onChange={handleFileEvent}>Select a file to import</h3>
                                    <label class="label">
                                        excel, pdf, docx{" "}
                                        <span class="browse-files">
                                            <input
                                                type="file"
                                                name="file"
                                                class="default-file-input"
                                                onChange={handleFileEvent}
                                                accept="application/msword, application/pdf, application/vnd.ms-excel"
                                            />
                                        </span>
                                    </label>
                                </>
                                }

                                {fileLimit && (
                                    <div className="invalid-feedback">
                                        File limit exceeded. Maximum of {MAX_COUNT} files are allowed.
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                    {errorMessage && <div className="form-error">{errorMessage}</div>}
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" class="btn back-btn" onClick={backModal}><img src={Back} />Back</button>

                    <button type="submit" class="btn submit-btn" onClick={handleSubmit} >Submit<img src={Next} /></button>
                </Modal.Footer>
            </Modal>



            {/*Popup success message*/}
            <SuccessModal successpopup={successpopup} successModalClose={successModalClose} successMsg={successMsg} />

            <FailModal failpopup={failpopup} failModalClose={failModalClose} failureMsg={failureMsg} />




            {/* Download document modal */}
            <DownloadDocument documentshow={documentshow}
                documentmodalClose={documentmodalClose}
                setDocumentShow={setDocumentShow}
                handleDownload={handleDownload}
                attachment={attachment} />
        </>
    )
};

export default ProductDetail