import React from 'react';
import Loader from "react-js-loader";

const Spinner = () => {
  return (
    <>
        <div className={"item"}>
                <Loader type="spinner-circle" bgColor={"#81868C"} title={""} color={'#81868C'} size={70} />
            </div>

    </>
  )
}

export default Spinner