import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Seemore from '../../assets/images/seemore.svg';
import Next from '../../assets/images/next-button.svg';
import { httpHeaders } from '../../providers/dataProvider';
import ContactData from '../Common/ContactData';
import urls from '../../constants';
import { useQuery } from 'react-query';

const Reference = () => {
  const location = useLocation();
  const { RefId } = location.state || {};

  const [refDetail, setRefDetail] = useState([]);
  const [refFullDetail, setRefFullDetail] = useState([]);
  const [selectedOption, setSelectedOption] = useState(RefId || "0");
  const [visibleProjects, setVisibleProjects] = useState(8);
  const [loadMoreCount, setLoadMoreCount] = useState(12);

  const requestOptions = httpHeaders();

  const { isLoading: loading1, error: error1, data: data1 } = useQuery(
    {
      queryKey: ['ProjectReferences1'],
      queryFn: () =>
        fetch(urls.PRODUCT_REF_URL, requestOptions).then((res) => res.json()),
      enabled: true,
    }
  );


  const { isLoading: loading2, error: error2, data: data2, refetch: refetchData2 } = useQuery({
    queryKey: ['ProjectReferences2', selectedOption],
    queryFn: async () => {
      if (selectedOption !== "") {
        const res = await fetch(`${urls.PRODUCT_REF_URL}${selectedOption}`, requestOptions);
        return await res.json();
      }
      return Promise.resolve([]);
    },
    enabled: selectedOption !== "",
    cacheTime: 0,
  });


  useEffect(() => {
    if (data2 && data2.length > 0) {
      setRefDetail(data2);
    }
  }, [selectedOption, data2, refetchData2]);

  useEffect(() => {
    if (refDetail) {
      const fullDetail = refDetail.flatMap((items) => {
        const filteredProjects = items?.tblWsRefProjects.filter(
          (project) => project.wsRefId === items.wsRefId
        );
        return filteredProjects.map((project) => ({
          ...project,
          wsRefName: items.wsRefName,
        }));
      });
      setRefFullDetail(fullDetail);
    }
  }, [refDetail]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    refetchData2();
  };

  const handleLoadMore = () => {
    setVisibleProjects((prevVisibleProjects) => prevVisibleProjects + loadMoreCount);
  };

  useEffect(() => {
    if (selectedOption >= "0") 
    {
      setLoadMoreCount(12);
      setVisibleProjects(8); 
    }
  }, [selectedOption]);
  

  const [projectTitle, setProjectTitle] = useState("All Projects");

  useEffect(() => {
    if (selectedOption !== "0" && refDetail.length > 0) {
      const selectedProject = refDetail.find((item) =>
        item?.wsRefId === parseInt(selectedOption)

      );

      if (selectedProject) {
        setProjectTitle(selectedProject.wsRefName);
      }
    } else {
      setProjectTitle("All Projects");
    }
  }, [selectedOption, refDetail]);

  return (
    <>
      <div className="our-reference">
        <div className="our_products-sec reference">
          <div className="sec-bottom">
            <h2 className="sec-title">Our References</h2>
            <select className="form-control" value={selectedOption} onChange={handleSelectChange}>
              {data1?.map((project) => (
                <option key={project.wsRefId} value={project.wsRefId === 10 ? "0" : project.wsRefId}>
                  {project.wsRefName}
                </option>
              ))}
            </select>
          </div>
          <div className="reference-list">
            <p className="sec-bottom-title">
              {projectTitle}

            </p>
            <div className="col-md-12">
              <Row>
                {refFullDetail?.slice(0, visibleProjects)
                  .map((item) =>
                  (
                    <Col xl={3} lg={4} md={6} sm={6} xs={12} className="pl-1 pr-1 mb-4 mb-4" key={item.wsPrjId}>
                      <div className="carousel__item right">
                        <div className="image-box">
                          <img width="100%" src={item?.tblWsProjectImages[0]?.waImgUrl} alt="" />
                        </div>
                       
                        <Link to={{pathname: `/reference-detail`, search: `?RefId=${item?.wsRefId}&prodId=${item?.tblWsProjectImages[0]?.wsPrjId}&?RefName=${item?.wsRefName}`}} onClick={() => {window.scroll(0, 0);}}>
                          
                          <div className="middle">
                            <div className="text">
                              <img src={Next} alt="" />
                              <p>Explore</p>
                            </div>
                            <h2>{item?.wsPrjLocation}</h2>
                          </div>
                        </Link>
                        <div className="carousel__caption">
                          <h5>{item?.wsPrjName}</h5>
                          <p> {item?.wsRefName}  - {item?.wsPrjLocation}</p>

                        </div>
                      </div>
                    </Col>
                  )
                  )}
              </Row>
              {refFullDetail.length > 0 && visibleProjects < refFullDetail?.length && (
                <div className="load-more">
                  <button className="seemore-btn" onClick={handleLoadMore}>
                    Load more <img src={Seemore} alt="" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <ContactData />
      </div>
    </>
  );
};

export default Reference;
