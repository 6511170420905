import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Popover } from 'react-bootstrap';
import Fail from '../../assets/images/failure.svg';
import Success from '../../assets/images/success.svg';
import Next from '../../assets/images/seemore-white.svg';

const OtpVerifyModal = (props) => {
  const { otpshow, otpmodalClose, otpMessage, setOtpShow, handleDownload, attachment } = props
  const [successpopup, setSuccessShow] = useState(false);
  const [otpInput, setOtpInput] = useState(new Array(4).fill(""));
  const successModalClose = () => setSuccessShow(false);
  const successModalShow = () => {
    setOtpShow(false);
    setSuccessShow(true);
  }
  const [failpopup, setFailShow] = useState(false);
  const failModalClose = () => setFailShow(false);
  const failModalShow = () => {
    setOtpShow(false);
    setFailShow(true);
  }
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setOtpInput(new Array(4).fill(""))
  }, [otpMessage])

  useEffect(() => {
    const otpFieldValue = otpInput.join("");
    otpFieldValue?.length == 4 ? setDisabled(false) : setDisabled(true);
  }, [otpInput]);

  const handleKeyDown = (e, index) => {

    if (e.key === 'Backspace' || e.key === 'Delete') {
      setOtpInput([
        ...otpInput?.map((d, idx) => (idx == index ? "" : d)),
      ]);

      switch (e.target.tabIndex) {
        case 1:
          document.getElementById("OTP1").focus();
          break;
        case 2:
          document.getElementById("OTP1").focus();
          break;
        case 3:
          document.getElementById("OTP2").focus();
          break;
        case 4:
          document.getElementById("OTP3").focus();
          break;
      }

    }
    else {
      if (isNaN(e.key)) return false;
      setOtpInput([
        ...otpInput?.map((d, idx) => (idx == index ? e.key : d)),
      ]);

      switch (e.target.tabIndex) {
        case 1:
          document.getElementById("OTP2").focus();
          break;
        case 2:
          document.getElementById("OTP3").focus();
          break;
        case 3:
          document.getElementById("OTP4").focus();
          break;
      }

    }
  };

  const handleSubmit = async () => {
    let result = {};
    const otp = otpInput.join("")
    if (otp === otpMessage?.data?.verifyCode) {
      successModalShow();
      document.cookie = "emailverify=true";

    }
    else {
      failModalShow();
    }
  };

  return (
    <>
      <Modal centered show={otpshow} onHide={otpmodalClose} className="new-demo-modal otp-modal">
        <Modal.Header>
          <Modal.Title>Verification code</Modal.Title>
          <button type="button" class="btn-close" aria-label="Close" onClick={otpmodalClose}>X</button>
        </Modal.Header>


        <Modal.Body className="show-grid">
          <p>Please enter the verification code that has been sent to your email address.</p>
          <div className="contact-form">
            <Row>
              {otpInput?.map((data, index) => {
                return (
                  <Col sm={2} md={2}>
                    <div class="did-floating-label-content">
                      <input
                        class="did-floating-input"
                        type="text"
                        id={`OTP${index + 1}`}
                        name="otp"
                        value={data}
                        //  onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        maxLength="1"
                        key={index}
                        tabIndex={index + 1}
                      />
                    </div>
                  </Col>
                );
              })}
              <Col sm={12} md={7} className="submit-sec">
                <button onClick={handleSubmit} disabled={disabled} className="btn submit-btn">Verify <img src={Next} /></button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>


      {/*  Success popup modal  */}
      <Modal centered show={successpopup} onHide={successModalClose} className="new-demo-modal success-modal">

        <Modal.Body className="show-grid">
          <div className="body-box">
            <img src={Success} />
            <h1>Success</h1>
            <p>Email verified successfully. Downloading documnet ...</p>
            <div className="submit-sec">
              <button className="btn submit-btn" onClick={() => { handleDownload(attachment.wspPatUrl, attachment.wspPatName); successModalClose(); }}>Continue <img src={Next} /></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Fail popup modal */}

      <Modal centered show={failpopup} onHide={failModalClose} className="new-demo-modal fail-modal">

        <Modal.Body className="show-grid">
          <div className="body-box">
            <img src={Fail} />
            <h1>Invalid OTP</h1>
            <p>OTP you have entered is not a valid one. Please verify your email.</p>
            <div className="submit-sec">
              <button className="btn submit-btn" onClick={failModalClose}>Try Again <img src={Next} /></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}



export default OtpVerifyModal;