
let apiBaseUrl = process.env.REACT_APP_API_URL + '/api';

const urls = {
    PRODUCT_SUBPROD_URL: apiBaseUrl + '/ProductGrp/SubGroups',
    PRODUCT_LIST_URL: apiBaseUrl + '/ProductGrp/AllSubCategoriesModel?GrpID=',
    PRODUCT_DETAILS_URL: apiBaseUrl + '/ProductModels/GetModelDetails?Mdlid=',
    PRODUCT_REF_URL: apiBaseUrl + '/ProjectReferences/',
    PRODUCT_REF_DETAIL_URL: apiBaseUrl + '/ProjectReferences/Projects/',
    PRODUCT_CONTACT_URL: apiBaseUrl + '/Contacts',
    PRODUCT_ENQUIRY_URL: apiBaseUrl + '/Enquiries',
    DOWNLOAD_CENTER_URL: apiBaseUrl + '/ProdAttachments/Downloads',
    CAREER_LIST_URL: apiBaseUrl + '/Careers',
    COUNTRY_LIST_URL: apiBaseUrl + '/Countries',
    CREATE_CAREER_URL: apiBaseUrl + '/CareerApplications',
    DOWNLOAD_CENTER_FILTER_URL: apiBaseUrl + '/ProdAttachments/DownloadFilters',
    LOGIN_URL: apiBaseUrl + '/Users/Login',
    AWS_ACCESS_KEY_ID: 'AKIAV36Z3YERVMLDCQU7',
    AWS_ACCESS_SECRET_KEY: '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',
    AWS_REGION: 'me-south-1',
    SEND_OTP_URL: apiBaseUrl + '/General/GenerateOTP?EmailID=',
    DOWNLOAD_CONTACTS_URL: apiBaseUrl + '/DownloadContacts',
    AWS_BUCKET_NAME: 'jmcwebsitefiles'
};

export default urls;