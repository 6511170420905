// ProductFilter.js
import React from 'react';
import { Accordion } from 'react-bootstrap'

const ProductFilter = ({ uniquepropname, uniquepropvalue, productdisp, checkedprop, checkedprod, handleClear, handleCheckprop, handleCheckprod,productId}) => {
  return (
    <Accordion defaultActiveKey={['0',1,2,3,4,5,6,7,8,9]} alwaysOpen>
      {
        <>
         {[1,3,4].includes(productId) && <Accordion.Item  eventKey="0">
            <Accordion.Header>Product</Accordion.Header>
            <Accordion.Body>
            {checkedprod?.length>0? <a className="clear-btn" onClick={()=>handleClear("product")}>Clear</a>:""}

              {productdisp?.map((item, index) => {
                return (
                  <ul>
                      <li>
                        <input
                          type="checkbox"
                          checked={checkedprod.some(
                            (checkedItem) =>
                              checkedItem.productName === item.productName
                          )}
                           onChange={() => handleCheckprod(item)}
                        />
                        <label>{item?.productName}</label>
                      </li>
                  </ul>
                )
              })
              }
            </Accordion.Body>
          </Accordion.Item>
          }

          {uniquepropname?.map((propname, propindex) => {
            const valueitem = uniquepropvalue?.filter(function (item1) {
              return item1?.prop_name == propname?.prop_name
            })

            return (
              <Accordion.Item eventKey={(propindex*1)+1}>
                <Accordion.Header>{propname?.prop_name}</Accordion.Header>
                <Accordion.Body>
                {checkedprop.some(
                                (checkedItem) =>
                                  checkedItem.prop_name === propname.prop_name
                              ) === true? <a className="clear-btn" onClick={()=>handleClear("productprop",propname?.prop_name)}>Clear</a>:""}
                  {valueitem?.map((propvalue, valueindex) => {

                    return (
                      <ul>
                          <li>
                            <input
                              type="checkbox"
                              checked={checkedprop.some(
                                (checkedItem) =>
                                  checkedItem.prop_value === propvalue.prop_value
                              )}
                               onChange={() => handleCheckprop(propvalue)}
                            />
                            <label>{propvalue?.prop_value}</label>
                          </li>
                      </ul>
                    )
                  })
                  }
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </>
      }
    </Accordion>

  )
}

export default ProductFilter;