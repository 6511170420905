import React, { useEffect, useState } from 'react';
import CounterPlus from '../../assets/images/counter-plus.svg';



const Count = props => {
  // label of counter
  // number to increment to
  // duration of count in seconds
  const {label, number, duration } = props.data

  // number displayed by component
  const [count, setCount] = useState("0")



  return (
   
    <div class="col-sm count-col">
      <div className='count-box'>
        
        <p class="fs-2 counter-number"><img src={CounterPlus} /> <span data-count={number}>0</span></p>
        <p class="text-light counter-label">{label}</p>
        </div>
    </div>
            
  );
}

export default Count;