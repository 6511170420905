import React, { useState } from 'react';
import { Container, Accordion } from 'react-bootstrap'
import '../../assets/css/mainpage.css';
import '../../assets/css/footer.css';
import Logo from '../../assets/images/footer-logo.png';
import Facebook from '../../assets/images/facebook.png';
import Instagram from '../../assets/images/instagram.png';
import Linkedin from '../../assets/images/linkedin.png';
import Twitter from '../../assets/images/twitter.png';
import Download from '../../assets/images/download.svg';
import { Link } from 'react-router-dom';
import { httpHeaders } from '../../providers/dataProvider';
import urls from '../../constants';
import { useQuery } from 'react-query';


function Footer() {

    const requestOptions = httpHeaders();

    const { isLoading, error, data: subproduct } = useQuery(
      {
        queryKey: ['subproduct'],
        queryFn: () =>
          fetch(urls.PRODUCT_SUBPROD_URL, requestOptions).then((res) => res.json()),
        enabled: true,
      }
    );


    return (
        <footer className="mainfooter">
            <div className="bottom-footer">
                <div className='footer-desc'>
                    <h2>Download our e-brochures <br />& choose your preferred products <br /> from our library</h2>
                    <button className='btn display-btn'><a href='https://jmcweblink.blob.core.windows.net/jmcfilelink/JMFG_Datasheets/brochure/Jersey%20Mfg_Product%20brochure.pdf' target='_blank'>Download e-brochures <img src={Download} /></a></button>
                </div>

                <nav className="toplink large-view">
                    <div className="logo-wrapper">
                        <Link className="anchor logo-anchor" to='/'>
                            <picture>
                                <img
                                    srcSet={Logo}
                                    alt="jersey manufacturing"
                                />
                            </picture>
                        </Link>
                        <div className='follow-us-sec'>
                            <p className="followtitle">Follow us</p>
                            <ul className="follow-us">
                                <li className='followlink'>
                                    <a href='https://www.facebook.com/JMCQatar' target='_blank'>
                                        <picture>
                                            <img
                                                srcSet={Facebook}
                                                alt="facebook"
                                            />
                                        </picture>
                                    </a>
                                </li>
                                <li className='followlink'>
                                    <a href='https://www.instagram.com/jmcqatar/' target='_blank'>
                                        <picture>
                                            <img
                                                srcSet={Instagram}
                                                alt="Instagram"
                                            />
                                        </picture>
                                    </a>
                                </li>
                                <li className='followlink'>
                                    <a href='https://www.linkedin.com/company/jersey-manufacturing/' target='_blank'>
                                        <picture>
                                            <img
                                                srcSet={Linkedin}
                                                alt="Linkedin"
                                            />
                                        </picture>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul className="footernav">
                        <li className="footerlink title">Get In Touch</li>
                        <li className="footerlink address">
                            <a><strong>Jersey Manufacturing for Airconditioning
                                <br/> Products W.L.L</strong>
                                <br/>
                             PO Box 1038, St. No: 21, Small & Medium
                             <br/>Industries area, Doha, Qatar.</a>
                        </li>
                        <li className='footerlink phno'>
                            <a style={{textTransform:'math-auto'}}>Tel: +974 4494 1300</a>
                        </li>
                        <li className='footerlink phno'>
                            <a style={{textTransform:'math-auto'}}>Fax: +974 4418 1867</a>
                        </li>
                        <li className='footerlink phno'>
                            <a href="mailto:info@jerseymfg.com">info@jerseymfg.com</a>
                        </li>
                        <li className='footerlink phno'>
                            <a href="www.jerseymfg.com">www.jerseymfg.com</a>
                        </li>
                    </ul>
                    {subproduct && (
                    <ul className="footernav">
                        <li className="footerlink title">Products</li>
                        {subproduct?.map((item, index) => (
                            <li className="footerlink">
                                <Link to={{pathname: `/product-list`, search: `?Id=${item?.wspGrpId}&Name=${item?.wspGrpName}`}}  onClick={() => {window.scroll(0, 0);}}>
                                    {item?.wspGrpName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    )}

                    <ul className="footernav">
                        <li className="footerlink title">Resources</li>

                        <li className="footerlink">
                            <Link to='/certification' onClick={() => {window.scroll(0, 0);}}>Certifications</Link>
                        </li>
                        <li className="footerlink">
                            <Link to='/download-center' onClick={() => {window.scroll(0, 0);}}>Download center</Link>
                        </li>
                        <li className="footerlink">
                            <Link to='/reference' onClick={() => {window.scroll(0, 0);}}>Projects</Link>
                        </li>
                        <li className="footerlink">
                            <Link to='/terms-conditions' onClick={() => {window.scroll(0, 0);}}>Terms & Conditions</Link>
                        </li>
                        <li className="footerlink">
                            <Link to='/warranty' onClick={() => {window.scroll(0, 0);}}>Warranty</Link>
                        </li>
                    </ul>
                    <ul className="footernav">
                        <li className="footerlink title">About Us</li>

                        <li className="footerlink">
                            <Link to='/about' onClick={() => {window.scroll(0, 0);}}>Jersey Manufacturing</Link>
                        </li>
                        <li className="footerlink">
                            <a href='/about/#VisionMission'> Vision & Mission</a>
                        </li>
                        <li className="footerlink">
                            <a href='/about/#History'>History</a>
                        </li>
                        <li className="footerlink">
                            <Link to='/contact-us' onClick={() => {window.scroll(0, 0);}}>Contact us</Link>
                        </li>
                        <li className="footerlink">
                            <Link to='/news-events' onClick={() => {window.scroll(0, 0);}}>News & Events</Link>
                        </li>
                        <li className="footerlink">
                            <Link to='/careers' onClick={() => {window.scroll(0, 0);}}>Careers</Link>
                        </li>
                    </ul>
                </nav>
                {/* Mobile view */}
                <div className='mobile-footer'>
                    <div className="logo-wrapper">
                        <Link className="anchor logo-anchor" to='/'>
                            <picture>
                                <img
                                    srcSet={Logo}
                                    alt="jersey manufacturing"
                                />
                            </picture>
                        </Link>

                    </div>
                    <div className='list-items'>
                        <Accordion  defaultActiveKey={['0','1','2']} alwaysOpen>
                        {subproduct && (
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Products</Accordion.Header>
                                <Accordion.Body>
                                    {subproduct?.map((item, index) => (
                                        <li className="footerlink">
                                        <Link to={{pathname: `/product-list`, search: `?Id=${item?.wspGrpId}&Name=${item?.wspGrpName}`}}  onClick={() => {window.scroll(0, 0);}}>
                                                {item?.wspGrpName}
                                            </Link>
                                        </li>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Resources</Accordion.Header>
                                <Accordion.Body>
                                    <li className="footerlink">
                                        <Link to='/certification' onClick={() => {window.scroll(0, 0);}}>Certifications</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/download-center' onClick={() => {window.scroll(0, 0);}}>Download center</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/reference' onClick={() => {window.scroll(0, 0);}}>Projects</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/terms-conditions' onClick={() => {window.scroll(0, 0);}}>Terms & Conditions</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/warranty' onClick={() => {window.scroll(0, 0);}}>Warranty</Link>
                                    </li>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>About Us</Accordion.Header>
                                <Accordion.Body>
                                    <li className="footerlink">
                                        <a href='/about/#VisionMission'> Vision & Mission</a>
                                    </li>
                                    <li className="footerlink">
                                        <a href='/about/#History'>History</a>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/contact-us' onClick={() => {window.scroll(0, 0);}}>Contact us</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/news-events' onClick={() => {window.scroll(0, 0);}}>News & Events</Link>
                                    </li>
                                    <li className="footerlink">
                                        <Link to='/careers' onClick={() => {window.scroll(0, 0);}}>Careers</Link>
                                    </li>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <ul className="footernav">
                        <li className="footerlink title">Get In Touch</li>
                        <li className="footerlink">
                            <a><strong>Jersey Manufacturing for Airconditioning
                                <br/> Products W.L.L</strong>
                                <br/>
                             PO Box 1038, St. No: 21, Small & Medium
                             <br/>Industries area, Doha, Qatar.</a>
                        </li>
                        <li className='footerlink'>
                            <a>Tel: +974 4494 1300</a>
                        </li>
                        <li className='footerlink'>
                            <a>Fax: +974 4418 1867</a>
                        </li>
                        <li className='footerlink'>
                            <a href="mailto:info@jerseymfg.com">info@jerseymfg.com</a>
                        </li>
                        <li className='footerlink'>
                            <a href="www.jerseymfg.com">www.jerseymfg.com</a>
                        </li>
                    </ul>
                    <div className='follow-us-sec'>
                        <p className="followtitle">Follow us</p>
                        <ul className="follow-us">
                            <li className='followlink'>
                                <a href='https://www.facebook.com/JMCQatar' target='_blank'>
                                    <picture>
                                        <img
                                            srcSet={Facebook}
                                            alt="facebook"
                                        />
                                    </picture>
                                </a>
                            </li>
                            <li className='followlink'>
                                <a href='https://www.instagram.com/jmcqatar/' target='_blank'>
                                    <picture>
                                        <img
                                            srcSet={Instagram}
                                            alt="Instagram"
                                        />
                                    </picture>
                                </a>
                            </li>
                            <li className='followlink'>
                                <a href='https://www.linkedin.com/company/jersey-manufacturing/' target='_blank'>
                                    <picture>
                                        <img
                                            srcSet={Linkedin}
                                            alt="Linkedin"
                                        />
                                    </picture>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <nav className="toplink region">
                    <ul className="footernav regionnav">
                        <li className="regionlink copyright">
                            <span>© Jersey Manufacturing 
                                 subject to copyright. all rights reserved.</span>
                        </li>
                        <li className="regionlink">
                            <Link to='/privacy-policy' onClick={() => {window.scroll(0, 0);}}>Privacy Policy</Link>
                        </li>
                        <li className="regionlink">
                            <Link to='/disclaimer' onClick={() => {window.scroll(0, 0);}}>Terms of use</Link>
                        </li>
                        <li className="regionlink designedby">
                            <a>Developed by LINKS4ENGG</a>
                        </li>
                    </ul>
                </nav>

            </div>


        </footer>
    );
}

export default Footer