import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import Next from '../../assets/images/seemore-white.svg';
import Fail from '../../assets/images/failure.svg';

const FailModal = ({failpopup,failModalClose,failureMsg}) => {
    return(
    <Modal centered show={failpopup} onHide={failModalClose} className="new-demo-modal fail-modal">        
        <Modal.Body className="show-grid">
            <div className="body-box">
                <img src={Fail} />
                <h1>Error</h1>
                <p>{failureMsg}</p>
                <div className="submit-sec">
                    <button type="submit" className="btn submit-btn" onClick={failModalClose}>Try Again <img src={Next} /></button>
                </div>
            </div>
        </Modal.Body>
    </Modal>

    );
};


export default FailModal