import React from "react";
import {Container , Row, Col} from 'react-bootstrap';
import logo from '../../assets/images/logo-icon.png';
import Next from '../../assets/images/seemore-white.svg';


const Login = () =>{
    return(
        <div className="login-page">
            <div className="login-sec">
                <Row>
                    <Col md={6}>
                        <div className="left-side">
                            <img src={logo} />
                            <h1>Hi, Welcome Back</h1>
                            <p>Login to your account to add product to RFQ list</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form">
                            <form>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Email Address</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div class="did-floating-label-content">
                                            <input class="did-floating-input" type="text" placeholder=" " />
                                            <label class="did-floating-label">Full Name</label>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="signedin">
                                            <div class="form-check">
                                                <div className='agree-check'>
                                                    <input type="checkbox" class="form-check-input" id="check2" name="option2" value="something" />
                                                <label class="form-check-label" for="check2">Keep Me Signed In </label>
                                                </div>
                                            </div>
                                            <a href="">Forgotten Your Password?</a>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <button className="btn display-btn">Login <img src={Next} /></button>
                                    </Col>
                                    <Col md={12}>
                                        <p className="register-link">Not a Member? <a href="/new-register">Register Now</a></p>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        
    )
};
export default Login