import React, { useState, useEffect } from 'react';
import "../../assets/css/mainpage.css";
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import More from '../../assets/images/more.svg';
import ReferenceDetailCarousel from './ReferenceDetailCarousel';
import { GetOne } from "../../providers/dataProvider";
import ProductCarousel from './ProductSupplied';

const ReferenceDetail = () => {
    const location = useLocation();
    const myParam = new URLSearchParams(location.search);
    const RefId = myParam.get('RefId');
    const prodId = myParam.get('prodId');
    const RefName = myParam.get('RefName');
    const [listProj, setListProj] = useState([]);

    const [productRelatedData, setProductRelatedData] = useState([]);

    const fetchData = () => {
        GetOne('ReferenceDetail', { id: prodId }, (data) => {
            if (data?.data && data.data.length > 0) {
                const refDetailResponse = data.data[0] || {};
                setListProj(refDetailResponse);
            }
        });
    };



    const relatedData = () => {
        GetOne('ProjectReferences', { id: RefId }, (data) => {

            if (data?.data && data.data.length > 0) {
                const refDetailResponse = data.data[0]?.tblWsRefProjects || [];
                const filteredProjects = refDetailResponse.filter((item) => item.wsPrjId !== parseInt(prodId))
                console.log("filteredProjects", filteredProjects);
                setProductRelatedData(filteredProjects);
            }
        });
    };

    useEffect(() => {
        if (RefId && prodId) {
            fetchData();
        }
    }, [RefId, prodId]);

    useEffect(()=>{
        relatedData();
    },[prodId])


    return (
        <div className="reference-detail">
            <div className="refer-detail">
                {listProj && listProj.tblWsProjectImages && listProj.tblWsProjectImages.length > 0 && (
                    <img src={listProj.tblWsProjectImages[0].waImgUrl} className='img-fluid' alt="" />
                )}

            </div>
            <div className="bread-crumbs">
                <nav>
                    <Link to="/reference"
                        className={location.pathname === "/reference" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                        Our References
                    </Link>
                    <span className="breadcrumb-arrow">/</span>
                    <Link
                        className={location.pathname.startsWith("/reference-detail") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                        {listProj?.wsPrjName}
                    </Link>

                </nav>
            </div>
            <Row className="m-0">
                <Col sm={6} md={6} className="p-0">
                    <div className="our_products-sec ref-detail">
                        <p className="sec-top-title">{RefName}</p>
                        <div className="sec-bottom">
                            <h2 className="sec-title">{listProj?.wsPrjName}</h2>
                        </div>
                    </div>
                </Col>
                <Col sm={6} md={6}>
                    <div className="detail-right">
                        <h4>{listProj?.wsPrjLocation}</h4>
                        <p>{listProj?.waPrjDetails}</p>

                    </div>
                </Col>
            </Row>
            <div className="our_products-sec related-product">
                <p className="sec-top-title">{listProj?.wsPrjName}</p>
                <div className="sec-bottom">
                    <h2 className="sec-title">Products Supplied</h2>
                </div>
                <div className="related-carouselItem">
                    <Col md={12}>
                        <Row>
                            <div className="carouselItem pdt-supplied-carousel">
                                <ProductCarousel listProj={listProj?.tblWsRefProjectProducts} />
                            </div>
                        </Row>
                    </Col>
                </div>
            </div>
            <div className="our_products-sec ref-carousel">
                <ReferenceDetailCarousel listProjImg={listProj?.tblWsProjectImages} />
                <Row className="refernce-non-carousel">
                    {listProj?.tblWsProjectImages?.map((item) => (
                        <Col xs={12} className="mb-3">
                            <div className='carousel__item right'>
                                <img width="100%" src={item?.waImgUrl} alt={item?.wsImgName} />
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>

            <div className="our_products-sec latestNews">
                <div className="sec-bottom">
                    <h2 className="sec-title">Related Projects</h2>
                    {/* <span><Link to={`/reference`} state={{ RefId: RefId }} onClick={() => { window.scroll(0, 0); }}> */}

                    <span><Link to={{ pathname: `/reference`, search: `?RefId=${RefId}` }} onClick={() => { window.scroll(0, 0); }}>
                        View More <img src={More} /></Link></span>


                </div>

                <div className="latest-news-sec">
                    <Row>
                        {productRelatedData?.slice(0, 3).map((item_) => (
                            <Col sm={4} md={4}>

                                <div className="news-section">
                                    {/* <Link to={`/reference-detail`} onClick={() => {window.scroll(0, 0);}} state={{ RefId: RefId, prodId: item_?.tblWsProjectImages[0]?.wsPrjId, RefName: RefName }}> */}

                                    <Link to={{ pathname: `/reference-detail`, search: `?RefId=${RefId}&prodId=${item_?.tblWsProjectImages[0]?.wsPrjId}&?RefName=${RefName}` }} onClick={() => { window.scroll(0, 0); }}>
                                        <div className='image-box'>
                                            <img src={item_?.tblWsProjectImages[0]?.waImgUrl} />
                                        </div>
                                    </Link>
                                    <h5>{item_?.wsPrjName}</h5>

                                    <p>{item_?.wsPrjLocation}</p>
                                </div>

                            </Col>
                        ))}
                    </Row>
                </div>

            </div>

        </div>
    )
};

export default ReferenceDetail;