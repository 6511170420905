import React, { useState, useEffect } from "react";
import "../../assets/css/mainpage.css";
import { Link } from 'react-router-dom';
import BannerCarousel from './BannerCarousel';
import ProductCarousel from "./ProductCarousel";
import SectorCarousel from "./SectorCarousel";
import Partnership from '../../assets/images/partnership.png';
import { Container, Row, Col } from 'react-bootstrap';
import PartnerLogo from '../../assets/images/partner-logo.png';
import Excellence1 from '../../assets/images/excellence1.png';
import Excellence2 from '../../assets/images/excellence2.png';
import Excellence3 from '../../assets/images/excellence3.png';
import Excellence4 from '../../assets/images/excellence4.png';
import Excellence5 from '../../assets/images/excellence5.png';
import Events1 from '../../assets/images/events1.png';
import Events2 from '../../assets/images/events2.png';
import Events3 from '../../assets/images/events3.png';
import Next from '../../assets/images/next-button.svg';
import NextBlack from '../../assets/images/next-button-black.svg';
import Upbutton from '../../assets/images/upbtn.png';
import CertificationCarousel from "./CertificationCarousel";
import Count from './Counter';
import More from '../../assets/images/more.svg';
import Seemore from '../../assets/images/seemore.svg';
import MoreWhite from '../../assets/images/morewhite.svg';
import Seemorewhite from '../../assets/images/seemore-white.svg';
import ContactData from "../Common/ContactData";
import Modal from 'react-bootstrap/Modal';
import { ArrowAnimation } from "./ArrowAnimation";
import { CurveArrow } from "./CurveArrowAnimation";
import $ from 'jquery';
import Events5 from '../../assets/images/news2.png';
import Events6 from '../../assets/images/news33.png';
import News1 from '../../assets/images/JMC - KSA.jpg';


const data = {
    "counts": [
        {
            "id": "001",
            "label": "Years of Expereince",
            "number": "35",
            "duration": "2"
        },
        {
            "id": "002",
            "label": "Local & International Customers",
            "number": "495",
            "duration": "2"
        },
        {
            "id": "003",
            "label": "Countries Products Exported To",
            "number": "7",
            "duration": "2"
        }
    ]
}

const Home = () => {

    const [cookieShow, setCookieShow] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);



    useEffect(() => {

        const isCookieAccepted = localStorage.getItem('cookiesAccepted');
        if (isCookieAccepted) {
            setCookiesAccepted(JSON.parse(isCookieAccepted));
        } else {
            setCookieShow(true);
        }
    }, []);

    const handleAcceptAllCookies = () => {
        localStorage.setItem('cookiesAccepted', true);
        setCookiesAccepted(true);
        setCookieShow(false);
    };

    const handleDismissAllCookies = () => {
        localStorage.setItem('cookiesAccepted', false);
        setCookiesAccepted(false);
        setCookieShow(false);
    };

    useEffect(() => {
        // array with texts to type in typewriter
        var dataText = ["in providing innovative Commercial and industrial HVAC products since 1976. Over the past four decades of our existence , we have created a product portfolio that competes worldwide on quality, price and application."];

        // type one text in the typwriter
        // keeps calling itself until the text is finished
        function typeWriter(text, i, fnCallback) {
            // chekc if text isn't finished yet
            if (i <= (text.length)) {
                var counterSec = document.querySelector(".text-animation");
                if (counterSec) {
                    document.querySelector(".text-animation").innerHTML = text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

                    // wait for a while and call this function again for next character
                    setTimeout(function () {
                        typeWriter(text, i + 1, fnCallback)
                    }, 100);
                }
            }
            // text finished, call callback if there is a callback function
            else if (typeof fnCallback == 'function') {
                // call callback after timeout
                setTimeout(fnCallback, 200);
            }
        }
        // start a typewriter animation for a text in the dataText array
        function StartTextAnimation(i) {
            if (typeof dataText[i] == 'undefined') {
                setTimeout(function () {
                    //  StartTextAnimation(0);
                }, 1000);
            }
            // check if dataText[i] exists
            if (i < dataText.length) {
                // text exists! start typewriter animation
                typeWriter(dataText[i], 0, function () {
                    // after callback (and whole text has been animated), start next text
                    StartTextAnimation(i + 1);
                });
            }

        }
        // start the text animation
        StartTextAnimation(0);

    })


    return (
        <>
            {/* <div className="carouselItem"> */}
            <BannerCarousel />
            {/* </div> */}
            <div className="our_products-sec our-products">
                <p className="sec-top-title">Our products</p>
                <div className="sec-bottom">
                    <h2 className="sec-title">Air control and distribution solutions</h2>
                    <span><Link to="/products">View More <img src={More} className="more-black" /></Link></span>
                </div>
                <div className="product-carouselItem">
                    <ProductCarousel />
                </div>
                <div className='bottom-more'>
                    <div className='button-more'><a href="/products">View more <img src={MoreWhite} /></a></div>
                </div>
            </div>
            <div className="counter-sec">
                <Row>
                    <Col md={8} sm={12}>
                        <p className="sec-top-title">Jersey Manufacturing</p>
                        <h5>Jersey Manufacturing Company is known for its leading, customer focused approach <span className="text-animation">in providing
                            innovative Commercial and industrial HVAC products since 1976. Over the past four decades of our existence , we have created a product
                            portfolio that competes worldwide on quality, price and application.</span>
                        </h5>
                    </Col>
                    <Col md={4} sm={12}>

                        <ArrowAnimation />

                    </Col>
                    <Col md={12}>
                        <p className="counter-field">
                            <div className="row">
                                {data.counts.map(count => <Count key={count.id} data={count} />)}
                            </div>
                        </p>
                        <div className="knomore-sec">
                            <Link to="/about">
                                <button className="knowmore-btn">Know More<img src={Seemorewhite} /></button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="our_products-sec sectors">
                <p className="sec-top-title">SECTORS</p>
                <h2 className="sec-title">Sectors we cater</h2>
                <div className="sector-carouselItem">
                    <SectorCarousel />
                </div>
            </div>

            <div className="counter-sec partnership">
                <Row>
                    <Col sm={6} md={6}>
                        <div className="image-box">
                            <img src={Partnership} />
                        </div>
                    </Col>
                    <Col sm={6} md={6}>
                        <div className="image-b">
                            {/* <img src={background} className="before-hover" />
                                <img src={background1} className="after-hover"/> */}

                        </div>
                        <div className="partnership-right">

                            <p className="sec-top-title">PARTNERSHIPS</p>
                            <h5>Jersey Manufacturing signs exclusive agreement with Air Balance, USA </h5>
                            <p className="partner-bottom-p">Jersey Manufacturing Company, Qatar has signed up with Air Balance, USA as an exclusive Licensee to manufacture
                                and distribute their complete range of UL classified Life Safety Dampers and a
                                wide range of high-Performance Louvers in the MENA region, South Asia and the Indian Sub-Continent.</p>
                            <img src={PartnerLogo} />
                        </div>
                    </Col>
                </Row>
                <span className="HomeSubsidiaries_bg_line__OTckB ratio ratio-1x1">
                    <span className="SvgLineBg_bg_line__Aq2b0 SvgLineBg_bg_line_flip__l2Jky">
                        <CurveArrow />
                    </span>
                </span>
            </div>



            <div className="our_products-sec excellence">
                <p className="sec-top-title">PROJECTS</p>
                <div className="sec-bottom">
                    <h2 className="sec-title">Engineered excellence</h2>
                    <span><a href="/reference">View More <img src={More} /></a></span>
                </div>
                <div className="sector-carouselItem">
                    <Row>
                        <Col md={4} className=" mt-1 mb-1">
                            <div className='carousel__item left'>
                                <Link to="/reference" onClick={() => { window.scroll(0, 0); }}>
                                    <figure>
                                        <img width="100%" src={Excellence1} />
                                        <div className="middle">
                                            <div className="text">
                                                <img src={Next} className="overlay-img" />
                                                <p>Explore</p>
                                            </div>
                                        </div>
                                    </figure>
                                </Link>
                                <div className='carousel__caption'>
                                    <p>Lusail Plaza</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} className="pl-1">
                            <Row>
                                <Col md={6} className="pl-1 pr-1 mt-1 mb-1">
                                    <div className='carousel__item right'>
                                        <Link to="/reference" onClick={() => { window.scroll(0, 0); }}>
                                            <figure>
                                                <img width="100%" src={Excellence3} />
                                                <div className="middle">
                                                    <div className="text">
                                                        <img src={Next} className="overlay-img" />
                                                        <p>Explore</p>
                                                    </div>
                                                </div>
                                            </figure>
                                        </Link>
                                        <div className='carousel__caption'>
                                            <p>Katara Towers</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="pl-1 pr-1 mt-1 mb-1">
                                    <div className='carousel__item right'>
                                        <Link to="/reference" onClick={() => { window.scroll(0, 0); }}>
                                            <figure>
                                                <img width="100%" src={Excellence2} />
                                                <div className="middle">
                                                    <div className="text">
                                                        <img src={Next} className="overlay-img" />
                                                        <p>Explore</p>
                                                    </div>
                                                </div>
                                            </figure>
                                        </Link>
                                        <div className='carousel__caption'>
                                            <p>Qatar Petroleum District</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="pl-1 pr-1 mt-1 mb-1">
                                    <div className='carousel__item right'>
                                        <Link to="/reference" onClick={() => { window.scroll(0, 0); }}>
                                            <figure>
                                                <img width="100%" src={Excellence5} />
                                                <div className="middle">
                                                    <div className="text">
                                                        <img src={Next} className="overlay-img" />
                                                        <p>Explore</p>
                                                    </div>
                                                </div>
                                            </figure>
                                        </Link>
                                        <div className='carousel__caption'>
                                            <p>Al Bayt Stadium, Al Khor, Qatar</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="pl-1 pr-1 mt-1 mb-1">
                                    <div className='carousel__item right'>
                                        <Link to="/reference" onClick={() => { window.scroll(0, 0); }}>
                                            <figure>
                                                <img width="100%" src={Excellence4} />
                                                <div className="middle">
                                                    <div className="text">
                                                        <img src={Next} className="overlay-img" />
                                                        <p>Explore</p>
                                                    </div>
                                                </div>
                                            </figure>
                                        </Link>
                                        <div className='carousel__caption'>
                                            <p>Hamad International Airport</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className='bottom-more'>
                        <div className='button-more'><a href="/reference">View more <img src={More} /></a></div>
                    </div>
                </div>
            </div>
            <div className="our_products-sec certification home">
                <Row>
                    <Col sm={12} md={6}>
                        <h2 className="sec-title">Certification</h2>
                        <div className="sec-bottom">
                            <p className="sec-bottom-title">We manufacture an extensive range of Life Safety Dampers, Air Terminals, Noise Control Products, Diffusers, Grilles, Registers and Architectural louvers. Our products have met the quality and operational expectations in all applications and also under critical operating conditions.
                                Our products are tested and certified by some of the globally recognized laboratories.</p>
                        </div>
                        <div className="see-more">
                            <button className="seemore-btn" onClick={() => { window.scroll(0, 0); }}><Link to="/certification">See More <img src={Seemore} /></Link></button>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="certificate-carouselItem">
                            <CertificationCarousel />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="our_products-sec latestNews">
                <p className="sec-top-title">Insights</p>
                <div className="sec-bottom">
                    <h2 className="sec-title">Latest news & Events</h2>
                    {/* <span onClick={() => {window.scroll(0, 0);}}><Link to="/news-events">View More <img src={More} /></Link></span> */}
                </div>

                <div className="latest-news-sec">
                    <Row>
                        <Col sm={4} md={4}>
                            <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=1",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="news-section">
                                    <div className="image-box">
                                        <img src={Events5} />
                                    </div>
                                    {/* <p className="news-time">NEWS • FEBRUARY 2023</p> */}
                                    <h5>Air Balance, USA signs agreement with Jersey Manufacturing Company</h5>
                                    {/* <p>Jersey Manufacturing Company, Qatar has signed up with Air Balance...</p> */}
                                </div>
                            </Link>
                        </Col>
                        <Col sm={4} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=2",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="news-section">
                                    <div className="image-box">
                                        <img src={Events6} />
                                    </div>
                                    {/* <p className="news-time">NEWS • FEBRUARY 2023</p> */}
                                    <h5>Pressure Independent VAVs manufactured by Jersey Manufacturing (KMC)
                                        are tested and certified by AHRI</h5>
                                    {/* <p>Jersey Manufacturing Company (formerly known as Khalid Manufacturing Co)...</p> */}
                                </div>
                            </Link>
                        </Col>
                        <Col sm={4} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=3",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="news-section">
                                    <div className="image-box">
                                        <img src={News1} />
                                    </div>
                                    <h5>Jersey Manufacturing Co (formerly known as KMC) is now having its presence across Saudi Arabia & other GCC countries</h5>
                                    {/* <p>Jersey Manufacturing Company (formerly known as Khalid Manufacturing Co)...</p> */}
                                </div>
                            </Link>
                        </Col>

                    </Row>
                    {/* <div className='bottom-more'>
                            <div className='button-more'><Link to="/news-events"  onClick={() => {window.scroll(0, 0);}}>View More <img src={More} /></Link></div>
                        </div> */}
                </div>
            </div>


            <ContactData />



            {/* cookie policy code */}
            <Modal
                fullscreen='true'
                size='xxl-down'
                show={cookieShow}
                onHide={e => setCookieShow(!cookieShow)}
                backdrop="static"
                animation={false}
                keyboard={false}
                id="CookiePolicyModal"
                className="cookie-popup-modal modal" tabindex="-1">
                <Modal.Header>
                    <h4 className="modal-title">We Value Your Privacy</h4>
                    <button type="button" className="btn-close" aria-label="Close" onClick={e => setCookieShow(!cookieShow)}>X</button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-7">
                            <p>We only use cookies for which you have given us your consent, with the exception of strictly necessary cookies required for website functionality.
                                For more information, please read our <a hrf>Cookie Policy </a></p>
                        </div>
                        <div className="col-md-5">
                            <div className='bottom-more'>
                                <div className='button-more'>


                                    <a href="" onClick={handleDismissAllCookies}>Dismiss All <img src={Seemore} /></a>
                                    <a href="" className="accept-btn" onClick={handleAcceptAllCookies}>Accept All <img src={Seemorewhite} /></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* cookie policy code */}


        </>
    );
};

export default Home;
