import React, { useState } from 'react';
// import { Carousel } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/mainpage.css';
import Product1 from '../../assets/images/life1.png';
import Product2 from '../../assets/images/life2.png';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };

function CareerCarousel() {
    return (
        <Carousel responsive={responsive} arrows={true} showDots={true}>
            <div className='carousel__item'>
                <img width="100%" src={Product1} />
            </div>
            <div className='carousel__item'>
                <img width="100%" src={Product2} />
            </div>
            <div className='carousel__item'>
                <img width="100%" src={Product1} />
            </div>
            <div className='carousel__item'>
                <img width="100%" src={Product2} />
            </div>
            <div className='carousel__item'>
                <img width="100%" src={Product1} />
            </div>
            <div className='carousel__item'>
                <img width="100%" src={Product2} />
            </div>
        </Carousel>
    );
}

export default CareerCarousel


