import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/mainpage.css';

function ProductSupplied({ listProj }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };

  return (
    <>
      {listProj && (
        <Carousel responsive={responsive} arrows={window.innerWidth <= 600 ? false : true}>
          {listProj?.map((item, index) => (
            <div className='carousel__item' >
              {/* <Link to="/product-list"  onClick={() => { window.scroll(0, 0); }} state={{ Id: item?.wspGrp?.wspGrpId, Name: item?.wspGrp?.wspGrpName }}> */}

              <Link to={{pathname: `/product-list`, search: `?Id=${item?.wspGrp?.wspGrpId}&Name=${item?.wspGrp?.wspGrpName}`}} onClick={() => { window.scroll(0, 0); }}>
              
                <div className='image-box'>
                  <img width="100%" src={item?.wspGrp?.wspGrpImgUrl} />
                </div>
                <div className='carousel__caption'>
                  <p>{item?.wspGrp?.wspGrpName}</p>
                </div>
              </Link>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
}

export default ProductSupplied


