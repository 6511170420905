import {
  isRequired,
  isValidEmail,
  isValidPhone,
  isTextOnly
} from '../../utils/Validate';
import  { isValidPhoneNumber } from 'react-phone-number-input';

export const validation = (values) => {
  const errors = {};

  if (!isRequired(values.wspConName)) {
    errors.wspConName = 'Name is required';
  } else if (!isTextOnly(values.wspConName)) {
    errors.wspConName = 'Name must only contain text';
  }

  if (!isRequired(values.wspConComName)) {
    errors.wspConComName = 'Company name is required';
  }

  
  if (!isRequired(values.privacy)) {
    errors.privacy = 'Please agree to the privacy policy and consent to be contacted.';
  }

  if (!isRequired(values.wspConEmail)) {
    errors.wspConEmail = 'Email is required';
  } else if (!isValidEmail(values.wspConEmail)) {
    errors.wspConEmail = 'Email is not valid';
  }

  if (isRequired(values.wspConPhone)) {
    if ( values?.wspConPhone?.length > 4  && !isValidPhoneNumber(values.wspConPhone))
    {
      errors.wspConPhone = 'Mobile number is not valid';
    }
  }
   
  return errors;
};

