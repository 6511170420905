export const CurveArrow = () => {
  return (
     <svg xmlns="http://www.w3.org/2000/svg"  width="515.852" height="509.855" viewBox="0 0 515.852 509.855">
  <defs>
    <clipPath id="clip-path-11">
      <rect id="Rectangle_25744" data-name="Rectangle 25744" width="514.852" height="508.855" fill="none" stroke="#617088" stroke-width="1"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_25743" data-name="Rectangle 25743" width="19.89" height="20.016" fill="none" stroke="#617088" stroke-width="1"/>
    </clipPath>
  </defs>
  <g id="Group_136691" className="circle-arrow" data-name="Group 136691" transform="translate(0.5 0.5)">
    <g id="Group_67069" data-name="Group 67069">
      <g id="Group_67068" data-name="Group 67068" clip-path="url(#clip-path-11)">
        <g id="Group_67067" data-name="Group 67067" transform="translate(255.951 240.519)" opacity="0">
          <g id="Group_67066" data-name="Group 67066">
            <g id="Group_67065" data-name="Group 67065" clip-path="url(#clip-path-2)">
              <path id="Path_111025" data-name="Path 111025" d="M0,9.616a9.556,9.556,0,1,1,9.557,9.617A9.587,9.587,0,0,1,0,9.616Z" transform="translate(0.389 0.391)" fill="none" stroke="#617088" stroke-miterlimit="10" stroke-width="1"/>
            </g>
          </g>
        </g>
        <path id="Path_111026" data-name="Path 111026" d="M96.92,0C6.564,49.848-26.528,163.969,23.008,254.9s162.94,124.227,253.3,74.378" transform="translate(62.553 93.479)" fill="none" stroke="#00a6ef" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111027" data-name="Path 111027" d="M132.362,0C10.968,64.7-35.319,216.178,28.976,338.341S243.8,507.076,365.188,442.379" transform="translate(0.389 36.927)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111028" data-name="Path 111028" d="M7.448,14.974,11.193,3.638,0,0" transform="translate(354.382 475.666)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111029" data-name="Path 111029" d="M7.444,14.974,11.193,3.637,0,0" transform="translate(328.342 418.657)" fill="none" stroke="#00a6ef" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111030" data-name="Path 111030" d="M26.5,0a63.391,63.391,0,0,0-14.96,88.011A62.489,62.489,0,0,0,99,103.065" transform="translate(186.417 206.584)" fill="none" stroke="#f18a01" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111031" data-name="Path 111031" d="M8.893,14.152,11.5,2.5,0,0" transform="translate(274.49 306.711)" fill="none" stroke="#f18a01" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111032" data-name="Path 111032" d="M61.554,0C2.235,35-17.659,111.76,17.119,171.453S128.178,251.167,187.5,216.169" transform="translate(124.641 150.03)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111033" data-name="Path 111033" d="M7.444,14.974,11.193,3.637,0,0" transform="translate(300.941 362.564)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111034" data-name="Path 111034" d="M0,8A82.893,82.893,0,0,1,110.614,48.013c19.564,41.789,1.762,91.625-39.765,111.313l-.3.141" transform="translate(230.528 166.889)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111035" data-name="Path 111035" d="M10.156,12.971,0,10.1,2.916,0" transform="translate(229.857 165.162)" fill="none" stroke="#617088" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111036" data-name="Path 111036" d="M0,16.019C67.542-19.737,151.1,6.375,186.631,74.343s9.583,152.053-57.958,187.809" transform="translate(201.467 111.534)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111037" data-name="Path 111037" d="M0,24.239c93.519-51.911,211.145-17.7,262.727,76.411S280.312,313.128,186.8,365.037" transform="translate(172.406 55.984)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111038" data-name="Path 111038" d="M0,32.5C119.5-35.561,271.192,6.749,338.825,127s25.587,272.9-93.908,340.963" transform="translate(143.343 0.391)" fill="none" stroke="#b7c3d8" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111039" data-name="Path 111039" d="M0,.648A28.9,28.9,0,0,1,34.3,23.009,29.085,29.085,0,0,1,12.425,57.454" transform="translate(259.59 221.569)" fill="none" stroke="#617088" stroke-miterlimit="10" stroke-width="1" opacity="0"/>
        <path id="Path_111040" data-name="Path 111040" d="M8.274,14.777,0,8.191,6.557,0" transform="translate(258.8 214.106)" fill="none" stroke="#617088" stroke-miterlimit="10" stroke-width="1" opacity="0"/>
        <path id="Path_111041" data-name="Path 111041" d="M10.156,12.971,0,10.1,2.916,0" transform="translate(200.66 117.894)" fill="none" stroke="#617088" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_111042" data-name="Path 111042" d="M10.157,12.969,0,10.1,2.916,0" transform="translate(171.939 70.435)" fill="none" stroke="#617088" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
    
  </g>
</svg>
  );
};