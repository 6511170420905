import { useState, useEffect } from 'react';
import { validation } from './validation';
import { CreateEntry } from '../../providers/dataProvider';

const useForm = () => {
 
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('in');
 

  const [formValues, setFormValues] = useState({
      wspConName: '',
      wspConComName: '',
      wspConPhone: '',
      wspConEmail: '',
      wspConMsg: '',
      wspConStatus: '',
      privacy: false,
    });
  
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [createMsg, setCreateMsg] = useState(null);
    
    const handleFormChange = (e) => {
   
      const { name, value, type, checked } = e.target ;
      
      if (name) {

        if (type === 'checkbox') {
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: checked,
          }));
        } else {
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
          }));
        }
      
        const errors = validation({
          ...formValues,
          [name]: value,
        });
      
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [name]: errors[name],
        }));
      }
    };

    const handlePhoneChange = (value) => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        wspConPhone: value,
      }));

      const errors = validation({
        ...formValues,
        wspConPhone: value,
      });
    
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        wspConPhone: errors.wspConPhone,
      }));

    };
    const handleInputBlur = (e) => {
      if (e.target) {
        const { name, value } = e.target;
        const errors = validation({
          ...formValues,
          [name]: value,
        });
        
        if (name === '') {
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            wspConPhone: errors.wspConPhone,
          }));
        } else if (name === 'privacy') {
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            privacy: errors.privacy,
          }));
        } else {
         
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [name]: errors[name],
          }));
        }
      }
    };


   
    
   

    const handleCountryChange = (value) => {
      
      setSelectedCountry(value);
    };

  
    
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const errors = validation(formValues);
      setFormErrors(errors);
      setIsSubmit(true);
    };

    const [errorCount, setErrorCount] = useState();

  useEffect(()=>{
    
    const errors = validation(formValues);
    setErrorCount(Object.keys(errors).length);
  },[formValues])

    useEffect(() => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {

        const trimmedFormValues = {
          ...formValues,
          wspConName: formValues.wspConName.trim(),
        }; 


        CreateEntry(
          'Contacts',
          trimmedFormValues,
          setCreateMsg,
          'Success! Your message has been sent.',
          'Failed to create Contacts'
        );

       
        setFormValues({
          wspConName: '',
          wspConComName: '',
          wspConPhone: '',
          wspConEmail: '',
          wspConMsg: '',
          wspConStatus: '',
        });
        setFormErrors({});
      }
      setIsSubmit(false);
    }, [formErrors, formValues, isSubmit]);

    useEffect(()=>{

      if (createMsg?.message === 'Failure') {
        setErrorMsg(createMsg?.message);
      
      } else {
        setSuccessMsg(createMsg?.message);
        setTimeout(() => {
          setSuccessMsg('');
        }, 5000);
      
      }

    },[createMsg])

  

  return {
    handleFormChange,
    handleSubmit,
    formValues,
    formErrors,
    createMsg,
    successMsg,
    errorMsg,
    handlePhoneChange,
    handleCountryChange,
    selectedCountry,
    handleInputBlur,
    setSuccessMsg,
    isSubmit,
    errorCount
   
  };
};

export default useForm;
