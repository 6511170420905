import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import ReactImageMagnify from 'react-image-magnify';
import '../../assets/css/mainpage.css';

function ProductDetailCarousel({ productDetail }) {
  if (!productDetail || !productDetail.tblWsProdMdlImages) {
    return null;
  }

  const images = productDetail.tblWsProdMdlImages.map((imgurl_, index) => ({
    original: imgurl_?.wsmImgUrl,
    thumbnail: imgurl_?.wsmImgUrl,
    originalAlt: `Image ${index + 1}`,
    thumbnailAlt: `Thumbnail ${index + 1}`,
  }));

  return (
    <div className="product-detail-carousel">
      <div className="carousel-container">
        <ImageGallery
          items={images}
          showThumbnails={true}
          showFullscreenButton={false}
          showPlayButton={false}
          infinite={false}
          // renderItem={(item) => (
          //   <div className="zoom-container">
          //     <ReactImageMagnify
          //       {...{
          //         smallImage: {
          //           alt: item.originalAlt,
          //           isFluidWidth: true,
          //           src: item.original,
          //           sizes: '(min-width: 800px) 50vw, 100vw',
          //         },
          //         largeImage: {
          //           src: item.original,
          //           width: 1200,
          //           height: 1800,
          //           alt:'hover-image'
          //         },
          //         enlargedImagePosition: 'over',
          //       }}
          //       className="zoom-image"
          //     />
          //   </div>
          // )}
        />
      </div>
    </div>
  );
}

export default ProductDetailCarousel;
