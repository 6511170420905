import React, { createContext, useState, useEffect, useContext } from 'react';
import urls from "../../constants";

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
    const [token, setToken] = useState('');

    const fetchToken = async () => {
        try {
            const params = {
                "WsUsrLogin": "admin",
                "WsUsrPass": "KqacYa+a4MBYSSuehzLWHA=="
            };

            const apiUrl = getApiUrl("login");

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params)
            };

            console.log("apiUrl", apiUrl, requestOptions)
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const currentDateTime = new Date().getTime();
            localStorage.setItem('token', data.token);
            localStorage.setItem('tokenTimestamp', currentDateTime.toString());
            setToken(data.token);
        } catch (error) {
            console.error("Error fetching token:", error);
        }
    };

    useEffect(() => {
        fetchToken();
        const intervalId = setInterval(fetchToken, 5000); // Check token expiry 1 day
        return () => clearInterval(intervalId);
    }, []);

    const handleTokenExpiry = () => {
        const tokenTimestamp = parseInt(localStorage.getItem('tokenTimestamp'));
        const currentTime = new Date().getTime();
        const timeDifferenceInMs = currentTime - tokenTimestamp;
        const sixtyDaysInMs = 24 * 60 * 60 * 1000; // 1 days in milliseconds

        if (timeDifferenceInMs >= sixtyDaysInMs) {
            fetchToken();
            const currentDateTime = new Date().getTime();
            localStorage.setItem('tokenTimestamp', currentDateTime.toString());
        } else {
            console.log('Token still valid. No need to trigger API call.');
        }
    };

    useEffect(() => {
        handleTokenExpiry();
        const intervalId = setInterval(handleTokenExpiry, 24 * 60 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <TokenContext.Provider value={token}>
            {children}
        </TokenContext.Provider>
    );
};

export const UseToken = () => {
    return useContext(TokenContext);
};

const getApiUrl = (resource) => {
    let apiUrl = "";
    if (resource === "login") {
        apiUrl = urls.LOGIN_URL;
    }
    return apiUrl;
};
