import React, {useState,useEffect} from 'react';
import { Link} from 'react-router-dom';
import { Row, Col, Modal, Container } from 'react-bootstrap';
import ProductCarousel from "../Mainpage/ProductCarousel";
import More from '../../assets/images/more.svg';
import MoreWhite from '../../assets/images/morewhite.svg';
import { httpHeaders } from '../../providers/dataProvider';
import urls from '../../constants';
import { useQuery } from 'react-query';


function RelatedProduct({GroupId}) {


    const [productRelatedData,setProductRelatedData] =useState([]);

    const requestOptions = httpHeaders();

    const { isLoading, error, data: subproduct } = useQuery(
      {
        queryKey: ['subproduct'],
        queryFn: () =>
          fetch(urls.PRODUCT_SUBPROD_URL, requestOptions).then((res) => res.json()),
        enabled: true,
      }
    );
   

    useEffect(() => {
        if (subproduct) {
          const filteredSubproducts = subproduct.filter(item => item.wspGrpId !== parseInt(GroupId));
          setProductRelatedData(filteredSubproducts);
        }
      }, [subproduct, GroupId]);

   

    return (
        <div className="our_products-sec related-product">
            <div className="sec-bottom">
                <h2 className="sec-title">Related Products</h2>
                <span><Link to="/products" onClick={() => {window.scroll(0, 0);}}>View More <img src={More} className="more-black" /></Link></span>
            </div>
            <div className="product-carouselItem">
                <ProductCarousel />
            </div>
            <div className="related-carouselItem">
                <Col md={12}>
                    <Row>
                        {productRelatedData?.slice(0, 4).map((prod_, index) => (
                            <Col md={3} className="pl-1 pr-1">
                                {/* <Link to="/product-list" onClick={() => {window.scroll(0, 0);}} state={{ Id: prod_?.wspGrpId, Name: prod_?.wspGrpName}}> */}

                                <Link to={{pathname: `/product-list`, search: `?Id=${prod_?.wspGrpId}&Name=${prod_?.wspGrpName}`}} onClick={() => {window.scroll(0, 0);}}>
                                    <div className='carousel__item right'>
                                        <div className='image-box'>
                                            <img width="100%" src={prod_?.wspGrpImgUrl} alt={prod_.wspGrpName}/>
                                        </div>
                                        <div className='carousel__caption'>
                                            <p>{prod_?.wspGrpName}</p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>

                        ))}


                    </Row>
                    
                </Col>
            </div>
            <div className='bottom-more'>
                <div className='button-more' onClick={() => {window.scroll(0, 0);}}><Link to="/products">View more <img src={MoreWhite} /></Link></div>
            </div>
        </div>
    )
}

export default RelatedProduct