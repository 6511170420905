import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const BreadCrumb = (props) => {
  const location = useLocation();
  
  const { prodName, moduleName, prodListName } = props;

  return (
    <div className="bread-crumbs">
      <nav>
        <Link
          to="/"
          className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
        >
          Home
        </Link>
        <span className="breadcrumb-arrow">/</span>
        <Link
          to={`/${prodName}`}
          className={location.pathname.startsWith(`/${prodName}`) ? "breadcrumb-active" : "breadcrumb-not-active"}
        >
          {moduleName}
        </Link>

        {moduleName === "Category List" && (
          <>
            <span className="breadcrumb-arrow">/</span>
           
              <a className='breadcrumb-active'>{prodListName} </a>
           
          </>
        )}
      </nav>
    </div>
  );
};

export default BreadCrumb;
