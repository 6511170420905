import React,{useState}from "react";
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import BreadCrumb from "../Common/BreadCrumb";
import FetchData,{httpHeaders} from "../../providers/dataProvider";
import Spinner from "../Common/Spinner";
import urls from "../../constants";
import {
    useQuery
  } from 'react-query'


const Products = () => {
   

   // const { data: subproduct, loading, error } = FetchData("subproduct");
   const requestOptions = httpHeaders()
    const { loading, error, data } = useQuery({
        queryKey: ['subproduct'],
        queryFn: () =>
          fetch(urls.PRODUCT_SUBPROD_URL,requestOptions).then(
            (res) => res.json(),
          )
      })

    // if (loading) {
    //     return (<Spinner/>);
    // }

    if (error) {
        return <div>Error: {error.message}</div>;
    }


    return (
        <div className="products-listing">
            <div className="our_products-sec productlist">
                <p className="sec-top-title">OUR PRODUCTS</p>
                <div className="sec-bottom">
                    <h2 className="sec-title">Air Control & Distribution Solutions For Commercial & Industrial Use</h2>
                </div>
            </div>
            {/* BreadCrumb */}
            <BreadCrumb prodName="products" moduleName="Products Categories" />

            <div className="our_products-sec products">
                <div className="col-md-12">
                    <Row>
                        {data?.map((item, index) => (
                            <Col md={3} sm={3} xs={6} className="pl-1 pr-1">
                                <Link to={{pathname: `/product-list`, search: `?Id=${item?.wspGrpId}&Name=${item?.wspGrpName}`}}  onClick={() => {window.scroll(0, 0);}}>
                                    <div className='carousel__item right'>
                                        <div className='image-box'>
                                            <img width="100%" src={item?.wspGrpImgUrl} />
                                        </div>
                                        <div className='carousel__caption'>
                                            <p>{item?.wspGrpName}</p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

        </div>

    )
};

export default Products;