import { useState, useReducer, useEffect } from "react";
import urls from "../constants";
import { exists } from "../utils/Validate";

const ACTION = {
  API_REQUEST: "api-request",
  FETCH_DATA: "fetch-data",
  ERROR: "error"
};

const initialState = {
  data: [],
  loading: true,
  error: null,
  status: null
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTION.API_REQUEST:
      return { ...state, data: [], loading: true };
    case ACTION.FETCH_DATA:
      return { ...state, data: payload, loading: false };
    case ACTION.POST_DATA:
      return { ...state, status: "success" };
    case ACTION.ERROR:
      return { ...state, data: [], error: payload };
    default:
      return state;
  }
}



function FetchData(resource, params) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: ACTION.API_REQUEST });
        const apiUrl = getApiUrl(resource, params);
        const url = `${apiUrl}`;
        const requestOptions = httpHeaders();

        const response = await fetch(url, requestOptions);
        const data = await response.json();

        dispatch({ type: ACTION.FETCH_DATA, payload: data });
      } catch (error) {
        dispatch({ type: ACTION.ERROR, payload: error });
      }
    };

    fetchData();
  }, [resource, params]);

  return state;
}


export const GetOne = (resource, params, setProductData) => {
  let apiUrl = getApiUrl(resource, params);
  let url = `${apiUrl}${params?.id}`;
  const requestOptions = httpHeaders();
  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      setProductData({ data: data, message: "success" })
    })
    .catch((e) => {
      setProductData({ data: e?.errors, message: "Error" })
    });

}



export const CreateEntry = (resource, params, setCreateMsg, successMessage, failureMessage) => {

  const token = localStorage.getItem('token');
  const apiUrl = getApiUrl(resource, params);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(params)
  };


  fetch(apiUrl, requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data?.status === 400 || data?.statusCode === 400) {
        setCreateMsg({
          successData: data,
          message: "Faliure"
        })
      }
      else {
        setCreateMsg({
          successData: data,
          message: successMessage
        })
      }
    })
    .catch((error) => {
      setCreateMsg({
        successData: { error },
        message: failureMessage
      })
    })
}


const getApiUrl = (resource, params) => {
  let apiUrl = "";
  if (resource === "subproduct") {
    apiUrl = urls.PRODUCT_SUBPROD_URL;
  } else if (resource === "filterList") {
    apiUrl = urls.PRODUCT_LIST_URL;
  }
  else if (resource === "prodDetails") {
    apiUrl = urls.PRODUCT_DETAILS_URL;
  }
  else if (resource === "ProjectReferences") {
    apiUrl = urls.PRODUCT_REF_URL;
  }
  else if (resource === "Contacts") {
    apiUrl = urls.PRODUCT_CONTACT_URL;
  }
  else if (resource === "Enquiries") {
    apiUrl = urls.PRODUCT_ENQUIRY_URL;
  }

  else if (resource === "downloadcenter") {
    apiUrl = urls.DOWNLOAD_CENTER_URL;
  }

  else if (resource === "downloadcenterfilter") {
    apiUrl = urls.DOWNLOAD_CENTER_FILTER_URL;
  }

  else if (resource === "Countries") {
    apiUrl = urls.COUNTRY_LIST_URL;
  }

  else if (resource === "careers") {
    apiUrl = urls.CAREER_LIST_URL
  }

  else if (resource === "createcareer") {
    apiUrl = urls.CREATE_CAREER_URL
  }
  else if (resource === "ReferenceDetail") {
    apiUrl = urls.PRODUCT_REF_DETAIL_URL

  }
  else if (resource === "sendotp") {
    apiUrl = urls.SEND_OTP_URL

  }
  else if (resource === "DownloadContacts") {
    apiUrl = urls.DOWNLOAD_CONTACTS_URL
  }

  return apiUrl;
};




export const httpHeaders = () => {

  const token = localStorage.getItem('token');

  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
  };

  if (exists(token)) {
    headers['Authorization'] = `Bearer ` + token;
  }

  return {
    method: 'GET',
    headers: headers
  };
};

export default FetchData;
