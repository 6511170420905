import React from "react";

const TermsConditions = () =>{
    return (
        <>
        <div className="policy-sec">
            <div className="our_products-sec policy">
                <div className="sec-bottom">
                    <h2 className="sec-title">General Terms and Conditions of Sale</h2>
                </div>
                <p>These Terms & Conditions of sale govern to all agreements concluded between Jersey Manufacturing for Airconditioning Products w.l.l. (hereafter referred as “Seller”) and their Buyers or customers (hereafter referred as “Buyer”) on the manufacture and supply of goods. The `Buyer hereby agrees to these conditions by placing order with Jersey Mfg. In case of different or supplementary agreements or conflicting conditions, the written consent of Jersey Mfg is required. All orders for deliveries as well as any special conditions of warranties agreed to by Jersey Mfg require a written confirmation by Jersey Mfg. This requirement of form may only be waived by a written agreement.</p>
            
                <div className="policy-sub">
                    <h4 className="policy-title">Terms & Conditions</h4>
                    <p>No change to these Terms is binding upon both parties unless it is in writing and is signed by both parties. Neither Seller’s subsequent lack of objection to any terms, nor the delivery of the products or services, shall constitute an agreement by Seller to any terms. Our Quotations remain valid for a period of four (4) weeks from the date of Quotation and shall expire and be void after this period, unless otherwise specified in the Quotation. The Quotation may be modified or withdrawn by Seller at any time before receipt of Buyer ’s conforming acceptance. Neither advice and recommendations nor ancillary verbal agreements form a constituent part of any quotation. These may only be considered binding and effective if confirmed by Seller in writing. Details on delivery times are only approximate. The delivery date shall be the day of shipping ex-works. Prices set forth in Quotations are subject to change in accordance with section “Price”. All the details provided by the Seller about the item sold in the catalogue or during the contractual negotiations are characteristic descriptions, not warranties in the legal sense. Unless expressly stated or referred to otherwise in the sales contract, the Seller has not issued any warranties.</p>
                    
                </div>
                <div className="policy-sub">
                    
                    <h4 className="policy-title">Placement and Acceptance of purchase orders</h4>
                    <p>No Buyer purchase order shall be binding on Seller until accepted in writing by Seller, and Seller shall have no liability to Buyer with respect to purchase orders that are not accepted. A purchase contract is deemed concluded with our written order confirmation or with execution of a purchase order in reference to the applicability of these General Terms and Conditions, which are provided on our website (www.jerseymfg.com) and which will be supplied by mail on request. By placing a purchase order, the Buyer states his binding intention to purchase the ordered item. Purchase orders may be placed with us in writing or by electronic means. A confirmation of receipt does not constitute a confirmation of order or binding acceptance of a purchase order. Shipment against a purchase order shall be deemed to constitute Seller’s acceptance thereof, subject to these General Terms and Conditions. All purchase orders shall be subject to these General Terms and Conditions, whether or not the purchase orders so state. In the event Seller has accepted Buyer’s purchase order in writing, Buyer may not change its purchase order unless Seller accepts in writing any change(s) requested by Buyer (whereby Seller reserves the right to revise the price and delivery schedule).</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Delivery and Shipping</h4>
                    <p>The delivery period begins on dispatch of our Order Confirmation, but not before the 
                    provision of any documentation, approvals or releases to be furnished by the Buyer, 
                    nor before receipt of any agreed down payment. Seller will deliver the Products within 
                    a reasonable time after receiving Buyer’s purchase order, subject to their availability. 
                    The delivery date provided by Seller for the Products is only an estimate and is based 
                    upon prompt receipt of all necessary information from Buyer. Seller will not be liable 
                    for any delays, loss, or damage in transit, and failure to deliver within the time 
                    estimated will not be a material breach of contract on Seller’s part. Unless otherwise 
                    agreed in writing by the parties, Seller will deliver the Products, F.O.B., Seller’s Factory, 
                    using Seller’s standard methods for packaging and shipping same. Buyer will take delivery of 
                    the Products within five (5) days of Seller’s notice. If Buyer fails to take delivery of 
                    the Products within this five (5) day period, which delay must be consented to by Seller,
                    Seller may store the Products at the Buyer ’s risk and expense. Seller may, in its sole 
                    discretion, without liability or penalty, make partial shipments of Products to Buyer. 
                    Buyer is responsible for obtaining any import licenses and other consents required for 
                    a Product shipment at its own expense and will provide the licenses and consents to the 
                    Seller before shipment. The quantity of any instalment of the Products, as recorded by Seller 
                    on the Delivery Note from Seller’s place of business, is conclusive evidence of the quantity 
                    received by Buyer upon delivery, unless Buyer provides conclusive evidence to the contrary. 
                    Seller will not be liable for any non-delivery of the Products to the Delivery Location, 
                    unless Buyer gives written notice to Seller of the non-delivery within five (5) days 
                    following the date that Buyer would have received the Products. Seller’s liability 
                    for any non-delivery of the Products will be limited to replacing the Products within a 
                    reasonable time or adjusting the invoice for the Products to reflect the actual quantity 
                    delivered. If the delivery is delayed by force majeure, industrial disputes or other such
                    circumstances beyond the Seller’s control, the delivery time shall be extended commensurate 
                    to the circumstances. Compliance with the delivery deadline shall be subject to the proviso 
                    of correct and punctual deliveries to the Seller himself. In the event of any unforeseen
                    impediments beyond Seller’s control, such as operational breakdowns or delays in the 
                    supplies of essential materials, provided such impediments materially influence the 
                    delivery of the purchased goods. This shall also apply in the event that Seller’s vendors
                    experience similar circumstances. Seller will not be deemed responsible for the above-mentioned circumstances should these occur during an already existing delivery delay. Seller undertakes to inform the Buyer as soon as
                    possible of the start and end of such impediments in important cases of any imminent delays. 
                    In no event shall Jersey Manufacturing be liable to Buyer or any other person or entity for any 
                    damages arising from early or late deliveries.
                    </p>
                    <p>The below documents shall be received by the Buyer.</p>
                    <ul>
                        <li>Supplier’s invoice showing contract number, description of goods, quantity, unit price and total amount.</li>
                        <li>Copies of packing list identifying the contents of each package.</li>
                        <li>Manufacturer’s Quality Inspection certificate</li>
                    </ul>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Price</h4>
                    <p>Unless otherwise agreed, the prices shall apply ex works, including loading in the works, but excluding packaging and unloading. Prices for individual items of a quotation are only valid if a full order is placed for thequotation. The prices confirmed in our order confirmation shall apply only if the agreed upon order quantity is accepted. Prices quoted are exclusive of any taxes, Value Added Tax, excise tax, packing, freight, dutiesor customs fees or charges or the cost of any certificates or other charges related to delays at any port due to customs or otherwise and other costs, unless expressly stated in writing. In addition to theprice stated herein, the amount of any such present or future taxes or duties or increases therein applicable to the goods covered by this Contract or to the manufacture, production,transportation or sale thereof, shall be added to the price and paid by the Buyer. If after the contract has been concluded there is a change in the taxes or other outside costsincluding but not limited to change in insurance premium, destination charges, which may be established andincluded in the agreed price or if new ones emerge, the Seller shall be entitled to alter the price correspondingly and shall be to Buyer’s account and at Buyer’s sole cost and expense. On request, the Seller shall provide evidence of this cost increase.</p>

                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Payment Default</h4>
                    <p>If Buyer defaults in any payment when due, or in the event that any voluntary or involuntary bankruptcy or insolvency proceedings involving Buyer are initiated by or against Buyer, then the whole Contract price shall immediately become due and payable upon demand, or Jersey Mfg, at its option and without prejudice to its other lawful remedies, may defer delivery or cancel the Contract. Where delivery is deferred, and Seller does not demand immediate payment of the full Contract price, pro-rata payments shall become due as shipments are made.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Cancellation</h4>
                    <p>No order which has been accepted by Seller may be cancelled by the Buyer except with the express written consent of Seller, and the Buyer shall indemnify Seller in full against all loss (including loss of profit) costs (including the cost of all labour and materials used), damages, charges and expenses incurred by Selleras a result of any cancellation. In cases where special or materials not-in stock with Seller has been ordered, Buyer will have to pay 100% of the order value of that item. If the Buyer rejects delivery of any ordered item or finds that they are responsible for ordering the incorrect item, the Buyer will pay 100% of the order value of those items. In the event of Government intervention, trade restrictions, and/or quotas, which may delay or prevent delivery of the Goods or any part thereof, Seller at their option, may cancel the unshipped balance of the goods without liability. In the event any of the goods shall become subject to any Governmental fees, taxes, or duties not presently in effect or to any increase in any existing fee or duty, Seller shall have the right to cancel the unshipped balance of the goods without liability.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Title and Risk of Loss</h4>
                    <p>Ownership of and risk in and to the Goods shall pass to Buyer upon the physical delivery, at Seller’s premises as set out in the Purchase Order, accompanied with delivery note which shall be duly signed and returned by the Buyer. If Buyer fails to take delivery of the Goods or any part thereof, the Goods not delivered shall be held at Buyer’s sole risk and at their sole cost and expense in all respects.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Limitation of Warranty</h4>
                    <p>Unless otherwise expressly provided, and in lieu of all other warranties, express or implied, Seller warrants only that the Goods covered hereby will conform to the description, that Seller will convey good title thereto, free from any Lien or encumbrance, and said goods will be free from Defects in Material and workmanship under normal use and service and provided said goods have not been repaired, altered or neglected and have been held in normal indoor storage prior to installation. Seller warrants that the Goods shall conform to the description stated in the Invoice. The foregoing warranty is Seller’s sole warranty which is four hundred (400) days from the date of delivery. All other warranties, express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose, are hereby disclaimed. Jersey Mfg. will not be liable for any consequential or special damages for any breach of warranty or any damages for negligence or for the cost of removal, transportation or re-installation of defective or replacement goods. Seller’s liability for breach of warranty hereunder is limited solely to the replacement of the defective goods, which shall be returned to Seller’s premises, transportation charges prepaid by Buyer; and the failure to give notice of a warranty claim within four hundred (400) days from date of delivery shall constitute a waiver by Buyer of all claims in respect to such goods. The foregoing shall constitute the sole remedy of Buyer and the sole liability of Seller under this warranty.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Limitation of Liability</h4>
                    <p>Seller’s liability shall be limited to the cost of replacing duly rejected goods to the point of delivery as specified in the purchase order within a reasonable period following proper and timely rejection by Buyer. Under no circumstances shall Seller be liable for consequential contingent, incidental, special or punitive damages, of any nature whatsoever, arising from nonconformity of goods or delay in shipment, or any other breach by Seller.In no event shall Seller be Liable for any incidental or consequential damages, business interruption, lost profits or losses resulting from the installation, use, misuse, or inability to use the product. This exclusion applies regardless of whether such damages are sought based on breach of warranty, breach of contract, negligence, or any other legal theory even if the party was informed of the possibility of such damages, should Seller nevertheless be found liable for any damages, they shall be limited to the Purchase Price for the particular piece or item of equipment.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Indemnification</h4>
                    <p>Buyer will defend, indemnify, and hold harmless Seller and its parent company, their respective subsidiaries, affiliates, successors, and assigns and their respective directors, officers, shareholders, and employees from and against any loss, injury, death, damage, liability, claim, deficiency, action, judgment, interest, award, penalty, fine, cost, fees (including import and export customs fees), or expense (including attorney and professional fees and costs, and the cost of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers) (“Claims”) arising out ofor occurring in connection with the negligence or wilful misconduct of Buyer or its employees or agents, including but not limited to:</p>
                    <ul>
                        <li>Any misuse or modification of the Products by Buyer or its employees or agents,</li>
                        <li>Any act (or failure to act) by Buyer or its employees or agents in contravention of any</li>
                        <p>safety procedures orinstructions that Seller provides to Buyer or its employees or agents, or the failure to store, install, operate, or maintain the Products in accordance with theInstructions.</p>
                    </ul>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Changes in Product design</h4>
                    <p>In the interest of continuous improvement, Seller reserves the right to alter, modify, or redesign its products, without any obligation to replace previous shipments to Buyer.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">No License</h4>
                    <p>The sale of the Products will not confer upon Buyer any license, express or implied, under any patents, trademarks, trade names, or other proprietary rights owned or controlled by Seller, its subsidiaries, affiliates, or suppliers; it being specifically understood and agreed that all the rights are reserved to Seller, its subsidiaries, affiliates, or suppliers. Without limiting the foregoing, Buyer will not, without Seller’s prior written consent, use any trademark or trade name of Seller in connection with any the Products, other than with respect to the resale of the Products pre-marked or packaged by or on behalf of Seller. If software is provided by Seller under the Agreement, Buyer agrees that the software may only be used in accordance with the terms and conditions of the software license agreement that accompanies the software. Buyer agrees not to directly or indirectly decompile, disassemble, reverse engineer or otherwise derive the source code for the software</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Termination</h4>
                    <p>In the event of termination by Buyer they shall pay Seller for the goods supplied prior to termination and all reasonable costs associated with the cancellation of any orders placed by Seller prior to termination. Notwithstanding anything to the contrary contained in the Purchase order or herein or elsewhere, in the event of the Buyer’s failure or delay to meet its obligations (including but not limited to the payment of the agreed fees, charges and costs as stipulated herein) within a maximum period of thirty (30) days from the date of the receipt of invoice by the Buyer; Seller shall automatically have the right to suspend performance, stop deliveries, postpone the fulfilment of any obligations affected by such failure or delay for a period as shall be reasonable and/or take such other measures as set out in herein. Buyer agrees that such suspension, cancellation, or termination of the supply of goods by Seller will not constitute a breach of Seller’s obligations and the Buyer would waive all claims against Seller in respect of such suspension, cancellation, or termination.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Lien</h4>
                    <p>Until the unpaid contract price and any other amounts payable by Buyer to Seller under the Order Acknowledgment are paid in full, Seller shall have a lien on the Goods for the unpaid amounts.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Force majeure</h4>
                    <p>Seller shall not be liable to Buyer for any breach of the terms of any contract between the Company and the Buyer incorporating these terms and conditions which is due to any force majeure event which shall include, but not be limited to, acts of God, epidemics or pandemics, laws and regulations, strikes, lightning, fire, flood, storm, war, acts or threats of terrorism, etc. and any other causes that are not reasonably within the control of the party affected. If either party is prevented or delayed from performing any of its obligations under this Agreement (other than an obligation to make payment) by Force Majeure, then:</p>
                    <ul>
                        <li>that party’s obligations under this Agreement shall be suspended for so long as the Force Majeure continues and shall be resumed upon the cessation of the Force Majeure event.</li>
                        <li>that party’s obligations under this Agreement shall be suspended for so long as the Force Majeure continues and shall be resumed upon the cessation of the Force Majeure event.</li>
                        <li>If the Force Majeure event persists for more than 60 calendar days, either Party is entitled to terminate the Agreement on written notice, without liability to pay compensation to the other for termination on account of force majeure.</li>
                    </ul>
                    <p>However, payment due to Jersey Mfg in accordance with the cancellation/termination articles contained herein shall be made without default.</p>
                
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Governing Law& Jurisdiction</h4>
                    <p>All matters relating to the formation, performance, and discharge of any contract incorporating theseterms and conditions shall be governed according to the Laws of the State of Qatar and shall be determined by proceedings conducted within the jurisdiction of Qatar</p>
                </div>

            </div>
        </div>
    </>
    )
};
export default TermsConditions
