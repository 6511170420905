import React from "react";

const Disclaimer = () =>{
    return (
        <>
        <div className="policy-sec">
            <div className="our_products-sec policy">
                <div className="sec-bottom">
                    <h2 className="sec-title">Disclaimer</h2>
                </div>
                <div className="policy-sub">
                    <h5 className="sub-title">PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEBSITE </h5>
                    <p>The terms of use contained herein apply to everyone who visits this website or any of the Jersey Group websites. By accessing and using these websites, you are agreeing to abide by, and be bound by, the following terms of use and any amendments in effect at the time of your visit. Jersey Manufacturing and / or Jersey Group may modify these terms and conditions at any time without notice by updating these Legal Notices.</p>
                    <h4 className="policy-title">Terms of Use</h4>
                    <p>You may download or use online, a copy of the documents, graphics, information or software (the “Material”) found on the Jersey Manufacturing Co. website for your own use. The Material contained on the Jersey Manufacturing Co. website are proprietary information copyrighted by Jersey Manufacturing Co.</p>
                    <p>Permission to use the Material is conditioned upon your agreement</p>
                    <ul>
                        <li>To include the copyright notice in all copies of the Material.</li>
                        <li>That use of the Material is for informational purposes only, and</li>
                        <li>That the Material may not be altered or modified in any way.</li>
                    </ul>
                    <p>You may not modify the Material, remove any copyright or other proprietary notices from the Material, or duplicate the Material for sale or for transfer to third parties in exchange for any form of consideration whatsoever. The trademarks and service marks may be used on the Jersey Group websites. However, nothing contained on the websites should be construed as granting any license or right to use the marks without the written permission of Jersey Manufacturing Co. The contents of Jersey Manufacturing Co’s website or any of the Jersey Group websites are (including, without limitation, the arrangement and layout of the websites; names and logos; and text, illustrations and artwork) are the copyrighted and or licensed property of Jersey Manufacturing Co and are protected under applicable copyright and trademark laws. Jersey Group actively enforces its rights regarding each of its or its partners’ marks.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Links</h4>
                    <p>To the extent that this site or any other Jersey Group site contains links to a third party site, Jersey Group does not make any claim to or offer any warranty or responsibly for the information and/or effect of transferring to the respective third party sites.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Products Availability</h4>
                    <p>References made to products on the Jersey Manufacturing sites does not guarantee that the products will be available in your city, state or country. Such reference does not imply any intention on our part to sell this product or service in your city, state or country and you should always rely on product information especially created for your city, state or country. The site contains information about products and services that may or may not be available in any particular city, state or country or region of the world, may be available under different trademarks in different countries and, where applicable, may be approved or cleared by a government regulatory body for sale or use with different indications and restrictions in different countries. The information on the websites concerning products is provided for informational purposes only and is non-binding. For more detailed information regarding a product you should contact Jersey Manufacturing Co.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Privacy</h4>
                    <p>Jersey Group does not collect personal information about an individual when they visit a Jersey Group website unless that information is voluntarily submitted by the individual as part of request for information, communication with Jersey Group or its subsidiary or part of a survey completed by the individual. In essence, an individual may access the websites without providing personal information so long as the individual does not volunteer the information to Jersey Group. If there is a conflict between these terms and terms of the Privacy and / or cookie policy, the terms of the Privacy and cookie policies will govern and control. If you do not agree to abide by these terms, please do not use or view the site or download any site content.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Changes</h4>
                    <p>Jersey Manufacturing Co. reserves the right, in its sole discretion, to change, modify, add or remove any portion of these Terms, the Site, and/or Contents in whole or in part, at any time, without notice. Changes will be effective when such change is posted. Your continued use of the Site after any changes to the Terms are posted will be acceptance of those changes.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">User Content</h4>
                    <p>All information (other than personal information, which is governed by our Privacy Policy), including data, text, files, graphics and other materials that you transmit to the Site or otherwise supply to Jersey Manufacturing Co., is referred to as “User Content”. By transmitting User Content on or through this Site, you agree that you are solely responsible for the transmission, accuracy, completeness, ownership and publication of that User Content, and that Jersey Manufacturing Co., is not responsible for your transmission of the User Content. By transmitting User Content to Jersey Manufacturing Co., or otherwise making User Content available on or through the Site, you grant Jersey Manufacturing Co. and / or Jersey Group a royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, create derivative works from, distribute and display that User Content, in whole or in part, and/or to incorporate it in other works in any form, and you agree that any “moral rights” in that User Content has been waived. Jersey Manufacturing Co., reserves the right, in its sole discretion, to refuse, edit or remove any User Content, in whole or part, which in Jersey Manufacturing Co’s judgment does not comply with these Terms, is illegal, or is otherwise undesirable, inappropriate, or inaccurate. Jersey Manufacturing Co is not responsible for any decision, lack of decision or delay in editing or removing User Content.</p>
                    <p>You may not transmit in any way User Content on or through the Site that:</p>
                    <ul>
                        <li>Infringes any proprietary rights of any party or person.</li>
                        <li>Is unlawful, harmful, threatening, abusive, harassing, defamatory, obscene, deceptive, fraudulent, sexually graphic, hateful, or invasive of another’s privacy.</li>
                        <li>Contains unsolicited or unauthorized advertising, promotional materials, “spam,” “chain letters,” or any other form of solicitation</li>
                        <li>Contains software viruses or any other computer code, files or programs that may interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or contribute to any other similarly destructive activity.</li>
                    </ul>
                    <p>International users should note that this site is operated and administered from the State of Qatar. If you are accessing this website from a location outside Qatar, you are responsible for compliance with all local laws. Upon Jersey Manufacturing Co’s belief that your conduct fails to conform to these Terms, Jersey Manufacturing Co., in its sole discretion and without notice, may block your access to this site, terminate your limited license and investigate and involve and cooperate with law enforcement authorities, and pursue a civil action or criminal prosecution for any alleged or actual illegal activities involving this Site.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Disclaimer</h4>
                    <p>Purchase of goods and services is subject to Jersey Manufacturing Company’s standard terms and conditions.Jersey Manufacturing Co has attempted to include up to date and accurate information on its websites. Warranties only apply to explicit contracts between Buyers and the Company. The details provided in this documentation are for general information purposes only. However, notwithstanding such efforts, Jersey Manufacturing Co does not make, and expressly disclaims, any representation or warranty of any kind, express or implied (including without limitation warranties of merchantability, fitness for a particular purpose, etc.) with respect to any information or protection of software or equipment on the sites. They are only intended as general information. Illustrated products and systems are only intended to show possible product alternatives. The illustrations may also show products and systems that were customised to specific customers’ requirements and are only available in this form as customer-specific solutions. Some products and systems provided in this documentation may show special equipment that is only available at an additional charge. The details on scope of delivery, appearance, performance as well as weights and measures are valid at the time of the publishing of this documentation, and they are subject to change at any time. All previous releases are now superseded. All information published on this world-wide web server is merely for informative purposes and does not include any kind of declaration of guarantee neither expressly nor implicitly. This Jersey documentation may include technical inaccuracies or typographic errors. Jersey Manufacturing Co. may from time to time change or complete all and any included information.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Limitation of Liability</h4>
                    <p>Your use of Jersey Manufacturing Website or Jersey Group’s websites is at your own risk. In no event shall Jersey Group or any of its officers, directors, employees or affiliates, be liable, directly or indirectly, for any damages of any kind or nature, including, without limitation, direct, incidental or consequential, or any claims or losses, resulting from or in connection with the creation or use of, or reliance on, this site, any other Jersey Group website or any linked website contained in any such sites, or any of the information accessed through any such site or from the inability to use any such site. This limitation includes damage to your computer equipment or any viruses that may infect your computer equipment or other property.</p>
                </div>
                <div className="policy-sub">
                    <h4 className="policy-title">Governing Law & Jurisdiction</h4>
                    <p>All matters relating to the Jersey Group Websites, formation, performance, and discharge of any contract incorporating these terms and conditions shall be governed according to the Laws of the State of Qatar and shall be determined by proceedings conducted within the jurisdiction of Qatar.</p>
                </div>


            </div>
        </div>
    </>
    )
};
export default Disclaimer