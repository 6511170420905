import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/mainpage.css";
import { Navigate, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/logo.svg';
import Arrow from '../../assets/images/right-arrow.svg';


const Header = (props) => {
  const location = useLocation();

  return (
    <>
      <div className="header-bar">
        <div className="header-outer-wrapper">
          <div className="header-inner-wrapper">
          <div className="logo-wrapper">
              <a className="anchor logo-anchor" href="/">
                <picture className="logo">
                  <img
                    srcSet={Logo}
                    alt="jersey manufacturing"
                  />
                </picture>
              </a>
            </div>
            <div id="menuToggle">
              <input className="sidebar" type="checkbox" />
              <span></span>
              <span></span>
              <ul id="menu">
                <Link to="/" className={location.pathname === "/" ? "active" : ""}>
                  <li>Home</li>
                  <img src={Arrow} />
                </Link>
                {/* <hr className="sideNav" /> */}
                <Link to="/products" className={location.pathname === "/products" ? "active" : ""}>
                  <li>Products</li>
                  <img src={Arrow} />
                </Link>
                <Link to="/reference" className={location.pathname === "/reference" ? "active" : ""}>
                  <li>Projects</li>
                  <img src={Arrow} />
                </Link>
                <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>
                  <li>About us</li>
                  <img src={Arrow} />
                </Link>
                <Link to="/contact-us" className={location.pathname === "/contact-us" ? "active" : ""}>
                  <li>Contact us</li>
                  <img src={Arrow} />
                </Link>
              </ul>
            </div>
          </div>
          <div className="navbar-wrapper">
            <nav className="nav-bar">
            <div className="logo-wrapper">
              <a className="anchor logo-anchor" href="/">
                <picture className="logo">
                  <img
                    srcSet={Logo}
                    alt="jersey manufacturing"
                  />
                </picture>
              </a>
            </div>
              <ul className="parent">
                <li>
                <a href="#top">
                    <Link to="/" className={location.pathname === "/" ? "active" : ""} onClick={() => {
                      window.scroll(0, 0);
                    }}>
                    Home
                    </Link>
                    </a>
                </li>
                <li>
                  <Link to="/products" className={location.pathname === "/products" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>
                  <a href="#top">Products</a>
                  </Link>
                </li>
                <li>
                  <Link to="/reference" className={location.pathname === "/reference" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>
                   Projects
                  </Link>
                </li>
                <li>
                  <Link to="/about" className={location.pathname === "/about" ? "active" : ""}onClick={() => {
                    window.scroll(0, 0);
                  }}>
                   About us
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className={location.pathname === "/contact-us" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>
                   Contact us
                  </Link>
                </li>
                <li>
                  <Link to="/login">
                    <button className="loginbtn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                    </svg> Login
                    </button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
