import React from "react";
import { Link } from 'react-router-dom'
import "../../assets/css/mainpage.css";
import { Container, Row, Col } from 'react-bootstrap';
import Aboutimg from '../../assets/images/about-img.jpg';
import AboutimgMobile from '../../assets/images/about-img-mobile.png';
import Iso from '../../assets/images/iso.png';
import FacilityPlus from '../../assets/images/counter-plus.svg';
import Mission from '../../assets/images/mission.png';
import Vision from '../../assets/images/vision.png';
import More from '../../assets/images/more.svg';
import Events3 from '../../assets/images/news2.png';
import Events4 from '../../assets/images/news33.png';
import Quality from '../../assets/images/quality.svg';
import Excellence from '../../assets/images/excellence.svg';
import Relationship from '../../assets/images/relationships.svg';
import Reliability from '../../assets/images/reliability.svg';
import Ethics from '../../assets/images/ethics.svg';
import Teamwork from '../../assets/images/teamwork.svg';
import Empowerment from '../../assets/images/empowerment.svg';
import Commitment from '../../assets/images/commitment.svg';
import Success from '../../assets/images/twenty-five-years.png';
import Trading from '../../assets/images/trading.png';
import Contracting from '../../assets/images/contracting.png';
import Manufacturing from '../../assets/images/manufacturing.png';
import Service from '../../assets/images/services.png';
import Logo1 from '../../assets/images/insulation.png';
import Logo2 from '../../assets/images/firelink.png';
import Logo3 from '../../assets/images/industrial.png';
import Logo4 from '../../assets/images/servicing.png';
import Logo5 from '../../assets/images/engineering.png';
import Logo6 from '../../assets/images/manufacture.png';
import Logo7 from '../../assets/images/glass.png';
import Viewmore from '../../assets/images/seemore.svg';
import Timeline from '../../assets/images/timeline-logo.png';
import TimelineJes from '../../assets/images/timeline-jes.png';
import JesManufacture from '../../assets/images/logo1.png';
import ArrowNext from '../../assets/images/morewhite.svg';
import ArrowPrev from '../../assets/images/more-left.svg';
import HistoryCarousel from "./HistoryCarousel";
import News1 from '../../assets/images/JMC - KSA.jpg';


const Aboutus = () => {
    return (
        <div className="about-us">
            <Col md={12}>
                <Row>
                    <Col sm={6} md={6}>
                        <div className="our_products-sec">
                            <p className="sec-top-title">ABOUT US</p>
                            <div className="sec-bottom">
                                <h2 className="sec-title">Innovative Manufacturing</h2>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={6}>
                        <div className="about-right">
                            <p>Jersey Manufacturing Company (formerly known as Khalid Manufacturing Company),
                                established in 1976 has over four decades of experience in the manufacture of various
                                air control, air distribution and noise control products.</p>
                            <br />
                            <p>We manufactue an extensive range of Dampers, Louvers, Sound Attenuators, Air Terminals (VAVs), Diffusers, Grilles and Registers. In almost past four decades we have created a product portfolio that competes worldwide on quality, price, and applications.

                            </p>
                            {/* <a className="read-more">Read more</a> */}

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="about-image">
                            <img src={Aboutimg} />
                            <img src={AboutimgMobile} className="mobile-img" />

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="about-accredit">
                            <img src={Iso} />
                            <p>
                                The Company Acquired Iso 9001:2015 Accreditation And Invested On New Manufacturing Resources
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="p-0">
                        <div className="our_products-sec facilities">
                            <p className="sec-top-title">FACILITIES</p>
                            <div className="sec-bottom">
                                <h2 className="sec-title">Our Facilities</h2>
                            </div>
                            <div className="about-facilty">
                                <Row>
                                    <Col md={5}>
                                        <div className="facility-box">
                                            <img src={FacilityPlus} />
                                            <div className="facility-des">
                                                <h2>World Class Products</h2>
                                                <p>At its state-of-the-art manufacturing facility at Qatar, Jersey Manufacturing
                                                    Company has the license to manufacture and sell some of the world’s best Pioneering products.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="facility-box">
                                            <img src={FacilityPlus} />
                                            <div className="facility-des">
                                                <h2>Manufacturing Facility</h2>
                                                <p>Jersey Manufacturing Company currently operates out of two manufacturing facilities in the State of Qatar with over 11,000 sq. mts. (120,000 sq. ft) of manufacturing area equipped with
                                                    state-of-the-art CNC machines and a powder coating facility.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="facility-box">
                                            <img src={FacilityPlus} />
                                            <div className="facility-des">
                                                <h2>Qualified Staff</h2>
                                                <p>Our strength lies in the depth of knowledge, skill and experience of our highly
                                                    qualified staff and manufacturing team.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="facility-box">
                                            <img src={FacilityPlus} />
                                            <div className="facility-des">
                                                <h2>Growth</h2>
                                                <p>Over the years we have continued to invest across all areas of the business and are committed
                                                    to continue doing so as the business goes forward.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="facility-box">
                                            <img src={FacilityPlus} />
                                            <div className="facility-des">
                                                <h2>Quality Control</h2>
                                                <p>Local manufacture, testing and distribution ensures Quality Control is central to everything we do. This, in turn, leads to
                                                    faster turnaround on special requirements and to shorter lead times in general.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="facility-box">
                                            <img src={FacilityPlus} />
                                            <div className="facility-des">
                                                <h2>Centralised Customer Service</h2>
                                                <p>QC and customer service are all based at our manufacturing facility in Qatar. They work together to create a seamless interface
                                                    between our customers and our product performance.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2}></Col>
                                </Row>
                            </div>
                            <span className="HomeSubsidiaries_bg_line__OTckB about ratio ratio-1x1">
                                <span className="SvgLineBg_bg_line__Aq2b0 SvgLineBg_bg_line_flip__l2Jky">

                                    <svg id="Component_67_7" data-name="Component 67 – 7" xmlns="http://www.w3.org/2000/svg" width="347.424" height="548.132" viewBox="0 0 347.424 548.132">
                                        <defs>
                                            <linearGradient id="linear-gradient" x1="0.796" y1="0.9" x2="0.142" y2="-0.19" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stop-color="#fff" />
                                                <stop offset="1" stop-color="#7ed1ff" />
                                            </linearGradient>
                                            <clipPath id="clip-path">
                                                <rect id="Rectangle_37623" data-name="Rectangle 37623" width="299.021" height="548.132" fill="none" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
                                            </clipPath>
                                        </defs>
                                        <circle id="Ellipse_78333" data-name="Ellipse 78333" cx="173.712" cy="173.712" r="173.712" transform="translate(0 78.893)" opacity="0.084" fill="url(#linear-gradient)" />
                                        <g id="Group_129084" data-name="Group 129084" transform="translate(24.143)" clip-path="url(#clip-path)">
                                            <path id="Path_111353" data-name="Path 111353" d="M.5,546.32v-154.8a129.413,129.413,0,0,1,37.9-91.509l79.675-79.675a121.073,121.073,0,0,0,35.461-85.611V1.24" transform="translate(0.057 0.141)" fill="none" stroke="#fc9e20" stroke-width="1" />
                                            <path id="Path_111354" data-name="Path 111354" d="M161.114,534.445V433.68a104.663,104.663,0,0,0-30.655-74.008L90.584,319.8a98.729,98.729,0,0,1-28.916-69.81V113.228" transform="translate(7.033 12.913)" fill="none" stroke="#10a6e8" stroke-width="1" />
                                            <line id="Line_2329" data-name="Line 2329" y1="438.933" transform="translate(201.01 109.199)" fill="none" stroke="#8c9db9" stroke-width="1" />
                                            <line id="Line_2330" data-name="Line 2330" y1="229.493" transform="translate(268.157 317.525)" fill="none" stroke="#8c9db9" stroke-width="1" />
                                            <line id="Line_2331" data-name="Line 2331" y1="182.773" transform="translate(68.7 363.688)" fill="#54698a" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
                                            <line id="Line_2332" data-name="Line 2332" y1="182.773" transform="translate(68.7 363.688)" fill="none" stroke="#8c9db9" stroke-width="1" />
                                            <path id="Path_111355" data-name="Path 111355" d="M53.049,122.507l9.555-9.26,9.065,9.472" transform="translate(6.05 12.915)" fill="none" stroke="#10a6e8" stroke-miterlimit="10" stroke-width="1" />
                                            <path id="Path_111356" data-name="Path 111356" d="M53.049,335l9.555-9.26,9.065,9.472" transform="translate(6.05 37.149)" fill="none" stroke="#8c9db9" stroke-width="1" />
                                            <path id="Path_111357" data-name="Path 111357" d="M217.906,293.681l9.555-9.26,9.2,9.749" transform="translate(40.752 32.437)" fill="none" stroke="#8c9db9" stroke-width="1" />
                                            <path id="Path_111358" data-name="Path 111358" d="M129.339,9.97,138.894.71l9.065,9.472" transform="translate(14.75 0.081)" fill="none" stroke="#fc9e20" stroke-width="1" />
                                            <path id="Path_111359" data-name="Path 111359" d="M171.765,106.918l9.555-9.26,9.065,9.472" transform="translate(19.589 11.137)" fill="none" stroke="#8c9db9" stroke-width="1" />
                                        </g>
                                    </svg>
                                </span>
                            </span>

                        </div>
                    </Col>
                </Row>
                <Row id="VisionMission">
                    <Col md={12} className="p-0">
                        <div className="our_products-sec mission-vision">
                            <p className="sec-top-title">ABOUT US</p>
                            <div className="sec-bottom">
                                <h2 className="sec-title">Mission & Vision Statement</h2>
                            </div>
                            <Row>
                                <Col md={6} sm={6} >
                                    <div className="mission-box">
                                        <div className="mission-title">
                                            <img src={Mission} />
                                            <h2>Mission</h2>
                                        </div>
                                        <p>Our mission is to build a product portfolio which is profitable and sustainable with a focus on providing
                                            the most reliable engineering solutions in all our markets.</p>
                                    </div>
                                </Col>
                                <Col md={6} sm={6} >
                                    <div className="mission-box">
                                        <div className="mission-title">
                                            <img src={Vision} />
                                            <h2>Vision</h2>
                                        </div>
                                        <p>Our vision is to be a market leader in our chosen field, providing industry-leading solutions and becoming the partner of choice to our customers,
                                            partners and employees whilst adhering to our core values</p>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="p-0">
                        <div className="our_products-sec ourvalues">
                            <p className="sec-top-title">Our Values</p>
                            <div className="sec-bottom">
                                <h2 className="sec-title">Built on values that matter</h2>
                            </div>
                            <Row className="values">
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Quality} />
                                            <h2>Quality</h2>
                                        </div>

                                    </div>
                                </Col>
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Excellence} />
                                            <h2>Excellence</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Relationship} />
                                            <h2>Relationships</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Reliability} />
                                            <h2>Reliability</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Ethics} />
                                            <h2>Ethics</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Teamwork} />
                                            <h2>Teamwork</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Empowerment} />
                                            <h2>Empowerment</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} className="pl-0">
                                    <div className="value-box">
                                        <div className="value-title">
                                            <img src={Commitment} />
                                            <h2>Commitment</h2>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                <Row id="History">
                    <Col md={12}>
                        <div className="our_products-sec history">
                            <h2 className="sec-title">History of Jersey Manufacturing</h2>
                            <div class="timeline" id="timeline">
                                <ol>
                                    <li>
                                        <div>
                                            <time>1976</time>
                                            <p>Manufacturing Operations was established in 1976 in a 2500 sq.mt facility in the Industrial Area of the State of Qatar for manufacture of Air Outlets under License from Anemostat, USA</p>
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2000</time>
                                            <p>The manufacturing business of KMC is acquired by Jersey Group</p>
                                            <img src={TimelineJes} />
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2001-2009</time>
                                            <p>By the mid 2000 the business had grown to such a size that new manufacturing
                                                facilities were required.</p>
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2010</time>
                                            <p>In 2010 the business moved to a new upgraded 5,000 sq.mt. facility to the
                                                New Industrial Area in the State of Qatar, where extensive investment in
                                                product development, people, IT and capital equipment took place, all of
                                                which underpinned the company’s growth and subsequent rapid expansion.
                                            </p>
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2011</time>
                                            <p>We enter into an agreement with Mestek, USA for the manufacture of UL
                                                classified Life Safety dampers. KMC enters into an agreement to
                                                manufacture HVAC noise control products with Aerosonics, USA in
                                                Qatar and the region.
                                            </p>
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2012</time>
                                            <p>All air outlets and Air terminal products manufactured by us are now 
                                                under the JMC brand(formerly  KMC ) & added another manufacturing facility 
                                                of 5,500 sq.mt for the manufacture of UL classified dampers with state of the art CNC machines.
                                            </p>
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2014-2016</time>
                                            <p>Export revenue to the GCC region increased due to addition of the entire range of Air
                                                control and Distribution products.
                                            </p>
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2017-2020</time>
                                            <p>Business expands further along with our customer base and investment in broadening product range to
                                                provide extensive integrated solutions like
                                                Air Terminals(Pressure Independent VAVs) manufactured by KMC certified by AHRI.
                                            </p>
                                            <img src={Timeline} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2021</time>
                                            <p>The Board decides to re-brand KMC as "Jersey Manufacturing" to align wth the Jersey Group
                                                and the planned expansion to other markets.
                                            </p>
                                            <img src={JesManufacture} />
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <time>2022</time>
                                            <p>In line with the future vision, Jersey Manufacturing, a division of Jersey Group and Air Balance
                                                , USA have entered into an agreement for the manufacture and sales of UL classified Life Safety Dampers and other products
                                                in the MENA region, India and the Indian sub-continent.
                                            </p>
                                            <img src={JesManufacture} />
                                        </div>
                                    </li>
                                    <li></li>
                                </ol>
                                <div class="arrows">
                                    <button class="arrow arrow__prev disabled" id="buttonPrev">
                                        <img src={ArrowPrev} alt="prev timeline arrow" />
                                    </button>
                                    <button class="arrow arrow__next" id="buttonNext">
                                        <img src={ArrowNext} alt="next timeline arrow" />
                                    </button>
                                </div>



                            </div>
                            {/* Mobile view */}
                            <div className="timeline-mobile">
                                <HistoryCarousel />
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={12} className="p-0">
                        <div className="our_products-sec company-info">
                            <Row>
                                <Col md={6} sm={6}>
                                    <div className="sec-bottom">
                                        <img src={Success} />
                                    </div>

                                </Col>
                                <Col md={6} sm={6}>
                                    <div>
                                        <h2 className="sec-title">Jersey group</h2>
                                        <p className="sec-bottom-title">Jersey Group has come of age as a tall leader in the trading, contracting, manufacturing and service industry in Qatar. With its 600-strong experienced and efficient workforce, Jersey Group has proved to be a dependable business performer, reporting year-on-year growth of 20 percent and upwards as well as posting big new business wins.</p>

                                    </div>
                                </Col>
                            </Row>
                            <div className="about-services">
                                <Row>
                                    <Col md={6}>
                                        <div className="service-box">
                                            <div className='carousel__item'>
                                                <img width="100%" src={Trading} />

                                                <div className='carousel__caption'>
                                                    <p>Trading</p>
                                                </div>
                                            </div>
                                            <div class="middle trading">
                                                <div class="text">
                                                    <h2>Trading</h2>
                                                    <p>With over 25 years of market experience, Trading Verticals of Jersey Group are known for reliability and delivering high quality products and services.</p>
                                                </div>
                                            </div>
                                            <div className="service-logo">
                                                <img width="100%" src={Logo1} />
                                                <img width="100%" src={Logo5} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="service-box">
                                            <div className='carousel__item'>
                                                <img width="100%" src={Contracting} />
                                                <div className='carousel__caption'>
                                                    <p>Contracting</p>
                                                </div>
                                            </div>
                                            <div class="middle contracting">
                                                <div class="text">
                                                    <h2>Contracting</h2>
                                                    <p>Building safety being foremost in all projects, Firelink WLL began its operations modestly & has grown in stature over time to be Qatar’s premier company for Fire Fighting, Fire Alarm & building safety.</p>
                                                </div>
                                            </div>
                                            <div className="service-logo">
                                                <img width="100%" src={Logo2} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="service-box">
                                            <div className='carousel__item'>
                                                <img width="100%" src={Manufacturing} />
                                                <div className='carousel__caption'>
                                                    <p>Manufacturing</p>
                                                </div>
                                            </div>
                                            <div class="middle manufacturing">
                                                <div class="text">
                                                    <h2>Manufacturing</h2>
                                                    <p>Jersey Group have set up state of the art manufacturing facilities Having a total of over 15,000 sq mts of facilities in Qatar to manufacture some of the world’s leading products for the construction Industry.</p>
                                                </div>
                                            </div>
                                            <div className="service-logo">
                                                <img width="100%" src={Logo6} />
                                                <img width="100%" src={Logo3} />
                                                <img width="100%" src={Logo7} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="service-box">
                                            <div className='carousel__item'>
                                                <img width="100%" src={Service} />
                                                <div className='carousel__caption'>
                                                    <p>Services</p>
                                                </div>
                                            </div>
                                            <div class="middle services">
                                                <div class="text">
                                                    <h2>Services</h2>
                                                    <p>We provide Integrated Facility Management (IFM) services vide advanced technologies and Energy Efficiency Services that enhance optimized use of energy within your facilities. We also provide specialized services such as Fire and building safety & security systems.</p>
                                                </div>
                                            </div>
                                            <div className="service-logo">
                                                <img width="100%" src={Logo4} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='bottom-more'>
                                    <div className='button-more'>
                                        <Link to="https://www.jerseygroup.com/" target="_blank">Visit Jersey group <img src={Viewmore} /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col md={12} className="p-0">
                        <div className="our_products-sec latestNews">
                            <p className="sec-top-title">Insights</p>
                            <div className="sec-bottom">
                                <h2 className="sec-title">Latest news & Events</h2>
                                <span><Link to="/news-events" onClick={() => { window.scroll(0, 0); }}>View More <img src={More} /></Link></span>
                            </div>

                            <div className="latest-news-sec">
                                <Row>
                                    <Col sm={6} md={4}>
                                        <Link
                                            to={{
                                                pathname: "/news-events/news",
                                                search: "?news=1",
                                            }}
                                            onClick={() => {
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            <div className="news-section">
                                                <div className="image-box">
                                                    <img src={Events3} />
                                                </div>
                                                {/* <p className="news-time">NEWS • FEBRUARY 2023</p> */}
                                                <h5>Air Balance, USA signs agreement with Jersey Manufacturing Company</h5>

                                            </div>
                                        </Link>
                                    </Col>
                                    <Col sm={6} md={4}>
                                        <Link
                                            to={{
                                                pathname: "/news-events/news",
                                                search: "?news=2",
                                            }}
                                            onClick={() => {
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            <div className="news-section">
                                                <div className="image-box">
                                                    <img src={Events4} />
                                                </div>
                                                {/* <p className="news-time">NEWS • FEBRUARY 2023</p> */}
                                                <h5>Pressure Independent VAVs manufactured by Jersey Manufacturing (formerly KMC).</h5>

                                            </div>
                                        </Link>
                                    </Col>
                                    <Col sm={6} md={4}>
                                        <Link
                                            to={{
                                                pathname: "/news-events/news",
                                                search: "?news=3",
                                            }}
                                            onClick={() => {
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            <div className="news-section">
                                                <div className="image-box">
                                                    <img src={News1} />
                                                </div>
                                                {/* <p className="news-time">NEWS • FEBRUARY 2023</p> */}
                                                <h5>Jersey Manufacturing Co (formerly known as KMC) is now having its presence...</h5>

                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                                <div className='bottom-more'>
                                    <div className='button-more'><Link to="/news-events" onClick={() => { window.scroll(0, 0); }}>View More <img src={More} /></Link></div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Col>


        </div>
    )
};

export default Aboutus;