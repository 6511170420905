import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import More from '../../assets/images/seemore.svg';
import Events4 from '../../assets/images/events1.png';
import Twitter from '../../assets/images/twit.png';
import Facebook from '../../assets/images/fb.png';
import Instagram from '../../assets/images/instagram-bl.png';
import Linkedin from '../../assets/images/link-in.png';
import Next from '../../assets/images/seemore-white.svg';
import Attach from '../../assets/images/attach-file.png';
import DownloadFile from '../../assets/images/pdf.png';
import FetchData, { CreateEntry, httpHeaders } from "../../providers/dataProvider";
import urls from "../../constants";
import {
    useQuery
} from 'react-query'
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import {
    isRequired,
    isValidEmail,
    isValidPhone,
    isTextOnly,
    handleSocialMediaClick,
    isValidFileSize
} from '../../utils/Validate';
import AWS from 'aws-sdk';


AWS.config.update({
    accessKeyId: urls.AWS_ACCESS_KEY_ID,
    secretAccessKey: urls.AWS_ACCESS_SECRET_KEY,
    region: urls.AWS_REGION,
    signatureVersion: 'v4',
});



const defaultValues = {
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNo: "",
    JobType: "",
    Qualification: "",
    Attachment: "",
    Message: "",
    City: "",
    State: "",
    Country: "",
    privacy: false,
}


const CareerDetail = () => {
    const location = useLocation();
    const [createMsg, setCreateMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false)
    const [formValues, setFormValues] = useState(defaultValues);
    const [jobDetail, setJobDetail] = useState({})
    const [countrydrp, setCountrydrp] = useState([]);
    const [Country, setCountry] = useState({});
    const [phoneValue, setPhoneValue] = useState(null);
    const [filelocation, setLocation] = useState("");
    const requestOptions = httpHeaders();
    const [file, setFile] = useState({ fileobj: "", fileurl: "" });

    const ALLOWED_FILE_TYPES = ['application/pdf'];

    const s3 = new AWS.S3();

    const { data: country, loading: countryloading, error: countryerror } = useQuery({
        queryKey: ['Countries'],
        queryFn: () =>
            fetch(`${urls.COUNTRY_LIST_URL}`, requestOptions).then(
                (res) => res.json(),
            ),
    })

    // const { data: country, countryloading, countryerror } = FetchData("Countries");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type == "checkbox" ? checked : value,
        });

        // re-run the validation check on the changed field value
        const errors = validation({
            ...formValues,
            [name]: type == "checkbox" ? checked : value,
        });
        // update the formErrors state
        setFormErrors({
            ...formErrors,
            [name]: errors[name]
        });
    }

    const handleInputBlur = (e, name) => {
        if (e.target) {

            if (name === "Country" || name === "MobileNo" || name === "file") {
                const errors = validation({
                    ...formValues,
                    [name]: name === "file" ? e.target?.files[0] : name === "Country" ? Country.label : e.target?.value,
                });

                setFormErrors({
                    ...formErrors,
                    [name]: errors[name],
                });
            }
            else {
                const { name, value, type, checked } = e.target;

                const errors = validation({
                    ...formValues,
                    [name]: type == "checkbox" ? checked : value,
                });

                setFormErrors({
                    ...formErrors,
                    [name]: errors[name],
                });
            }


        }
    };

    useEffect(() => {
        if (location?.state) {
            const item = location?.state;
            if (item?.jobdetail) {
                setJobDetail(item?.jobdetail)
            }
        }
    }, [])

    useEffect(() => {
        setFormValues({
            ...formValues,
            JobType: jobDetail?.jobTitle
        })
    }, [jobDetail])


    const validation = (values) => {
        const errors = {};

        if (!isRequired(values.FirstName)) {
            errors.FirstName = 'First name is required';
        } else if (!isTextOnly(values.FirstName)) {
            errors.FirstName = 'First name can only contain alphabets';
        }

        if (!isRequired(values.LastName)) {
            errors.LastName = 'Last name is required';
        } else if (!isTextOnly(values.LastName)) {
            errors.LastName = 'Last name can only contain alphabets';
        }

        if (!isRequired(values.Email)) {
            errors.Email = 'Email is required';
        } else if (!isValidEmail(values.Email)) {
            errors.Email = 'Email is not valid';
        }
        if (values?.MobileNo === undefined) {
            errors.MobileNo = 'Mobile number is required';
        }
        else if (values?.MobileNo?.length <= 4) {
            errors.MobileNo = 'Mobile number is required';
        }
        else if (!isValidPhoneNumber(values.MobileNo)) {
            errors.MobileNo = 'Mobile number is not valid';
        }

        if (!isRequired(values.Qualification)) {
            errors.Qualification = 'Qualification is required';
        }

        if (!isRequired(values.Country)) {
            errors.Country = 'Country name is required';
        }
        if (!isRequired(values.State)) {
            errors.State = 'State name is required';
        }
        if (!isRequired(values.City)) {
            errors.City = 'City is required';
        }
        if (!isRequired(file?.fileobj)) {
            errors.file = 'Please upload file';
        }
        else if(!ALLOWED_FILE_TYPES.includes(file?.fileobj?.type))
        {
            errors.file = 'Please upload pdf file';
            setFile({ fileobj: "", fileurl: "" })
        }
        else if (!isValidFileSize(file?.fileobj?.size)) {
            errors.file = 'File size is greater than max limit of 500kb';
            setFile({ fileobj: "", fileurl: "" })
        }
        if (!isRequired(values.privacy)) {
            errors.privacy = 'Please agree to the privacy policy.';
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            uploadToS3();
        }
        setIsSubmit(false);
    }, [formErrors]);

    useEffect(() => {
        if (filelocation !== "") {
            let formvaluecopy = { ...formValues };
            formvaluecopy["Attachment"] = filelocation;
            CreateEntry(
                'createcareer',
                formvaluecopy,
                setCreateMsg,
                'Job Applied Succesfully',
                'Failed to Apply Job'
            );
            setFormErrors({});
            setLocation("")
            setFile({ fileobj: "", fileurl: "" })
            setFormValues(defaultValues)
        }
    }, [filelocation])

    const handleCareerSubmit = (event) => {
        event.preventDefault();
        setFormErrors(validation(formValues));
        setIsSubmit(true);
    }

    useEffect(() => {
        const getCountryData = () => {
            if (country) {
                const list = formatcountrydrp(country);
                setCountrydrp(list);
            }
        };
        getCountryData();
    }, [country]);

    const formatcountrydrp = (list) => {
        var te = list?.map((data, index) => ({
            label: data?.wsCountry,
            value: data?.wsCntId
        }));
        return te;
    };

    const handleFilterChange = (selectedOption, params) => {
        if (params === "Country") setCountry(selectedOption)

        setFormValues({ ...formValues, [params]: selectedOption.label });

        // re-run the validation check on the changed field value
        const errors = validation({
            ...formValues,
            [params]: selectedOption?.label
        });
        // update the formErrors state
        setFormErrors({
            ...formErrors,
            [params]: errors[params]
        });
    };

    const closepopup = () => {
        setSuccessMsg('');
        setErrorMsg('');
    }

    useEffect(() => {
        if (createMsg?.message !== "") {
            if (createMsg?.message === "Job Applied Succesfully")
            {
                setSuccessMsg(createMsg?.message);
                setTimeout(() => {
                    setSuccessMsg('');
                  }, 5000);
            }
            else if (createMsg?.message === "Failed to Apply Job" || createMsg?.message === "Faliure")
                setErrorMsg(createMsg?.message);

            setFormValues({
                FirstName: "",
                LastName: "",
                Email: "",
                MobileNo: "",
                JobType: "",
                Qualification: "",
                Attachment: "",
                Message: "",
                City: "",
                State: "",
                Country: "",
            })

            setLocation("")
            setFile({ fileobj: "", fileurl: "" })
        }

    }, [createMsg]);

    useEffect(() => {
        if (phoneValue !== null) {
            setFormValues({
                ...formValues,
                "MobileNo": phoneValue,
            });

            // re-run the validation check on the changed field value
            const errors = validation({
                ...formValues,
                "MobileNo": phoneValue,
            });
            // update the formErrors state
            setFormErrors({
                ...formErrors,
                "MobileNo": errors["MobileNo"]
            });
        }

    }, [phoneValue])

    const handleFileSelect = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);

        setFile({ fileobj: e.target.files[0], fileurl: url });

        // re-run the validation check on the changed field value
        const errors = validation(formValues);
        // update the formErrors state
        setFormErrors({
            ...formErrors,
            "file": errors["file"]
        });
    }

    useEffect(() => {
        if (file?.fileobj !== "") {
            // re-run the validation check on the changed field value
            const errors = validation(formValues);
            // update the formErrors state
            setFormErrors({
                ...formErrors,
                "file": errors["file"]
            });

        }
    }, [file?.fileobj])

    const uploadToS3 = async () => {
        if (!file?.fileobj) {
            return;
        }
        const params = {
            Bucket: urls.AWS_BUCKET_NAME,
            Key: `${Date.now()}.${file?.fileobj?.name}`,
            Body: file?.fileobj
        };
        const { Location } = await s3.upload(params).promise();

        if (Location === "") {
            setFormErrors({
                ...formErrors,
                "file": "File not uploaded",
            });
        } else
            setLocation(Location);
        //console.log('uploading to s3', Location);
    }

    const handleRemoveFile = () => {
        setFile({ fileobj: "", fileurl: "" })
        // re-run the validation check on the changed field value
        //  const errors = validation(formValues);
        // update the formErrors state
        setFormErrors({
            ...formErrors,
            "file": "Please upload file"
        });
    }


    return (
        <div className='career-detail'>
            <div className='details'>
                <div className="bread-crumbs">
                    <nav>
                        <Link to="/careers"
                            className={location.pathname === "/careers" ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            Career
                        </Link>
                        <span className="breadcrumb-arrow">/</span>
                        <Link to="/career-detail"
                            className={location.pathname.startsWith("/career-detail") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                            Career Detail
                        </Link>
                    </nav>
                </div>
                <div className="our_products-sec careerDetail">
                    <Row>
                        <Col md={9}>
                            <div className='career-left'>
                                <div className="sec-bottom">
                                    <h2 className="sec-title">{jobDetail?.jobTitle}</h2>
                                </div>

                                <div className="career-overview">
                                    <div className="job-section">
                                        <p className='sec-bottom-title'>Overview</p>
                                        <p>
                                            {jobDetail?.jobOverview}
                                        </p>

                                    </div>
                                    <div className="job-section">
                                        <p className='sec-bottom-title'>Requirements</p>
                                        <ul>
                                            <li> {jobDetail?.jobRequirements}</li>
                                        </ul>
                                    </div>
                                    <div className="job-section">
                                        <p className='sec-bottom-title'>Benefits</p>
                                        <ul>
                                            <li>{jobDetail?.benefits}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="contact-form">
                                    <p className='sec-bottom-title'>Apply Now</p>

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <input className="did-floating-input" type="text" placeholder=" " name="FirstName" value={formValues.FirstName} onBlur={(e) => { handleInputBlur(e, "FirstName") }} onChange={handleChange} />
                                                <label className="did-floating-label">First Name</label>
                                                {formErrors?.FirstName && <p className="form-error">{formErrors?.FirstName}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <input className="did-floating-input" type="text" placeholder=" " name="LastName" value={formValues.LastName} onBlur={(e) => { handleInputBlur(e, "LastName") }} onChange={handleChange} />
                                                <label className="did-floating-label">Last Name</label>
                                                {formErrors?.LastName && <p className="form-error">{formErrors?.LastName}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <input className="did-floating-input" type="text" placeholder=" " name="Email" value={formValues.Email} onBlur={(e) => { handleInputBlur(e, "Email") }} onChange={handleChange} />
                                                <label className="did-floating-label">Email</label>
                                                {formErrors?.Email && <p className="form-error">{formErrors?.Email}</p>}

                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>

                                            <div className="did-floating-label-content">
                                                {/* <input className="did-floating-input" type="text" placeholder=" " name="MobileNo" value={formValues.MobileNo} onChange={handleChange} /> */}
                                                {formValues.MobileNo && <label class="did-floating-label" style={{top:"-15px"}}>Phone Number</label>}
                                                <PhoneInput
                                                    id='MobileNo'
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    // error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                    name='MobileNo'
                                                    placeholder="Mobile Number"
                                                    country={'in'}
                                                    defaultCountry={'QA'}
                                                    value={formValues.MobileNo || ''}
                                                    onBlur={(value) => { handleInputBlur(value, "MobileNo") }}
                                                    onChange={setPhoneValue}
                                                    enableSearch
                                                    searchPlaceholder="Search Country"
                                                />
                                                {formErrors?.MobileNo && <p className="form-error" style={{marginLeft: "38px"}}>{formErrors?.MobileNo}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <label class="did-floating-label country" value={Country.value}>Country</label>
                                                <Select
                                                    name="countrydrp"
                                                    options={countrydrp}
                                                    onChange={(e) => { handleFilterChange(e, "Country") }}
                                                    value={Country}
                                                    placeholder="Country"
                                                    className="did-floating-input"
                                                    onBlur={(e) => { handleInputBlur(e, "Country") }}
                                                    hideSelectedOptions={false}
                                                />

                                                {formErrors?.Country && <p className="form-error">{formErrors?.Country}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <input className="did-floating-input" type="text" placeholder=" " name="State" value={formValues.State} onBlur={(e) => { handleInputBlur(e, "State") }} onChange={handleChange} />
                                                <label className="did-floating-label">State</label>
                                                {formErrors?.State && <p className="form-error">{formErrors?.State}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <input className="did-floating-input" type="text" placeholder=" " name="City" value={formValues.City} onBlur={(e) => { handleInputBlur(e, "City") }} onChange={handleChange} />
                                                <label className="did-floating-label">City</label>
                                                {formErrors?.City && <p className="form-error">{formErrors?.City}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <input className="did-floating-input" type="text" placeholder=" " name="Qualification" value={formValues.Qualification} onBlur={(e) => { handleInputBlur(e, "Qualification") }} onChange={handleChange} />
                                                <label className="did-floating-label">Highest Qualification</label>
                                                {formErrors?.Qualification && <p className="form-error">{formErrors?.Qualification}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12}>
                                            <div className='form-container'>
                                                <div class="upload-files-container">
                                                    {!file?.fileurl && (
                                                        <div class="drag-file-area">
                                                            <img src={Attach} />
                                                            <h3 class="dynamic-message"> Select a file to import </h3>
                                                            <label class="label"> pdf <span class="browse-files">
                                                                <input type="file" class="default-file-input"
                                                                    accept="application/pdf"
                                                                    //onChange={(e)=>uploadfile(e.target.files[0])} 
                                                                    onChange={handleFileSelect}
                                                                   // onBlur={(e) => { handleInputBlur(e, "file") }}
                                                                /> </span> </label>


                                                        </div>)}
                                                    {file?.fileurl && (
                                                        <div className='uploaded-box'>
                                                            <a href={file?.fileurl} target="_blank" rel="noopener noreferrer">
                                                                <img src={DownloadFile} alt="Download" />
                                                                <span>
                                                                    {file?.fileobj?.name}
                                                                </span>
                                                            </a>

                                                            <button onClick={() => handleRemoveFile()} className="close-img">
                                                                X
                                                            </button>
                                                        </div>

                                                    )}
                                                    {formErrors?.file && <p className="form-error">{formErrors?.file}</p>}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <div className="did-floating-label-content">
                                                <input className="did-floating-input" type="text" placeholder=" " name="Message" value={formValues.Message} onBlur={(e) => { handleInputBlur(e, "Message") }} onChange={handleChange} />
                                                <label className="did-floating-label">Message(optional)</label>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12}>
                                            <div className="form-check">
                                                <div className='agree-check'>
                                                    <input type="checkbox" className="form-check-input" name="privacy" checked={formValues.privacy}
                                                        onChange={handleChange} onBlur={(e) => { handleInputBlur(e, "privacy") }}
                                                    />
                                                    <label className="form-check-label" htmlFor="check2">I agree to the <a href='/privacy-policy'>privacy policy</a> and to be contacted by Jersey Manufacturing</label>
                                                </div>
                                                {formErrors.privacy && <p className="form-error">{formErrors.privacy}</p>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={7} className="submit-sec">
                                            <div className='submit-sec'>
                                                <button type="submit" className="btn submit-btn" onClick={handleCareerSubmit}>Submit <img src={Next} alt="Next" /></button>
                                            </div>
                                            
                                        </Col>
                                        <Col sm={12} md={12}>
                                            {successMsg && 
                                            <div className='alert-toast'>
                                                <p className="success-message">{successMsg}</p>
                                                <button class="close-img" onClick={() => closepopup()}>X</button>
                                            </div>
                                            }
                                                {errorMsg && <div className='alert-toast'>
                                                <p className="form-error">{errorMsg}</p>
                                                <button class="close-img" onClick={() => closepopup()}>X</button>
                                            </div>}
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className='location-div'>
                                <div className='address'>
                                    <p className='sec-bottom-title'>Location</p>
                                    <h4>{jobDetail?.jobLocation}</h4>
                                </div>
                                <div className='address'>
                                    <p className='sec-bottom-title'>Job Type</p>
                                    <h4>{jobDetail?.jobType}</h4>
                                </div>
                                <div className='address'>
                                    <p className='sec-bottom-title'>Share with Your Friends</p>
                                    <div className='social-icons'>
                                        <a onClick={() => handleSocialMediaClick('Twitter')}><img src={Twitter} alt="Twitter" /></a>
                                        <a onClick={() => handleSocialMediaClick('Facebook')} title="Facebook"><img src={Facebook} alt="Facebook" /></a>
                                        <a onClick={() => handleSocialMediaClick('Instagram')} title="Instagram"><img src={Instagram} alt="Instagram" /></a>
                                        <a onClick={() => handleSocialMediaClick('LinkedIn')} title="Linkedin"><img src={Linkedin} alt="Linkedin" /></a>
                                    </div>
                                </div>
                                {/* <button type="submit" className="btn display-btn">Apply Now <img src={Next} alt="Next" /></button> */}
                            </div>
                        </Col>
                    </Row>




                </div>
            </div>




        </div>
    )
};
export default CareerDetail
