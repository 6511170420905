import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/mainpage.css';
import { httpHeaders } from '../../providers/dataProvider';
import urls from '../../constants';
import { useQuery } from 'react-query';

function ProductCarousel() {

  const requestOptions = httpHeaders();

  const { isLoading, error, data: subproduct } = useQuery(
    {
      queryKey: ['subproduct'],
      queryFn: () =>
        fetch(urls.PRODUCT_SUBPROD_URL, requestOptions).then((res) => res.json()),
      enabled: true,
    }
  );
  
  const [progress, setProgress] = useState(0);
  
 

  const CustomDot = ({ active, onClick }) => {
    return (
      <li>
        <button
          className={`custom-dot ${active ? "custom-dot--active" : ""}`}
          onClick={() => onClick()}
        />
      </li>
    );
  };

  const dynamicIndex = 3;
  useEffect(() => {
    if (subproduct && subproduct.length > 0) {
      setProgress(100 / subproduct.length);
    }
  }, [subproduct]);

  const handleSlideChange = (currentIndex) => {
    const totalSlides = subproduct ? subproduct.length : 0;
    const currentProgress = Math.ceil(((currentIndex + 1) / totalSlides) * 100);

    if (currentIndex === dynamicIndex) {
      setProgress(100);
    } else {
      setProgress(currentProgress);
    }
  };
  
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };


  return (
    <>
     {subproduct && (
      <Carousel
        responsive={responsive}
        arrows={window.innerWidth <= 600 ? false : true}
        beforeChange={handleSlideChange}
        swipeable={true}
        showDots
        customDot={<CustomDot />}
      >
        {subproduct?.map((item, index) => (
          <div className='carousel__item' key={index}>
            <Link to={{pathname: `/product-list`, search: `?Id=${item?.wspGrpId}&Name=${item?.wspGrpName}`}} onClick={() => {window.scroll(0, 0);}}>
              <div className='image-box'>
                <img width="100%" src={item?.wspGrpImgUrl} alt={item?.wspGrpName} />
              </div>
              <div className='carousel__caption'>
                <p>{item?.wspGrpName}</p>
              </div>
            </Link>
          </div>
        ))}

      </Carousel>
        )}

      {/* <ProgressBar now={progress} max={100} /> */}
    </>
  );
}

export default ProductCarousel


