import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/mainpage.css';
import Certificate1 from '../../assets/images/certificate1.png';
import Certificate2 from '../../assets/images/certificate2.png';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 2
    },
    pc: {
        breakpoint: { max: 1200, min: 768 },
        items: 1
      },
    tablet: {
      breakpoint: { max: 768, min: 525 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };

function CertificationCarousel() {
    return (
      <>
      {window.innerWidth <= 600 ? 
        <Carousel responsive={responsive} arrows={false}>
            <div className="certificates">
                <div className="certificate-box">
                    <img src={Certificate1} />
                    <h5>Life safety dampers</h5>
                    <p>Tested and classified by Underwriters Laboratories Inc, USA to UL555 and UL555s standards</p>
                </div>
            </div>
            <div className="certificates">
                <div className="certificate-box">
                    <img src={Certificate2} />
                    <h5>Variable Air Volume air terminals (VAVs)</h5>
                    <p>Tested and certified by AHRI, USA, to AHRI Standard 880 for Air Terminals.</p>
                </div>
            </div>
            <div className="certificates">
                <div className="certificate-box">
                    <img src={Certificate1} />
                    <h5>Life safety dampers</h5>
                    <p>Tested and classified by Underwriters Laboratories Inc, USA to UL555 and UL555s standards</p>
                </div>
            </div>
            <div className="certificates">
                <div className="certificate-box">
                    <img src={Certificate2} />
                    <h5>Variable Air Volume air terminals (VAVs)</h5>
                    <p>Tested and certified by AHRI, USA, to AHRI Standard 880 for Ar Terminals.</p>
                </div>
            </div>
          </Carousel>
          :
          <div className='d-flex'>
            <div className="certificates">
                <div className="certificate-box">
                    <img src={Certificate1} />
                    <h5>Life safety dampers</h5>
                    <p>Tested and classified by Underwriters Laboratories Inc, USA to UL555 and UL555s standards</p>
                </div>
            </div>
            <div className="certificates">
                <div className="certificate-box">
                    <img src={Certificate2} />
                    <h5>Variable Air Volume air terminals (VAVs)</h5>
                    <p>Tested and certified by AHRI, USA, to AHRI Standard 880 for Air Terminals.</p>
                </div>
            </div>
          </div>
      }
      </>
    );
}

export default CertificationCarousel


