import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Upbutton from '../../assets/images/upbtn.png';
import Next from '../../assets/images/next-button.svg';
import Seemorewhite from '../../assets/images/seemore-white.svg';
import MapView from '../../assets/images/country-map.png';
import useForm from "../Common/useForm";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { CountryMap } from "./CountryMap";
import $ from 'jquery';

const ContactUs = () => {

    const {
        handleFormChange,
        handleSubmit,
        formValues,
        formErrors,
        createMsg,
        successMsg,
        handlePhoneChange,
        selectedCountry,
        handleCountryChange,
        handleInputBlur,
        errorMsg,
        setSuccessMsg,
        isSubmit,
        errorCount

    } = useForm()

    useEffect(() => {
        $(document).ready(function (e) {
            $(".showSaudi").css('fill', '#9dc0ff');
            $(".country-names span").click(function () {
              var Text = $(this).text().split(' ')[0];
              $(".country-names span").removeClass('active');
              $(this).toggleClass('active');
              $(".mark-country").css("fill","#1359d3")
              $(".show"+Text).css('fill', '#9dc0ff');
            });
          });
    })

    return (
       
        <>
            <div className="Contact-Us">
                <div className="our_products-sec contact-us">
                    <div className="sec-bottom">
                        <h2 className="sec-title">Contact Us</h2>
                    </div>
                </div>
                <div className="our_products-sec contactus" id="contactform">
                    <p className="sec-top-title">CONNECT WITH US</p>
                    <h2 className="sec-title">We’re here to help. Let’s talk!</h2>
                    <div className="form-section">
                        <Row>

                            <Col sm={12} md={6}>
                                <div className="expert-box">
                                    <h4>Talk to our Experts</h4>
                                    <div className="expert-phn">
                                        <p>+974 4494 1300</p>
                                    </div>
                                    <p className="date">Sunday - Thursday 8:00am to 5.30pm</p>
                                </div>
                                <div className="expert-box">
                                    <h4>General inquiries & Concerns</h4>
                                    <div className="expert-phn">
                                        <p>+974 4494 1300</p>
                                    </div>
                                    <p className="date">info@jerseymfg.com</p>
                                </div>
                                <div className="contact-info"><img src={Upbutton} /><p><a href="mailto:info@jerseymfg.com">info@jerseymfg.com</a></p></div>
                            </Col>
                            <Col sm={12} md={6}>

                                <div className="contact-form">
                                    <form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name="wspConName" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wspConName} />
                                                    <label className="did-floating-label">Name</label>
                                                    {formErrors.wspConName && <p className="form-error">{formErrors.wspConName}</p>}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name="wspConComName" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wspConComName} />
                                                    <label className="did-floating-label">Company Name</label>
                                                    {formErrors.wspConComName && <p className="form-error">{formErrors.wspConComName}</p>}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="did-floating-label-content">
                                                    {formValues.wspConPhone && <label class="did-floating-label" style={{ top: "-15px", color: "#81868C" }}>Phone Number(Optional)</label>}
                                                    <PhoneInput
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="QA"
                                                        id='MobileNo'
                                                        name='wspConPhone'
                                                        value={formValues?.wspConPhone}
                                                        onChange={handlePhoneChange}
                                                        onBlur={handleInputBlur}
                                                        placeholder="Mobile Number"

                                                    />
                                                    {formErrors.wspConPhone && <p className="form-error" style={{marginLeft: "38px"}}>{formErrors.wspConPhone}</p>}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name="wspConEmail" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wspConEmail} />
                                                    <label className="did-floating-label">Email</label>
                                                    {formErrors.wspConEmail && <p className="form-error">{formErrors.wspConEmail}</p>}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="did-floating-label-content">
                                                    <input className="did-floating-input" type="text" placeholder=" " name="wspConMsg" onChange={handleFormChange} value={formValues?.wspConMsg} />
                                                    <label className="did-floating-label">Message(Optional)</label>
                                                    {formErrors.wspConMsg && <p className="form-error">{formErrors.wspConMsg}</p>}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="form-check">
                                                    <div className='agree-check'>
                                                        <input type="checkbox" className="form-check-input" id="check2" name="privacy" checked={formValues.privacy}
                                                            onChange={handleFormChange}
                                                            onBlur={handleInputBlur}
                                                            value="true" />
                                                        <label className="form-check-label" htmlFor="check2">I agree to the <a href='/privacy-policy'>privacy policy</a> and to be contacted by Jersey Manufacturing</label>
                                                    </div>
                                                    {formErrors.privacy && <p className="form-error">{formErrors.privacy}</p>}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={7} className="submit-sec">

                                            <button type="submit" className="btn submit-btn" disabled={errorCount !== 0}>
                                            Send Message <img src={Next} alt="Next" />
                                            </button>

                                               

                                            </Col>

                                            <Col sm={12} md={12}>
                                                {successMsg || errorMsg ? (
                                                    <div className='alert-toast'>
                                                        <p className={successMsg ? "success-message" : "form-error"}>{successMsg}</p>
                                                        <button className="close-img" onClick={() => setSuccessMsg('')}>X</button>
                                                    </div>
                                                ) : null}
                                            </Col>

                                        </Row>
                                    </form>


                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="contact counter-section">
                    <Row>
                        <Col md={8}>
                            <div className="our_products-sec">
                                <h2 className="sec-title">We’re closer than Ever</h2>
                                <p className="country-names">
                                    <a><span className="text-blue">Qatar</span></a>
                                    <a><span className="text-blue active">Saudi Arabia</span></a>
                                    <a><span className="text-blue">Oman</span></a>
                                    <a><span className="text-blue">Kuwait</span></a>
                                </p>
                                {/* <img src={MapView} className="mapview" /> */}
                                <div className="contact-location" id="world-map">
                                    <CountryMap />
                                </div>
                            </div>

                        </Col>
                        <Col md={4}>
                            <p className="counter-text">With our Corporate headquarters in Qatar and branch offices in Saudi Arabia</p>
                            <p className="counter-field">
                                <h4>Products Exported to</h4>
                                <div className='count-box'>
                                    
                                    <p class="fs-2 counter-number">7+</p>
                                    <p class="text-light counter-label">Countries</p>
                                </div>
                                {/* <div className='count-box'>
                                    <p class="fs-2 counter-number">05+</p>
                                    <p class="text-light counter-label">Offices Around the World</p>
                                </div> */}
                            </p>
                            <div className="knomore-sec"><a className="knowmore-btn" href="#ourOffice">Offices And Branches <img src={Seemorewhite} /></a></div>
                        </Col>
                    </Row>


                </div>
                <div className="our_products-sec our-offices" id="ourOffice">
                    <h2 className="sec-title">Our Offices</h2>
                    <div className="address-book">
                        <Row className="mt-5">
                            <Col sm={12} md={4}>
                                <h4 className="office-location">Qatar</h4>
                            </Col>
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col md={4}>
                                        <div className="office-address">
                                            <h4>Head Office</h4>
                                            <p className="address-one">Jersey Manufacturing Company,</p>
                                            <p>Logistics Park B ,</p>
                                            <p>Birkat Al Awamer  P.O. Box 7922,</p>
                                            <p>Doha,</p>
                                            <p>State of Qatar</p>
                                            <p className="tel">Tel : +974 44941300</p>
                                            <p>Fax: +974 44995088</p>
                                            <p>info@jerseymfg.com</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="office-address">
                                            <h4>Factory</h4>
                                            <p className="address-one">Jersey Manufacturing Company,</p>
                                            <p>Street 8 Small & Medium Industries</p>
                                            <p>Area, P.O. Box 1038,</p>
                                            <p>Doha,</p>
                                            <p>State of Qatar</p>
                                            <p className="tel">Tel : +974 44941300</p>
                                            <p>info@jerseymfg.com</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="office-address">
                                            <h4>Factory</h4>
                                            <p className="address-one">Jersey Manufacturing Company,</p>
                                            <p>Street 21, Small & Medium Industries</p>
                                            <p>Area P.O. Box 7922,</p>
                                            <p>Doha,</p>
                                            <p>State of Qatar</p>
                                            <p className="tel">Tel : +974 44941300</p>
                                            <p>info@jerseymfg.com</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="office-address">
                                            <h4>International Sales</h4>
                                            <p className="address-one">Jersey, Manufacturing Company,</p>
                                            <p>P.O. Box 7922,</p>
                                            <p>Doha,</p>
                                            <p>State of Qatar</p>
                                            <p className="tel">Tel : +974 44941300</p>
                                            <p>exportsales@jerseymfg.com</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col sm={12} md={4}>
                                <h4 className="office-location">Other Countries</h4>
                            </Col>
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col md={4}>
                                        <div className="office-address">
                                            <h4>Saudi Arabia</h4>
                                            <p className="address-one"><span>Jersey Integrated for trading L.L.C</span></p>
                                            <p><b>Mr.Muhammed Shibu A</b></p>
                                            <p>Senior Engineer - Sales & Marketing</p> 
                                            <p>Mob: +966 58 100 8609</p>
                                            <p>muhammed.s@jerseyintegrated.com</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="office-address">
                                            <h4>Oman</h4>
                                            <p className="address-one">Bahwan Engineering Company LLC.</p>
                                            <p><b>Mr.Sumesh Chandran</b></p>
                                            <p>Senior Engineer - Mechanical Products Division</p>
                                            <p>Tel: +968 22031765</p>
                                            <p>Mob: +968 94342549</p>
                                            <p>sumesh.mechprod@becoman.com</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="office-address">
                                            <h4>Kuwait</h4>
                                            <p className="address-one"><span>Falcon Al-Ahliya General Trading & Contracting Co.</span></p>
                                            <p><b>Mr.Abrar Rafat Ali</b></p>
                                            <p>Senior Engineer - Sales</p>
                                            <p>Mob: +965-50194624</p>
                                            <p>Email:sales@falconkwt.com</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
};
export default ContactUs