import React from 'react'

function NotFound() {
    return (
        <div>
            <h6>Sorry, no results found</h6>
            <p>We can't find any items matching your search.</p>
        </div>
    )
}

export default NotFound