import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/mainpage.css';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 525 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 525, min: 0 },
      items: 1
    }
  };

const ReferenceDetailCarousel = ({listProjImg}) => {
  if (!listProjImg || listProjImg.length === 0) {
    return null; 
  }
 
    return (
        <Carousel responsive={responsive} arrows={true} showDots={true}>
          {listProjImg?.map((item) => (
             <div className='carousel__item'>
             <img width="100%" src={item?.waImgUrl} alt={item?.wsImgName}/>
         </div>

          ))}
           
          
        </Carousel>
    );
}

export default ReferenceDetailCarousel


